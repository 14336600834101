import { getBranches, getProducts, postTransferInventory, putUpdateSupplier } from "../../../../../api";
import { PrimaryButton, Select, TextField } from "../../../../Libraries";
import { useLanguage, useToast } from "../../../../../context";
import { Collapse, SubHeader } from "../../../../common";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDebounce } from "../../../../../hooks";

const CreateStockTransfer = ({ from, item }) => {

    const { invokeToast } = useToast();
    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const [load, setLoad] = useState({ branchLoad: true, productsLoad: false, loading: false });
    const [errors, setErrors] = useState({});
    const [transfer, setTransfer] = useState({
        product: item?.product ? { name: item.product.name, value: item.product } : {},
        fromBranch: item?.branch ? { name: item.branch.name, value: item.branch } : {},
        toBranch: item?.branch ? { name: item.branch.name, value: item.branch } : {},
        quantity: item?.quantity || '',
    });
    const [options, setOptions] = useState({
        products: [],
        branches: []
    });

    useEffect(() => {
        handleGetDetails()
    }, []);

    useEffect(() => {
        if (transfer['fromBranch']?.value?._id) {
            handleGetBranchProducts(transfer['fromBranch'].value);
        }
    }, [transfer['fromBranch']]);


    const handleGetDetails = async () => {
        try {
            const { data } = await getBranches();

            setOptions(prev => {
                let all = { ...prev };
                all['branches'] = data?.branches || [];
                return all;
            });
        } catch (err) { }
        finally {
            setLoad({ ...load, branchLoad: false });
        }
    };

    const handleGetBranchProducts = async (branch) => {
        try {
            setLoad({ ...load, productsLoad: true });

            let params = {
                resultsPerPage: 500,
                branch: branch._id,
                page: 1,
            }

            const { data } = await getProducts(params);

            setOptions(prev => {
                let all = { ...prev };
                all['products'] = data?.products || [];
                return all;
            });
        } catch (err) { }
        finally {
            setLoad({ ...load, productsLoad: false });
        }
    };

    const onChange = (key, value) => {
        let error = { ...errors };
        setTransfer(prev => {
            let all = { ...prev };
            all[key] = value;

            if (error[key]) error[key] = '';
            return all;
        });
        if (errors[key]) setErrors(error);
    };

    const validateTransfer = () => {
        let obj = {};

        if (!transfer['fromBranch']?.name) obj = { ...obj, fromBranch: 'From branch is required' };
        if (!Number(transfer['quantity'])) obj = { ...obj, quantity: 'Quantity is required' };
        if (!transfer['toBranch']?.name) obj = { ...obj, toBranch: 'To branch is required' };
        if (!transfer['product']?.name) obj = { ...obj, product: 'Product is required' };

        return obj;
    };

    const onSave = async (event) => {
        window.scrollTo({ top: 0 });
        event.stopPropagation();
        event.preventDefault();

        try {

            if (Object.values(validateTransfer()).length) {
                setErrors(validateTransfer());
                return;
            }

            setLoad({ ...load, loading: true });

            const { fromBranch, quantity, toBranch, product } = transfer;

            let params = {
                product_id: product?.value?._id || '',
                fromBranch: fromBranch?.value?._id || '',
                toBranch: toBranch?.value?._id || '',
                quantity: Number(quantity || 0),
            };

            from == 'edit' ? await putUpdateSupplier(item._id, params) : await postTransferInventory(params);

            invokeToast({ message: `Stock transfer ${from == 'edit' ? 'updated' : 'created'} successfully!`, type: 'success' });

            navigate('/stock-transfer');
        } catch (err) {
            invokeToast({ message: err, type: 'danger' });
        } finally {
            setLoad({ ...load, loading: false });
        }
    }

    const handleBack = () => navigate(-1);
    const onCancel = () => navigate(-1);

    const fromBranchList = () => {
        let toBranchID = transfer.toBranch?.value?._id || '';

        if (toBranchID) return options.branches.filter(x => x._id != toBranchID).map(item => ({ value: item, label: item.name }));
        else return options.branches.map(item => ({ value: item, label: item.name }));
    };

    const toBranchList = () => {
        let fromBranchID = transfer.fromBranch?.value?._id || '';

        if (fromBranchID) return options.branches.filter(x => x._id != fromBranchID).map(item => ({ value: item, label: item.name }));
        else return options.branches.map(item => ({ value: item, label: item.name }));
    };

    return (
        <div className="page-wrapper">
            <div className="content">

                <SubHeader
                    data={{
                        subTitle: 'transfer_your_stock',
                        title: 'new_stock_transfer',
                        flip: true,
                        actions: [{
                            className: 'btn btn-secondary',
                            title: 'back_to_tansfer_list',
                            action: handleBack,
                            icon: 'arrow-left',
                        }]
                    }}
                />

                <form type="submit" onSubmit={onSave} >
                    <div className="card">
                        <div className="card-body add-product pb-0">
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <Collapse
                                        data={{
                                            title: I18n.transfer_information,
                                            target: 'collapseOne',
                                            id: 'headingOne',
                                            icon: 'info',
                                        }} >
                                        <div
                                            className="accordion-collapse collapse show"
                                            data-bs-parent="#accordionExample"
                                            aria-labelledby="headingOne"
                                            id="collapseOne"
                                        >
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <div className="col-lg-4 col-sm-6 col-12">
                                                        <Select
                                                            placeholder={load.branchLoad ? I18n.fetching_branches : I18n.choose}
                                                            onChange={(value) => onChange('fromBranch', value)}
                                                            className="mb-3 add-product"
                                                            isDisabled={load.branchLoad}
                                                            value={transfer.fromBranch}
                                                            error={errors?.fromBranch}
                                                            options={fromBranchList()}
                                                            label={I18n.from_branch}
                                                            actions={{
                                                                icon: 'plus-circle',
                                                                title: 'add_new',
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="col-lg-4 col-sm-6 col-12">
                                                        <Select
                                                            placeholder={load.branchLoad ? I18n.fetching_branches : I18n.choose}
                                                            onChange={(value) => onChange('toBranch', value)}
                                                            className="mb-3 add-product"
                                                            isDisabled={load.branchLoad}
                                                            value={transfer.toBranch}
                                                            error={errors?.toBranch}
                                                            options={toBranchList()}
                                                            label={I18n.toBranch}
                                                            actions={{
                                                                icon: 'plus-circle',
                                                                title: 'add_new',
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 col-sm-6 col-12">
                                                        <Select
                                                            placeholder={!transfer.fromBranch?.name ? I18n.choose_from_branch_first : load.productsLoad ? I18n.fetching_products : I18n.choose}
                                                            options={options.products.map(item => ({ value: item, label: item.name }))}
                                                            onChange={(value) => onChange('product', value)}
                                                            isDisabled={load.productsLoad}
                                                            className="mb-3 add-product"
                                                            label={I18n.product_name}
                                                            value={transfer.product}
                                                            error={errors.product}
                                                            actions={{
                                                                icon: 'plus-circle',
                                                                title: 'add_new',
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <TextField
                                                        onChange={(value) => onChange('quantity', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        placeholder="enter_product_quantity"
                                                        innerContClass='mb-3 add-product'
                                                        value={transfer.quantity}
                                                        error={errors?.quantity}
                                                        label={'quantity'}
                                                        type={"text"}
                                                        onlyNumber
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="btn-addproduct mb-4">
                            <PrimaryButton
                                className='btn btn-cancel me-2'
                                onClick={onCancel}
                                title={'cancel'}
                                type="button"
                            />
                            <PrimaryButton
                                className='btn btn-submit'
                                title={'save_transfer'}
                                loading={load.loading}
                                type="submit"
                            />
                        </div>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default CreateStockTransfer;