import { getCompanies, postCreateSupplier, postImages, putUpdateSupplier } from "../../../../../api";
import { EMAIL_REGEX, handleUploadImage, SARNO_REGEX } from "../../../../../constants";
import { AddImage, Collapse, ImageList, SubHeader } from "../../../../common";
import { PrimaryButton, Select, TextArea, TextField } from "../../../../Libraries";
import { useLanguage, useToast } from "../../../../../context";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const CreateSupplier = ({ from, item }) => {

    const { invokeToast } = useToast();
    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const [companies, setCompanies] = useState([]);
    const [laoding, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [supplier, setSupplier] = useState({
        company: item?.company ? { name: item.company.name, value: item.company } : {},
        nameLocal: item?.nameLocal || '',
        email: item?.email || '',
        phone: item?.phone || '',
        image: item?.image || '',
        name: item?.name || '',
    });

    useEffect(() => {
        handleGetCompanies()
    }, []);

    const handleGetCompanies = async () => {
        try {
            const { data } = await getCompanies();
            setCompanies(data.companies || []);
        } catch (err) { }
    };

    const onChange = (key, value) => {
        let error = { ...errors };
        setSupplier(prev => {
            let all = { ...prev };
            all[key] = value;
            if (error[key]) error[key] = '';
            return all;
        });
        if (errors[key]) setErrors(error);
    };

    const removeImage = () => setSupplier({ ...supplier, image: '' });

    const addImage = (event) => {
        handleUploadImage(event).then(image => {
            setSupplier({ ...supplier, image });
        });
    };

    const validateSupplier = () => {
        let obj = {};

        if (supplier['email'] && !EMAIL_REGEX.test(supplier['email'])) obj = { ...obj, email: 'Email is invalid' };
        if (supplier['phone'] && !SARNO_REGEX.test(supplier['phone'])) obj = { ...obj, phone: 'Phone is invalid' };
        else if (!supplier['phone']) obj = { ...obj, phone: 'Supplier phone is required' };
        if (!supplier['company']?.name) obj = { ...obj, company: 'Company is required' };
        if (!supplier['name']) obj = { ...obj, name: 'Supplier name is required' };

        return obj;
    };

    const onSave = async (event) => {
        window.scrollTo({ top: 0 });
        event.stopPropagation();
        event.preventDefault();

        try {

            if (Object.values(validateSupplier()).length) {
                setErrors(validateSupplier());
                return;
            }

            setLoading(true);

            const { nameLocal, company, email, phone, image, name } = supplier;

            let params = {
                company_id: company.value._id,
                nameLocal,
                email,
                phone,
                name,
            };

            if (typeof image == 'object') {
                const imagesFormData = new FormData();

                imagesFormData.append('supplier', image);

                const imagesData = await postImages(imagesFormData);

                const uploadedImages = imagesData.data.images;

                if (uploadedImages['supplier']) params.image = uploadedImages['supplier'];
            };

            from == 'edit' ? await putUpdateSupplier(item._id, params) : await postCreateSupplier(params);

            invokeToast({ message: `Supplier ${from == 'edit' ? 'updated' : 'created'} successfully!`, type: 'success' });

            navigate('/supplier');
        } catch (err) {
            invokeToast({ message: err, type: 'danger' });
        } finally {
            setLoading(false);
        }
    }

    const handleBack = () => navigate(-1);
    const onCancel = () => navigate(-1);

    return (
        <div className="page-wrapper">
            <div className="content">

                <SubHeader
                    data={{
                        title: from == 'edit' ? 'edit_supplier' : 'new_supplier',
                        subTitle: from == 'edit' ? '' : 'create_new_supplier',
                        flip: true,
                        actions: [{
                            className: 'btn btn-added color',
                            title: 'back_to_supplier',
                            action: handleBack,
                            icon: 'arrow-left',
                        }]
                    }}
                />

                <form type="submit" onSubmit={onSave} >
                    <div className="card">
                        <div className="card-body add-product pb-0">
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <Collapse
                                        data={{
                                            title: I18n.supplier_information,
                                            target: 'collapseOne',
                                            id: 'headingOne',
                                            icon: 'info',
                                        }} >
                                        <div
                                            className="accordion-collapse collapse show"
                                            data-bs-parent="#accordionExample"
                                            aria-labelledby="headingOne"
                                            id="collapseOne"
                                        >
                                            <div className="accordion-body">
                                                <div className="row">

                                                    <div className="col-lg-4 col-sm-6 col-12">
                                                        <Select
                                                            options={companies.map(item => ({ value: item, label: item.name }))}
                                                            onChange={(value) => onChange('company', value)}
                                                            className="mb-3 add-product"
                                                            value={supplier.company}
                                                            placeholder={I18n.choose}
                                                            error={errors?.company}
                                                            label={I18n.company}
                                                            actions={{
                                                                icon: 'plus-circle',
                                                                title: 'add_new',
                                                            }}
                                                        />
                                                    </div>

                                                    <TextField
                                                        onChange={(value) => onChange('name', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        innerContClass='mb-3 add-product'
                                                        placeholder="enter_supplier_name"
                                                        label={'supplier_name'}
                                                        value={supplier.name}
                                                        error={errors?.name}
                                                        type={"text"}
                                                    />

                                                    <TextField
                                                        onChange={(value) => onChange('nameLocal', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        placeholder="enter_local_supplier_name"
                                                        innerContClass='mb-3 add-product'
                                                        label={'local_supplier_name'}
                                                        value={supplier.nameLocal}
                                                        type={"text"}
                                                    />
                                                </div>

                                                <div className="row">
                                                    <TextField
                                                        onChange={(value) => onChange('phone', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        innerContClass='mb-3 add-product'
                                                        placeholder="enter_supplier_phone"
                                                        label={'supplier_phone'}
                                                        value={supplier.phone}
                                                        error={errors?.phone}
                                                        type={"text"}
                                                    />

                                                    <TextField
                                                        onChange={(value) => onChange('email', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        placeholder="enter_supplier_email"
                                                        innerContClass='mb-3 add-product'
                                                        label={'supplier_email'}
                                                        value={supplier.email}
                                                        error={errors?.email}
                                                        type={"text"}
                                                    />
                                                </div>

                                                <div className="text-editor add-list add">
                                                    <div className="col-lg-12">
                                                        <div className="add-choosen">
                                                            {supplier.image ?
                                                                <ImageList
                                                                    removeImage={removeImage}
                                                                    item={supplier.image}
                                                                />
                                                                :
                                                                <AddImage addImage={(event) => addImage(event)} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="btn-addproduct mb-4">
                            <PrimaryButton
                                className='btn btn-cancel me-2'
                                onClick={onCancel}
                                title={'cancel'}
                                type="button"
                            />
                            <PrimaryButton
                                className='btn btn-submit'
                                title={'save_supplier'}
                                loading={laoding}
                                type="submit"
                            />
                        </div>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default CreateSupplier;