import { DatePicker } from "antd";
import { Icon } from "..";

const AppDatePicker = ({ format, lable, error, icon, ...rest }) => {
    return (
        <div className="input-blocks">
            <label>{lable}</label>
            <div className="input-groupicon calender-input">
                {Icon[icon]}
                <DatePicker
                    dateFormat={format || "dd-MM-yyyy"}
                    type="date"
                    {...rest}
                />
            </div>
            {error && (
                <div className="error-message">
                    {error}
                </div>
            )}
        </div>
    );
};

export default AppDatePicker;