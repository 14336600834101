import React from 'react'
import AppSpinner from '../AppSpinner'

const AppCardBoxLoader = () => {
  return (
    <div className="page-wrapper">
        <div className="content">
            <div className="page-header"></div>
            <div>
                <div className="row">
                    <div className="col-lg-12 col-sm-12">
                            <div className="card cardBodyLoader">
                            <div className="card-body">
                                <AppSpinner />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </div>
  )
}

export default AppCardBoxLoader
