import { TextField } from "../../../Libraries";

const SearchComp = ({ onChange, setText, text }) => {
    return (
        <div className="search-set">
            <div className="search-input">
                <TextField
                    suffixClass='feather-search btn btn-searchset'
                    inputClass='form-control-sm formsearch'
                    containerClass='dataTables_filter'
                    innerContClass='form-addons'
                    placeholder="search_by_name"
                    onChange={(value) => {
                        setText(value);
                        onChange(value);
                    }}
                    value={text}
                    type="text"
                />
            </div>
        </div>
    );
};

export default SearchComp;