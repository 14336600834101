import { formatNum } from "../../../../constants";
import { useLanguage } from "../../../../context";
import { Image } from "../../../Libraries";
import AnalyticsBox from "./AnalyticsBox";

const Earnigs = ({ data }) => {

    const { I18n } = useLanguage();

    return (
        <div className="row sales-cards">
            <div className="col-xl-6 col-sm-12 col-12">
                <div className="card d-flex align-items-center justify-content-between default-cover mb-3 weeklyEarning">
                    <div>
                        <h6>{I18n.weekly_earning}</h6>
                        <h3>
                            SAR {" "}
                            <span className="counters" data-count="95000.45">
                                {formatNum(data?.weeklyEarnings || 0)}
                            </span>
                        </h3>
                    </div>
                    <Image
                        src="assets/img/icons/weekly-earning.svg"
                        alt="img"
                    />
                </div>
            </div>

            <AnalyticsBox
                image={'assets/img/icons/total-sales.svg'}
                title={I18n.no_of_total_sales}
                amount={data?.salesCount || 0}
                className={'primary'}
            />
            <AnalyticsBox
                image={'assets/img/icons/purchased-earnings-white.svg'}
                title={I18n.no_of_total_customers}
                amount={data?.customerCount || 0}
                className={'secondary'}
            />

        </div>
    );
};

export default Earnigs;