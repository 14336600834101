import { Datatable, SearchHeader, SubHeader } from "../../../../common";
import { deleteBrand, getSales } from "../../../../../api";
import { camelCase } from "../../../../../constants";
import { useLanguage } from "../../../../../context";
import { Icon } from "../../../../Libraries";
import { useEffect, useState } from "react";

const SalesList = () => {

    const { I18n } = useLanguage();

    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState({
        resultsPerPage: 10,
        totalCount: 0,
        page: 1,
    });

    useEffect(() => {
        handleSales();
    }, []);

    const onDelete = async (id) => {
        try {
            const data = await deleteBrand(id, { archive: true });
            if (data.success) handleSales();
        } catch (err) { }
    };

    const handleSales = async (reqPage = 1, filters = undefined) => {
        try {
            setLoading(true);

            let params = {
                resultsPerPage: pagination.resultsPerPage,
                page: reqPage
            };

            if (filters) params = { ...params, ...filters };

            const { data } = await getSales(params);
            const { resultsPerPage, totalCount, orders, page } = data || {};

            setPagination({ resultsPerPage, totalCount, page });
            setList(orders.map(order => {
                return {
                    paymentStatus: camelCase(order?.paymentStatus || '-'),
                    cashier: camelCase(order?.cashier?.name || ''),
                    status: camelCase(order?.status || '-'),
                    orderNumber: order?.orderNumber || 0,
                    paidAmount: order?.paidAmount || 0,
                    dueAmount: order?.dueAmount || 0,
                    updatedAt: order?.updatedAt || '-',
                    total: order?.total || '0',
                    _id: order._id,
                }
            }));

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const onPageChange = (page) => {
        if (page == pagination.current || loading) return;
        handleSales(page);
    };

    return (
        <div className="page-wrapper">
            <div className="content">
                <SubHeader
                    data={{
                        subTitle: 'manage_ur_sales',
                        title: 'sale_list',
                        overlays: [
                            {
                                svg: 'assets/img/icons/pdf.svg',
                                tipType: I18n.pdf
                            },
                            {
                                svg: 'assets/img/icons/excel.svg',
                                tipType: I18n.excel
                            },
                            {
                                Icon: Icon['referesh'],
                                tipType: I18n.refresh,
                                onClick: handleSales,
                                disabled: loading
                            }
                        ]
                    }}
                />

                <div className="card table-list-card">
                    <div className="card-body">
                        <SearchHeader applyFilter={handleSales} />

                        <div className="table-responsive">
                            <Datatable
                                onPageChange={onPageChange}
                                pagination={pagination}
                                onDelete={onDelete}
                                loading={loading}
                                dataSource={list}
                                type="sales-list"
                                noOfColumns={9}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SalesList;