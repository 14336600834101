import { DatePicker, PrimaryButton, TextField } from "../../../../../Libraries";
import { AddImage, ImageList } from "../../../../../common";
import { useLanguage } from "../../../../../../context";

const SingleProduct = ({ handleFunctions, onChange, product, loading, errors, from }) => {
    const { I18n } = useLanguage();

    return (
        <div
            className="tab-pane fade show active"
            aria-labelledby="pills-home-tab"
            id="pills-home"
            role="tabpanel"
        >
            <div className="row">
                <div className="col-lg-4 col-sm-6 col-12">
                    <div className="input-blocks add-product list">
                        <TextField
                            placeholder={loading ? 'fetching_sku' : 'enter_sku'}
                            onChange={(value) => onChange('sku', value)}
                            error={errors?.sku}
                            value={product.sku}
                            disabled={loading}
                            label={'sku'}
                            type={"text"}
                            onlyNumber
                        />
                        <PrimaryButton
                            onClick={() => handleFunctions('generate_sku')}
                            className='btn btn-primaryadd'
                            title={'generate_code'}
                            loading={loading}
                            type="button"
                        />
                    </div>

                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                    <TextField
                        onChange={(value) => onChange('barcode', value)}
                        containerClass='input-blocks add-product'
                        placeholder="enter_barocde"
                        value={product.barcode}
                        label='barcode'
                        type={"text"}
                        onlyNumber
                    />
                </div>
                {from == 'edit' ? null :
                    <div className="col-lg-4 col-sm-6 col-12">
                        <TextField
                            onChange={(value) => onChange('quantity', value)}
                            containerClass='input-blocks add-product'
                            placeholder="enter_quantity"
                            error={errors?.quantity}
                            value={product.quantity}
                            label='quantity'
                            type={"text"}
                            onlyNumber
                        />
                    </div>
                }
            </div>
            <div className="row">
                <div className="col-lg-4 col-sm-6 col-12">
                    <TextField
                        onChange={(value) => onChange('cost_price', value)}
                        containerClass='input-blocks add-product'
                        placeholder="enter_cost_price"
                        value={product.cost_price}
                        label='cost_price'
                        type={"text"}
                    />
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                    <TextField
                        onChange={(value) => onChange('selling_price', value)}
                        containerClass='input-blocks add-product'
                        placeholder="enter_selling_price"
                        value={product.selling_price}
                        label='selling_price'
                        type={"text"}
                        onlyNumber
                    />
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                    <DatePicker
                        onChange={(date) => onChange('expiry_date', date)}
                        placeholder={I18n.choose_date}
                        value={product.expiry_date}
                        className="datetimepicker"
                        lable={I18n.expiry_date}
                        suffix="calendar"
                    />
                </div>
            </div>

            <div className="row">
                <div className="text-editor add-list add">
                    <div className="col-lg-12">
                        <div className="add-choosen">

                            {product.images ?
                                <ImageList
                                    removeImage={() => handleFunctions('remove_image')}
                                    item={product.images}
                                />
                                :
                                <AddImage addImage={(event) => handleFunctions('add_image', event)} />
                            }
                        </div>
                    </div>
                </div>
            </div>

            {from == "variant" ?
                <div className="row">
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="btn-addproduct removeVariant mb-4">
                            <PrimaryButton
                                onClick={() => handleFunctions('remove_variant', null)}
                                className='btn btn-cancel me-2'
                                title={'remove_variant'}
                                type="button"
                            />
                        </div>
                    </div>
                </div>
                : null}
        </div>
    );
};

export default SingleProduct;