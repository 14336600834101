import { Link } from "react-router-dom";
import Select from "react-select";
import { Fragment } from "react";
import { Icon } from "..";

const AppSelect = ({ className, onChange, actions, label, value, error, ...rest }) => {
    const { action, title, icon } = actions || {};

    const CustomLabel = () => label ? <label className="form-label">{label}</label> : null;
    const CustomSelect = () =>
        <Select
            onChange={(value) => onChange({ name: value.label, value: value.value })}
            value={value?.name ? { label: value.name, value: value.value } : ''}
            classNamePrefix="react-select"
            {...rest}
        />;


    return (
        <div className={className}>
            {action ?
                <Fragment>
                    <div className="add-newplus">
                        <CustomLabel />
                        <Link
                            data-bs-target="#add-units-brand"
                            data-bs-toggle="modal"
                            onClick={action}
                            to="#"
                        >
                            {Icon[icon]}
                            <span>{title}</span>
                        </Link>
                    </div>
                    <CustomSelect />
                </Fragment>
                :
                <Fragment>
                    <CustomLabel />
                    <CustomSelect />
                </Fragment>
            }

            {error && (
                <div className="error-message">
                    {error}
                </div>
            )}
        </div>
    );
};

export default AppSelect;