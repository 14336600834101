const Body = ({ id, children }) => {
    return (
        <div
            className="accordion-collapse collapse show"
            aria-labelledby={id}
            id={id}
        >
            {children}
        </div>
    );
};

export default Body;