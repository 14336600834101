import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../../../context";
import Navbar from "../../common/Navbar";
import { Drawer } from "../../common";
import Cookies from 'js-cookie';

const PrivateLayout = () => {

    const navigate = useNavigate();
    const { user, handleAuth } = useAuth();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        handleUserAuth();
    }, []);

    const handleUserAuth = async () => {
        if (!user) {
            if (Cookies.get("access_token")) {
                try {
                    await handleAuth();
                } catch (err) {
                    Cookies.remove("access_token");
                    navigate("/login");
                }
            } else {
                navigate("/login");
            }
        }

        setIsLoading(false);
    }

    return (
        <div className={`main-wrapper`}>
            <Navbar />
            <Drawer />

            <Outlet />
        </div>
    )
}

export default PrivateLayout;