import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../context";
import Swal from "sweetalert2";

export const useAlert = (onDelete, logout = false) => {

    const { I18n } = useLanguage();
    const navigate = useNavigate();

    const handleAlert = (id) => {
        Swal.fire({
            text: logout ? I18n.you_will_return_to_login : I18n.you_wont_be_revert_this,
            confirmButtonText: logout ? I18n.logout : I18n.delete,
            confirmButtonColor: "#ff0000",
            cancelButtonText: I18n.cancel,
            cancelButtonColor: "#3c5eab",
            title: I18n.are_you_sure,
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                onDelete(id || navigate);
                if (logout) return;

                Swal.fire({
                    title: I18n.deleted,
                    text: I18n.your_file_has_been_deleted,
                    className: "btn btn-success",
                    confirmButtonText: I18n.ok,
                    customClass: {
                        confirmButton: "btn btn-success",
                    },
                });
            } else {
                Swal.close();
            }
        });
    };

    return [handleAlert];
};