import { getBranches, getProducts, postCreateInventory, putUpdateInventory } from "../../../../../api";
import { PrimaryButton, Select, TextField } from "../../../../Libraries";
import { useLanguage, useToast } from "../../../../../context";
import { Collapse, SubHeader } from "../../../../common";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const CreateInventory = ({ from, item }) => {

    const { invokeToast } = useToast();
    const { I18n } = useLanguage();
    let navigate = useNavigate();

    let isDisabled = from == 'edit' ? true : false;

    const [options, setOptions] = useState({ products: [], branches: [] });
    const [load, setLoad] = useState({ dataLoad: true, loading: false });
    const [errors, setErrors] = useState({});
    const [inventory, setInventory] = useState({
        product: item?.product ? { name: item.product.name, value: item.product } : {},
        branch: item?.branch ? { name: item.branch.name, value: item.branch } : {},
        quantity: item?.quantity || ''
    });

    useEffect(() => {
        handleGetDetails();
    }, []);

    const handleGetDetails = async () => {
        try {
            const [productsData, branchesData] = await Promise.all([
                getProducts(),
                getBranches(),
            ]);

            setOptions(prev => {
                let all = { ...prev };
                all['products'] = productsData?.data?.products || [];
                all['branches'] = branchesData?.data?.branches || [];
                return all;
            });

        } catch (err) {
            console.log('err', err)
        } finally {
            setLoad({ ...load, dataLoad: false });
        }
    };

    const validateInventory = () => {
        let obj = {};

        if (!Number(inventory['quantity'])) obj = { ...obj, quantity: 'Quantity is required' };
        if (!inventory['product']?.name) obj = { ...obj, product: 'Product is required' };
        if (!inventory['branch']?.name) obj = { ...obj, branch: 'Branch is required' };

        return obj;
    };

    const onSave = async (event) => {
        window.scrollTo({ top: 0 });
        event.stopPropagation();
        event.preventDefault();

        try {

            if (Object.values(validateInventory()).length) {
                setErrors(validateInventory());
                return;
            };

            setLoad({ ...load, loading: true });

            const { quantity, product, branch } = inventory;

            let params = {
                quantity: Number(quantity || 0)
            };

            if (from != 'edit') {
                params = {
                    ...params,
                    product_id: product?.value?._id || '',
                    branch_id: branch?.value?._id || '',
                };
            }

            from == 'edit' ? await putUpdateInventory(item._id, params) : await postCreateInventory(params);

            invokeToast({ message: `Stock ${from == 'edit' ? 'updated' : 'created'} successfully!`, type: 'success' });

            navigate('/stock');
        } catch (err) {
            invokeToast({ message: err, type: 'danger' });
        } finally {
            setLoad({ ...load, loading: false });
        }
    };

    const onChange = (key, value) => {
        let error = { ...errors };
        setInventory(prev => {
            let all = { ...prev };
            all[key] = value;
            if (error[key]) error[key] = '';
            return all;
        });
        if (errors[key]) setErrors(error);
    };

    const handleBack = () => navigate(-1);
    const onCancel = () => navigate(-1);

    return (
        <div className="page-wrapper">
            <div className="content">
                <SubHeader
                    data={{
                        subTitle: from == 'edit' ? '' : 'create_new_stock',
                        title: from == 'edit' ? 'edit_stock' : 'new_stock',
                        flip: true,
                        actions: [{
                            className: 'btn btn-added color',
                            title: 'back_to_stock_list',
                            action: handleBack,
                            icon: 'arrow-left',
                        }]
                    }}
                />

                <form type="submit" onSubmit={onSave} >
                    <div className="card">
                        <div className="card-body add-product pb-0">
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <Collapse
                                        data={{
                                            title: I18n.stock_information,
                                            target: 'collapseOne',
                                            id: 'headingOne',
                                            icon: 'info',
                                        }} >
                                    </Collapse>

                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <Select
                                                        options={options.branches.map(item => ({ value: item, label: item.name }))}
                                                        placeholder={load.dataLoad ? I18n.fetching_branches : I18n.choose}
                                                        onChange={(value) => onChange('branch', value)}
                                                        isDisabled={isDisabled || load.dataLoad}
                                                        className="mb-3 add-product"
                                                        value={inventory.branch}
                                                        error={errors?.branch}
                                                        label={I18n.branch}
                                                        actions={{
                                                            icon: 'plus-circle',
                                                            title: 'add_new',
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <Select
                                                        options={options.products.map(item => ({ value: item, label: item.name }))}
                                                        placeholder={load.dataLoad ? I18n.fetching_products : I18n.choose}
                                                        onChange={(value) => onChange('product', value)}
                                                        isDisabled={isDisabled || load.dataLoad}
                                                        className="mb-3 add-product"
                                                        value={inventory.product}
                                                        error={errors?.product}
                                                        label={I18n.product}
                                                        actions={{
                                                            icon: 'plus-circle',
                                                            title: 'add_new',
                                                        }}
                                                    />
                                                </div>

                                                <TextField
                                                    onChange={(value) => onChange('quantity', value)}
                                                    containerClass='col-lg-4 col-sm-6 col-12'
                                                    innerContClass='mb-3 add-product'
                                                    placeholder="enter_quantity"
                                                    value={inventory.quantity}
                                                    error={errors?.quantity}
                                                    label={'quantity'}
                                                    type={"text"}
                                                    onlyNumber
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="btn-addproduct mb-4">
                            <PrimaryButton
                                className='btn btn-cancel me-2'
                                onClick={onCancel}
                                title={'cancel'}
                                type="button"
                            />
                            <PrimaryButton
                                className='btn btn-submit'
                                loading={load.loading}
                                title={'save_stock'}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateInventory;