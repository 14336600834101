import { Datatable, SearchHeader, SubHeader } from "../../../../common";
import { getInventoryTransferList } from "../../../../../api";
import { useLanguage } from "../../../../../context";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../../Libraries";
import { useEffect, useState } from "react";

const InventoryTransferList = () => {

    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState({
        resultsPerPage: 10,
        totalCount: 0,
        page: 1,
    });

    useEffect(() => {
        handleStockTransferList();
    }, []);

    const handleStockTransferList = async (reqPage = 1, filters = undefined) => {
        try {
            setLoading(true);

            let params = {
                resultsPerPage: pagination.resultsPerPage,
                page: reqPage
            };

            if (filters) params = { ...params, ...filters };

            const { data } = await getInventoryTransferList(params);
            const { resultsPerPage, totalCount, transferLog, page } = data || {};

            setPagination({ resultsPerPage, totalCount, page });
            setList(transferLog.map(st => {
                return {
                    fromBranch: st?.fromBranch?.name || '',
                    toBranch: st?.toBranch?.name || '',
                    product: st?.product?.name || '',
                    quantity: st?.quantity || 0,
                    _id: st._id,
                }
            }));

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const onPageChange = (page) => {
        if (page == pagination.current || loading) return;
        handleStockTransferList(page);
    };

    const AddNewTransfer = () => navigate('/stock-transfer/create');

    return (
        <div className="page-wrapper">
            <div className="content">
                <SubHeader
                    data={{
                        subTitle: 'manage_ur_stock_transfer',
                        title: 'stock_transfer',
                        overlays: [
                            {
                                svg: 'assets/img/icons/pdf.svg',
                                tipType: I18n.pdf
                            },
                            {
                                svg: 'assets/img/icons/excel.svg',
                                tipType: I18n.excel
                            },
                            {
                                onClick: handleStockTransferList,
                                Icon: Icon['referesh'],
                                tipType: I18n.refresh,
                                disabled: loading
                            }
                        ],
                        actions: [
                            {
                                className: 'btn btn-added',
                                action: AddNewTransfer,
                                title: 'add_new',
                                icon: 'plus',

                            }
                        ],
                    }}
                />

                <div className="card table-list-card">
                    <div className="card-body">
                        <SearchHeader
                            filter={{ isFilter: true, from: 'stock_transfer' }}
                            applyFilter={handleStockTransferList}
                        />

                        <div className="table-responsive">
                            <Datatable
                                onPageChange={onPageChange}
                                pagination={pagination}
                                type="stock-transfer"
                                loading={loading}
                                dataSource={list}
                                noOfColumns={4}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InventoryTransferList;