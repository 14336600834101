import { Datatable, SearchHeader, SubHeader } from "../../../../common";
import { useLanguage } from "../../../../../context";
import { getRoles } from "../../../../../api";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../../Libraries";
import { useEffect, useState } from "react";

const RolesPermisisonList = () => {

    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState({
        resultsPerPage: 10,
        totalCount: 0,
        page: 1,
    });

    useEffect(() => {
        handleRoles();
    }, []);

    const handleRoles = async (reqPage = 1, filters = undefined) => {
        try {
            setLoading(true);

            let params = {
                resultsPerPage: pagination.resultsPerPage,
                page: reqPage
            };

            if (filters) params = { ...params, ...filters };

            const { data } = await getRoles(params);
            const { resultsPerPage, totalCount, roles, page } = data || {};

            setPagination({ resultsPerPage, totalCount, page });
            setList(roles.map(role => {
                return {
                    nameLocal: role?.nameLocal || '',
                    createdAt: role?.createdAt,
                    name: role?.name || '',
                    _id: role._id,
                }
            }));

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const onPageChange = (page) => {
        if (page == pagination.current || loading) return;
        handleRoles(page);
    };

    const AddEmployee = () => navigate('/roles-permissions/create');

    return (
        <div className="page-wrapper">
            <div className="content">
                <SubHeader
                    data={{
                        subTitle: 'manage_ur_roles_permission',
                        title: 'roles_permission_list',
                        overlays: [
                            {
                                svg: 'assets/img/icons/pdf.svg',
                                tipType: I18n.pdf
                            },
                            {
                                svg: 'assets/img/icons/excel.svg',
                                tipType: I18n.excel
                            },
                            {
                                Icon: Icon['referesh'],
                                tipType: I18n.refresh,
                                onClick: handleRoles,
                                disabled: loading
                            }
                        ],
                        actions: [
                            {
                                className: 'btn btn-added',
                                title: 'add_new_roles',
                                action: AddEmployee,
                                icon: 'plus',

                            }
                        ]
                    }}
                />

                <div className="card table-list-card">
                    <div className="card-body">
                        <SearchHeader applyFilter={handleRoles} />

                        <div className="table-responsive">
                            <Datatable
                                onPageChange={onPageChange}
                                type="roles-permissions"
                                pagination={pagination}
                                loading={loading}
                                dataSource={list}
                                noOfColumns={3}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RolesPermisisonList;