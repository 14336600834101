import { createContext, useContext, useEffect, useState } from "react";
import { en, ar } from "../../languageConfig";

export const LanguageContext = createContext({
    I18n: en,
    changeLanguage: () => { },
    handleLanguage: () => { }
});

export const LanguageProvider = (props) => {

    const localLang = localStorage.getItem('language');

    const [I18n, changeLanguage] = useState(localLang === 'ar' ? ar : en);

    const handleLanguage = (lng) => {
        localStorage.setItem('language', lng);
        window.location.reload();
    }

    useEffect(() => {
        // document.documentElement.dir = I18n.languageDir || 'ltr';
    }, [I18n, I18n.languageDir]);

    return (
        <LanguageContext.Provider value={{ I18n, changeLanguage, handleLanguage }}>
            {props.children}
        </LanguageContext.Provider>
    )
}

export const useLanguage = () => useContext(LanguageContext);