import { Icon, PrimaryButton, Select } from "../../../../../Libraries";
import { useLanguage } from "../../../../../../context";
import { Link } from "react-router-dom";
import { Trash2 } from "react-feather";

const VariableProduct = ({ selectedVariants, handleFunctions, options }) => {
    const { I18n } = useLanguage();

    const getVaraintsObj = () => options.availableVariants.map(item => ({ value: item, label: item.name }));

    return (
        <div
            className="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
        >
            <div className="row select-color-add">
                <div className="col-lg-6 col-sm-6 col-12">
                    <div className="input-blocks add-product">
                        <div className="row">
                            <div className="col-lg-10 col-sm-10 col-10">
                                <Select
                                    onChange={(value) => handleFunctions('select_variant', value)}
                                    label={I18n.variant_attribute}
                                    options={getVaraintsObj()}
                                    placeholder={I18n.choose}
                                    value={null}
                                    className="react-select"
                                />
                            </div>
                        </div>
                    </div>

                    {selectedVariants.map((item, index) => {
                        return (
                            <div className="row align-items-center" key={index}>                                
                                <div className="col-sm-10">
                                    <div className="input-blocks">
                                        <input
                                            value={`${item.name} (${item.values.toString()})`}
                                            className="input-tags form-control"
                                            data-role="tagsinput"
                                            name="specialist"
                                            id="inputBox"
                                            type="text"
                                            disabled
                                        />
                                    </div>
                                </div>
                                
                                <div className="col-lg-2">
                                    <div className="input-blocks variantDeletBtn">
                                        <PrimaryButton
                                            className='remove-color'
                                            Icon={Icon['delete']}
                                            onClick={() => handleFunctions('remove_selected_variant', item)}                                            
                                        />                                                                            
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className="row align-items-center">
                        <div className="col-lg-12 mb-4">
                            <PrimaryButton
                                onClick={() => handleFunctions('generate_variants')}
                                title={'generate_variants'}
                                className='btn btn-submit'
                                type="button"
                            />
                        </div>                        
                    </div>
                </div>
            </div>

        </div>
    );
};

export default VariableProduct;