import { deleteSubCategory, getSubCategories } from "../../../../../api";
import { Datatable, SearchHeader, SubHeader } from "../../../../common";
import { useLanguage } from "../../../../../context";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../../Libraries";
import { useEffect, useState } from "react";

const SubCategoryList = () => {

    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState({
        resultsPerPage: 10,
        totalCount: 0,
        page: 1,
    });

    useEffect(() => {
        handleSubCategories();
    }, []);

    const onDelete = async (id) => {
        try {
            const data = await deleteSubCategory(id, { archive: true });
            if (data.success) handleSubCategories();
        } catch (err) { }
    };

    const handleSubCategories = async (reqPage = 1, filters = undefined) => {
        try {
            setLoading(true);

            let params = {
                resultsPerPage: pagination.resultsPerPage,
                page: reqPage
            };

            if (filters) params = { ...params, ...filters };

            const { data } = await getSubCategories(params);
            const { resultsPerPage, totalCount, subCategories, page } = data || {};

            setPagination({ resultsPerPage, totalCount, page });
            setList(subCategories.map(subCategory => {
                return {
                    category: subCategory?.category?.name || '',
                    nameLocal: subCategory.nameLocal || '',
                    createdAt: subCategory.createdAt,
                    isActive: subCategory.isActive,
                    name: subCategory.name || '',
                    code: subCategory.code,
                    _id: subCategory._id,
                }
            }));

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const onPageChange = (page) => {
        if (page == pagination.current || loading) return;
        handleSubCategories(page);
    };

    const AddSubCategory = () => navigate('/subcategory/create');

    return (
        <div className="page-wrapper">
            <div className="content">
                <SubHeader
                    data={{
                        subTitle: 'manage_ur_sub_categories',
                        title: 'sub_category_list',
                        overlays: [
                            {
                                svg: 'assets/img/icons/pdf.svg',
                                tipType: I18n.pdf
                            },
                            {
                                svg: 'assets/img/icons/excel.svg',
                                tipType: I18n.excel
                            },
                            {
                                onClick: handleSubCategories,
                                Icon: Icon['referesh'],
                                tipType: I18n.refresh,
                                disabled: loading
                            }
                        ],
                        actions: [
                            {
                                title: 'add_new_sub_category',
                                className: 'btn btn-added',
                                action: AddSubCategory,
                                icon: 'plus',
                            }
                        ],
                    }}
                />

                <div className="card table-list-card">
                    <div className="card-body">
                        <SearchHeader
                            filter={{ isFilter: true, from: 'sub_category' }}
                            applyFilter={handleSubCategories}
                        />

                        <div className="table-responsive">
                            <Datatable
                                onPageChange={onPageChange}
                                pagination={pagination}
                                onDelete={onDelete}
                                loading={loading}
                                type="subcategory"
                                dataSource={list}
                                noOfColumns={6}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubCategoryList;