import { AppCardBoxLoader, AppNotFoundBox } from "../../../../Libraries";
import { getInventoryById } from "../../../../../api";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CreateInventory } from "..";

const EditInventory = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState({});

    useEffect(() => {
        if (id) getInventory();
        else setLoading(false);
    }, [id]);

    const getInventory = async () => {
        try {
            const { data } = await getInventoryById(id);
            setItem(data.inventory);
        } catch (err) { }
        finally {
            setLoading(false);
        }
    };

    return loading ?
        <AppCardBoxLoader />
        : item?._id ?
            <CreateInventory from="edit" item={item} />
            :
            <AppNotFoundBox title='failed_to_load_editinventory' />
};

export default EditInventory;