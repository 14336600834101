import { CheckBox, Image, PrimaryButton, TextField } from "../../../Libraries";
import { EMAIL_REGEX } from "../../../../constants/functions";
import { useAuth, useLanguage } from "../../../../context";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

// shaheryar@essaly.com
// 763242 --> num
// abc123 --> pass

const Login = () => {
    const { I18n } = useLanguage();
    const navigate = useNavigate();
    const AuthContext = useAuth();

    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [rememberMe, setRememberMe] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [credentials, setCredentials] = useState({
        vendorNumber: '',
        password: '',
        email: ''
    });

    const validations = () => {
        let error = {};

        if (!credentials.vendorNumber) error = { vendorNumber: 'Vendor number is required' };
        else if (!credentials.password) error = { password: 'Password is required' }
        else if (!credentials.email) error = { email: 'Email is required' }
        else if (credentials.email && !EMAIL_REGEX.test(credentials.email)) error = { email: 'Email is invalid' }

        return error;
    }

    const handleLogin = async (event) => {

        event.preventDefault();

        if (Object.values(validations()).length) {
            setErrors(validations());
            return;
        }

        try {
            setLoading(true);

            await AuthContext.handleLogin(credentials);

            navigate("/dashboard");
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const onChange = (label, value) => {
        setCredentials(prev => {
            let all = { ...prev };
            all[label] = value;
            return all;
        });
    };

    const togglePasswordVisible = () => setIsPasswordVisible(prev => !prev);
    const toggleRememberMe = () => setRememberMe(prev => !prev);

    return (
        <div className="main-wrapper">
            <div className="account-content">
                <div className="login-wrapper bg-img">
                    <div className="login-content">
                        <form action="index" onSubmit={handleLogin}>
                            <div className="login-userset">
                                <div className="login-logo logo-normal">
                                    <Image src={"assets/img/vlogo-essaly.png"} />
                                </div>

                                <div className="login-userheading">
                                    <h3>{I18n.sign_in}</h3>
                                    <h4>
                                        {I18n.sign_in_descp}
                                    </h4>
                                </div>

                                <TextField
                                    onChange={(value) => onChange('vendorNumber', value)}
                                    suffixClass='fas toggle-password fa-user'
                                    inputClass='pass-input form-control'
                                    containerClass='form-login mb-3'
                                    value={credentials.vendorNumber}
                                    error={errors?.vendorNumber}
                                    innerContClass='form-addons'
                                    label='vendor_code'
                                    type="text"
                                />

                                <TextField
                                    onChange={(value) => onChange('email', value)}
                                    icon='assets/img/icons/mail.svg'
                                    containerClass='form-login mb-3'
                                    innerContClass='form-addons'
                                    inputClass='form-control'
                                    value={credentials.email}
                                    error={errors?.email}
                                    label='email_address'
                                    type="text"
                                />

                                <TextField
                                    suffixClass={`fas toggle-password ${isPasswordVisible ? "fa-eye" : "fa-eye-slash"}`}
                                    onChange={(value) => onChange('password', value)}
                                    type={isPasswordVisible ? "text" : "password"}
                                    inputClass='pass-input form-control'
                                    containerClass='form-login mb-3'
                                    onSuffix={togglePasswordVisible}
                                    value={credentials.password}
                                    innerContClass='pass-group'
                                    error={errors?.password}
                                    label='password'
                                />

                                <div className="form-login authentication-check">
                                    <div className="row">
                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                            <div className="custom-control custom-checkbox">
                                                <CheckBox
                                                    className="ps-4 mb-0 pb-0 line-height-1"
                                                    onChange={toggleRememberMe}
                                                    label={I18n.remember_me}
                                                    value={rememberMe}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-login">
                                    <PrimaryButton
                                        className='btn btn-login'
                                        onClick={handleLogin}
                                        loading={loading}
                                        title={'sign_in'}
                                        type="submit"
                                        auth={true}
                                    />
                                </div>
                                <div className="form-sociallink">
                                    <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                                        <p>{I18n.copy_right_descp}</p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;