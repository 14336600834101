import { AppCardBoxLoader, AppNotFoundBox } from "../../../../Libraries";
import { getEmployeeById } from "../../../../../api";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CreateUser from "../CreateUser";

const EditUser = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState({});

    useEffect(() => {
        if (id) getUser();
        else setLoading(false);
    }, [id]);

    const getUser = async () => {
        try {
            const { data } = await getEmployeeById(id);
            setItem(data.employee);
        } catch (err) { }
        finally {
            setLoading(false);
        }
    };


    return loading ?
        <AppCardBoxLoader />
        : item?._id ?
            <CreateUser from="edit" item={item} />
            :
            <AppNotFoundBox title='failed_to_load_user' />
};

export default EditUser;