import { BrandList, CategoryList, CreateBrand, CreateCategory, CreateCustomer, CreateProduct, CreateSalesTax, CreateSubCategory, CreateUnit, CreateVariantOption, CustomerList, Dashboard, EditCategory, EditCustomer, EditProduct, EditSubCategory, InventoryList, Login, ProductDetail, ProductList, SalesTaxList, SubCategoryList, UnitList, VariantOptionList, LoctionList, CreateLocation, EditLocation, EditVariantOption, SupplierList, CreateSupplier, EditSupplier, CreateCompany, EditCompany, CompanyList, PurchaseTaxList, CreatePurchaseTax, EditPurchaseTax, EditSalesTax, PurchaseOrderList, CreatePurchaseOrder, DiscountList, CreateInventory, EditPurchaseOrder, CreateDiscount, EditDiscount, EditUnit, EditBrand, InventoryTransferList, CreateStockTransfer, SalesList, EditInventory, SaleDetails, UserList, RolesPermisisonList, CreateRole, EditRole, RolePermisisonDetails, CreateUser, EditUser } from "../components/screens";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { PrivateLayout, PublicLayout } from '../components/layouts';

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* Public */}
                <Route element={<PublicLayout />}>
                    <Route path="login" element={<Login />} />
                </Route>

                {/* Private */}
                <Route element={<PrivateLayout />}>

                    {/* Dashboard*/}
                    <Route path={"/"} element={<Navigate to="dashboard" replace />} />
                    <Route path="dashboard" element={<Dashboard />} />

                    {/* Customer*/}
                    <Route path="customer/create" element={<CreateCustomer />} />
                    <Route path="customer/edit/:id" element={<EditCustomer />} />
                    <Route path="customer" element={<CustomerList />} />

                    {/* Supplier*/}
                    <Route path="supplier/create" element={<CreateSupplier />} />
                    <Route path="supplier/edit/:id" element={<EditSupplier />} />
                    <Route path="supplier" element={<SupplierList />} />

                    {/* Product*/}
                    <Route path="product/detail/:id" element={<ProductDetail />} />
                    <Route path="product/create" element={<CreateProduct />} />
                    <Route path="product/edit/:id" element={<EditProduct />} />
                    <Route path="product" element={<ProductList />} />

                    {/* Category*/}
                    <Route path="category/create" element={<CreateCategory />} />
                    <Route path="category/edit/:id" element={<EditCategory />} />
                    <Route path="category" element={<CategoryList />} />

                    {/* SubCategory*/}
                    <Route path="subcategory/create" element={<CreateSubCategory />} />
                    <Route path="subcategory/edit/:id" element={<EditSubCategory />} />
                    <Route path="subcategory" element={<SubCategoryList />} />

                    {/* Brand */}
                    <Route path="brand/create" element={<CreateBrand />} />
                    <Route path="brand/edit/:id" element={<EditBrand />} />
                    <Route path="brand" element={<BrandList />} />

                    {/* Sales Tax */}
                    <Route path="sales-tax/create" element={<CreateSalesTax />} />
                    <Route path="sales-tax/edit/:id" element={<EditSalesTax />} />
                    <Route path="sales-tax" element={<SalesTaxList />} />

                    {/* Unit */}
                    <Route path="unit/create" element={<CreateUnit />} />
                    <Route path="unit/edit/:id" element={<EditUnit />} />
                    <Route path="unit" element={<UnitList />} />

                    {/* Variant-Option */}
                    <Route path="variant-option/create" element={<CreateVariantOption />} />
                    <Route path="variant-option/edit/:id" element={<EditVariantOption />} />
                    <Route path="variant-option" element={<VariantOptionList />} />

                    {/* Stock */}
                    <Route path="stock/create" element={<CreateInventory />} />
                    <Route path="stock/edit/:id" element={<EditInventory />} />
                    <Route path="stock" element={<InventoryList />} />

                    {/* Stock Transfer */}
                    <Route path="stock-transfer/create" element={<CreateStockTransfer />} />
                    <Route path="stock-transfer" element={<InventoryTransferList />} />

                    {/* Location */}
                    <Route path="location/create" element={<CreateLocation />} />
                    <Route path="location/edit/:id" element={<EditLocation />} />
                    <Route path="location" element={<LoctionList />} />

                    {/* Company */}
                    <Route path="store-list/edit/:id" element={<EditCompany />} />
                    <Route path="store-list/create" element={<CreateCompany />} />
                    <Route path="store-list" element={<CompanyList />} />

                    {/* Purchase-Tax */}
                    <Route path="purchase-tax/edit/:id" element={<EditPurchaseTax />} />
                    <Route path="purchase-tax/create" element={<CreatePurchaseTax />} />
                    <Route path="purchase-tax" element={<PurchaseTaxList />} />

                    {/* Purchase-Order */}
                    <Route path="purchase-order/create" element={<CreatePurchaseOrder />} />
                    <Route path="purchase-order/edit/:id" element={<EditPurchaseOrder />} />
                    <Route path="purchase-order" element={<PurchaseOrderList />} />

                    {/* Discount */}
                    <Route path="discount/create" element={<CreateDiscount />} />
                    <Route path="discount/edit/:id" element={<EditDiscount />} />
                    <Route path="discount" element={<DiscountList />} />

                    {/* Sales */}
                    <Route path="sales-list/detail/:id" element={<SaleDetails />} />
                    <Route path="sales-list" element={<SalesList />} />

                    {/* Employee */}
                    <Route path="users/edit/:id" element={<EditUser />} />
                    <Route path="users/create" element={<CreateUser />} />
                    <Route path="users" element={<UserList />} />


                    {/* Roles */}
                    <Route path="roles-permissions/detail/:id" element={<RolePermisisonDetails />} />
                    <Route path="roles-permissions" element={<RolesPermisisonList />} />
                    <Route path="roles-permissions/create" element={<CreateRole />} />
                    <Route path="roles-permissions/edit/:id" element={<EditRole />} />


                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes;