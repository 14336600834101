import { postCreateDiscount, putUpdateDiscount } from "../../../../../api";
import { PrimaryButton, TextField } from "../../../../Libraries";
import { useLanguage, useToast } from "../../../../../context";
import { Collapse, SubHeader } from "../../../../common";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const CreateDiscount = ({ from, item }) => {

    const { invokeToast } = useToast();
    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const [laoding, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [discount, setDiscount] = useState({
        capPerTransaction: item?.capPerTransaction || '',
        nameLocal: item?.nameLocal || '',
        maxCap: item?.maxCap || '',
        value: item?.value || '',
        name: item?.name || '',
    });

    const onChange = (key, value) => {
        let error = { ...errors };
        setDiscount(prev => {
            let all = { ...prev };
            all[key] = value;
            if (error[key]) error[key] = '';
            return all;
        });
        if (errors[key]) setErrors(error);
    };

    const validateDiscount = () => {
        let obj = {};

        if (!Number(discount['maxCap'])) obj = { ...obj, maxCap: 'Max cap is required' };
        if (!Number(discount['value'])) obj = { ...obj, value: 'Value is required' };
        if (!discount['name']) obj = { ...obj, name: 'Discount name is required' };

        return obj;
    };

    const onSave = async (event) => {
        window.scrollTo({ top: 0 });
        event.stopPropagation();
        event.preventDefault();

        try {

            if (Object.values(validateDiscount()).length) {
                setErrors(validateDiscount());
                return;
            }

            setLoading(true);

            const { capPerTransaction, nameLocal, maxCap, value, type, name } = discount;

            let params = {
                capPerTransaction: Number(capPerTransaction || 0),
                maxCap: Number(maxCap || 0),
                value: Number(value || 0),
                type: "percentage",
                nameLocal,
                name
            };

            from == 'edit' ? await putUpdateDiscount(item._id, params) : await postCreateDiscount(params);

            invokeToast({ message: `Discount ${from == 'edit' ? 'updated' : 'created'} successfully!`, type: 'success' });

            navigate('/discount');
        } catch (err) {
            invokeToast({ message: err, type: 'danger' });
        } finally {
            setLoading(false);
        }
    }

    const handleBack = () => navigate(-1);
    const onCancel = () => navigate(-1);

    return (
        <div className="page-wrapper">
            <div className="content">

                <SubHeader
                    data={{
                        title: from == 'edit' ? 'edit_discount' : 'new_discount',
                        subTitle: from == 'edit' ? '' : 'create_new_discount',
                        flip: true,
                        actions: [{
                            className: 'btn btn-added color',
                            title: 'back_to_discount',
                            action: handleBack,
                            icon: 'arrow-left',
                        }]
                    }}
                />

                <form type="submit" onSubmit={onSave} >
                    <div className="card">
                        <div className="card-body add-product pb-0">
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <Collapse
                                        data={{
                                            title: I18n.discount_information,
                                            target: 'collapseOne',
                                            id: 'headingOne',
                                            icon: 'info',
                                        }} >
                                        <div
                                            className="accordion-collapse collapse show"
                                            data-bs-parent="#accordionExample"
                                            aria-labelledby="headingOne"
                                            id="collapseOne"
                                        >
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <TextField
                                                        onChange={(value) => onChange('name', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        innerContClass='mb-3 add-product'
                                                        placeholder="enter_discount_name"
                                                        label={'discount_name'}
                                                        value={discount.name}
                                                        error={errors?.name}
                                                        type={"text"}
                                                    />

                                                    <TextField
                                                        onChange={(value) => onChange('nameLocal', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        placeholder="enter_local_discount_name"
                                                        innerContClass='mb-3 add-product'
                                                        label={'local_discount_name'}
                                                        value={discount.nameLocal}
                                                        type={"text"}
                                                    />

                                                    <TextField
                                                        placeholder="enter_discount_value_in_percentage"
                                                        onChange={(value) => onChange('value', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        innerContClass='mb-3 add-product'
                                                        label={'discount_value'}
                                                        value={discount.value}
                                                        error={errors?.value}
                                                        type={"text"}
                                                        onlyNumber
                                                    />
                                                </div>

                                                <div className="row">
                                                    <TextField
                                                        onChange={(value) => onChange('maxCap', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        placeholder="enter_max_discount_cap"
                                                        innerContClass='mb-3 add-product'
                                                        value={discount.maxCap}
                                                        error={errors?.maxCap}
                                                        label={'max_cap'}
                                                        type={"text"}
                                                        onlyNumber
                                                    />

                                                    <TextField
                                                        onChange={(value) => onChange('capPerTransaction', value)}
                                                        placeholder="enter_discount_transaction_limit"
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        value={discount.capPerTransaction}
                                                        innerContClass='mb-3 add-product'
                                                        label={'limit_of_transaction'}
                                                        type={"text"}
                                                        onlyNumber
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-12">
                        <div className="btn-addproduct mb-4">
                            <PrimaryButton
                                className='btn btn-cancel me-2'
                                onClick={onCancel}
                                title={'cancel'}
                                type="button"
                            />
                            <PrimaryButton
                                className='btn btn-submit'
                                title={'save_discount'}
                                loading={laoding}
                                type="submit"
                            />
                        </div>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default CreateDiscount;