import axios, { AxiosError, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import API_ENDPOINTS from './endpoint';
import { BASE_URL } from '../constants';

export const Axios = axios.create({
    baseURL: BASE_URL
});

Axios.interceptors.request.use(
    async config => {
        let token = Cookies.get("access_token");

        if (token) {
            if (!config.headers.Authorization) {
                config.headers.Authorization = `Bearer ${token}`;
                // config.headers.Authorization = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3NTE2MjIyNDEsImRhdGEiOnsiX2lkIjoiNjY4NjY5MWJhNGU4OTkzMzEyNGZiYWM0IiwidmVuZG9yTnVtYmVyIjoiMTc4NDExIiwicm9sZSI6InZlbmRvciJ9LCJpYXQiOjE3MjAwODYyNDF9.Ylcy8niLVsbhERiUZ5NKNFY3w6GWJWazSTqN3YsKDdc`;
            }
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

export const handleApi = async (axiosPromise) => {
    try {
        return (await axiosPromise).data;
    } catch (err) {
        if (err instanceof AxiosError) {
            throw err.response?.data?.message || err.response?.data?.error
        }

        if (err instanceof Error) {
            throw err.message
        }

        throw err;
    }
}

export const postLoginUser = (params) => handleApi(Axios.post(API_ENDPOINTS.LOGIN, params));

export const getAuthenticatedUser = () => handleApi(Axios.get(API_ENDPOINTS.AUTHENTICATE));


// PRODUCTS
export const getProducts = (params) => handleApi(Axios.get(API_ENDPOINTS.PUT_GET_DELETE_PRODUCT, { params }));

export const postProduct = (params) => handleApi(Axios.post(API_ENDPOINTS.CREATE_PRODUCT, params));

export const getProductById = (id) => handleApi(Axios.get(`${API_ENDPOINTS.PUT_GET_DELETE_PRODUCT}/${id}`));

export const putUpdateProduct = (id, params) => handleApi(Axios.put(`${API_ENDPOINTS.PUT_GET_DELETE_PRODUCT}/${id}`, params));

export const deleteProduct = (id, params) => handleApi(Axios.delete(`${API_ENDPOINTS.PUT_GET_DELETE_PRODUCT}/${id}`, { data: params }));

export const getSku = () => handleApi(Axios.get(API_ENDPOINTS.GET_SKU));


// CATEGORIES
export const getCategories = (params) => handleApi(Axios.get(API_ENDPOINTS.PUT_GET_DELETE_CATEGORY, { params }));

export const postCreateCategory = (params) => handleApi(Axios.post(API_ENDPOINTS.CREATE_CATEGORY, params));

export const getCategoryById = (id) => handleApi(Axios.get(`${API_ENDPOINTS.PUT_GET_DELETE_CATEGORY}/${id}`));

export const putUpdateCategory = (id, params) => handleApi(Axios.put(`${API_ENDPOINTS.PUT_GET_DELETE_CATEGORY}/${id}`, params));

export const deleteCategory = (id, params) => handleApi(Axios.delete(`${API_ENDPOINTS.PUT_GET_DELETE_CATEGORY}/${id}`, { data: params }));

export const getCategoryCode = () => handleApi(Axios.get(API_ENDPOINTS.GET_CATEGORY_CODE));



// SUB-CATEGORIES
export const getSubCategories = (params) => handleApi(Axios.get(API_ENDPOINTS.PUT_GET_DELETE_SUBCATEGORIES, { params }));

export const postCreateSubCategory = (params) => handleApi(Axios.post(API_ENDPOINTS.CREATE_SUBCATEGORY, params));

export const getSubCategoryById = (id) => handleApi(Axios.get(`${API_ENDPOINTS.PUT_GET_DELETE_SUBCATEGORIES}/${id}`));

export const putUpdateSubCategory = (id, params) => handleApi(Axios.put(`${API_ENDPOINTS.PUT_GET_DELETE_SUBCATEGORIES}/${id}`, params));

export const deleteSubCategory = (id, params) => handleApi(Axios.delete(`${API_ENDPOINTS.PUT_GET_DELETE_SUBCATEGORIES}/${id}`, { data: params }));

export const getSubCategoryCode = () => handleApi(Axios.get(API_ENDPOINTS.GET_SUBCATEGORY_CODE));


// BRANDS
export const getBrands = (params) => handleApi(Axios.get(API_ENDPOINTS.PUT_GET_DELETE_BRANDS, { params }));

export const postCreateBrand = (params) => handleApi(Axios.post(API_ENDPOINTS.CREATE_BRAND, params));

export const getBrandById = (id) => handleApi(Axios.get(`${API_ENDPOINTS.PUT_GET_DELETE_BRANDS}/${id}`));

export const putUpdateBrand = (id, params) => handleApi(Axios.put(`${API_ENDPOINTS.PUT_GET_DELETE_BRANDS}/${id}`, params));

export const deleteBrand = (id, params) => handleApi(Axios.delete(`${API_ENDPOINTS.PUT_GET_DELETE_BRANDS}/${id}`, { data: params }));

export const getBrandCode = () => handleApi(Axios.get(API_ENDPOINTS.PUT_GET_DELETE_BRANDS));


// UNITS
export const getUnits = (params) => handleApi(Axios.get(API_ENDPOINTS.PUT_GET_DELETE_UNIT, { params }));

export const postCreateUnit = (params) => handleApi(Axios.post(API_ENDPOINTS.CREATE_UNIT, params));

export const getUnitById = (id) => handleApi(Axios.get(`${API_ENDPOINTS.PUT_GET_DELETE_UNIT}/${id}`));

export const putUpdateUnit = (id, params) => handleApi(Axios.put(`${API_ENDPOINTS.PUT_GET_DELETE_UNIT}/${id}`, params));

export const deleteUnit = (id, params) => handleApi(Axios.delete(`${API_ENDPOINTS.PUT_GET_DELETE_UNIT}/${id}`, { data: params }));

export const getUnitCode = () => handleApi(Axios.get(API_ENDPOINTS.PUT_GET_DELETE_UNIT));


// VARIANT OPTIONS
export const getOptions = (params) => handleApi(Axios.get(API_ENDPOINTS.PUT_GET_DELETE_OPTIONS, { params }));

export const postCreateOptions = (params) => handleApi(Axios.post(API_ENDPOINTS.CREATE_OPTION, params));

export const getOptionById = (id) => handleApi(Axios.get(`${API_ENDPOINTS.PUT_GET_DELETE_OPTIONS}/${id}`));

export const putUpdateOption = (id, params) => handleApi(Axios.put(`${API_ENDPOINTS.PUT_GET_DELETE_OPTIONS}/${id}`, params));

export const deleteOption = (id, params) => handleApi(Axios.delete(`${API_ENDPOINTS.PUT_GET_DELETE_OPTIONS}/${id}`, { data: params }));



// CUSTOMER
export const getCustomers = (params) => handleApi(Axios.get(API_ENDPOINTS.PUT_GET_DELETE_CUSTOMERS, { params }));

export const postCreateCustomer = (params) => handleApi(Axios.post(API_ENDPOINTS.CREATE_CUSTOMER, params));

export const getCustomerById = (id) => handleApi(Axios.get(`${API_ENDPOINTS.PUT_GET_DELETE_CUSTOMERS}/${id}`));

export const putUpdateCustomer = (id, params) => handleApi(Axios.put(`${API_ENDPOINTS.PUT_GET_DELETE_CUSTOMERS}/${id}`, params));

export const deleteCustomer = (id, params) => handleApi(Axios.delete(`${API_ENDPOINTS.PUT_GET_DELETE_CUSTOMERS}/${id}`, { data: params }));



// SUPPLIER

export const getSuppliers = (params) => handleApi(Axios.get(API_ENDPOINTS.PUT_GET_DELETE_SUPPLIER, { params }));

export const postCreateSupplier = (params) => handleApi(Axios.post(API_ENDPOINTS.CREATE_SUPPLIER, params));

export const getSupplierById = (id) => handleApi(Axios.get(`${API_ENDPOINTS.PUT_GET_DELETE_SUPPLIER}/${id}`));

export const putUpdateSupplier = (id, params) => handleApi(Axios.put(`${API_ENDPOINTS.PUT_GET_DELETE_SUPPLIER}/${id}`, params));

export const deleteSupplier = (id, params) => handleApi(Axios.delete(`${API_ENDPOINTS.PUT_GET_DELETE_SUPPLIER}/${id}`, { data: params }));


// LOCATIONS

export const getBranches = (params) => handleApi(Axios.get(API_ENDPOINTS.PUT_GET_DELETE_BRANCHES, { params }));

export const postCreateBranch = (params) => handleApi(Axios.post(API_ENDPOINTS.CREATE_BRANCH, params));

export const getBranchById = (id) => handleApi(Axios.get(`${API_ENDPOINTS.PUT_GET_DELETE_BRANCHES}/${id}`));

export const putUpdateBranch = (id, params) => handleApi(Axios.put(`${API_ENDPOINTS.PUT_GET_DELETE_BRANCHES}/${id}`, params));

export const deleteBranch = (id, params) => handleApi(Axios.delete(`${API_ENDPOINTS.PUT_GET_DELETE_BRANCHES}/${id}`, { data: params }));



// COMPANY

export const getCompanies = (params) => handleApi(Axios.get(API_ENDPOINTS.PUT_GET_DELETE_COMPANY, { params }));

export const postCreateCompany = (params) => handleApi(Axios.post(API_ENDPOINTS.CREATE_COMPANY, params));

export const getCompanyById = (id) => handleApi(Axios.get(`${API_ENDPOINTS.PUT_GET_DELETE_COMPANY}/${id}`));

export const putUpdateCompany = (id, params) => handleApi(Axios.put(`${API_ENDPOINTS.PUT_GET_DELETE_COMPANY}/${id}`, params));

export const deleteCompany = (id, params) => handleApi(Axios.delete(`${API_ENDPOINTS.PUT_GET_DELETE_COMPANY}/${id}`, { data: params }));



// PURCHASE TAX

export const getPurchaseTaxes = (params) => handleApi(Axios.get(API_ENDPOINTS.PUT_GET_DELETE_PURCHASE_TAX, { params }));

export const postCreatePurchaseTax = (params) => handleApi(Axios.post(API_ENDPOINTS.CREATE_PURCHASE_TAX, params));

export const getPurchaseTaxById = (id) => handleApi(Axios.get(`${API_ENDPOINTS.PUT_GET_DELETE_PURCHASE_TAX}/${id}`));

export const putUpdatePurchaseTax = (id, params) => handleApi(Axios.put(`${API_ENDPOINTS.PUT_GET_DELETE_PURCHASE_TAX}/${id}`, params));

export const deletePurchaseTax = (id, params) => handleApi(Axios.delete(`${API_ENDPOINTS.PUT_GET_DELETE_PURCHASE_TAX}/${id}`, { data: params }));



// SALE TAX

export const getSalesTaxes = (params) => handleApi(Axios.get(API_ENDPOINTS.PUT_GET_DELETE_SALES_TAX, { params }));

export const postCreateSalesTax = (params) => handleApi(Axios.post(API_ENDPOINTS.CREATE_SALES_TAX, params));

export const getSalesTaxById = (id) => handleApi(Axios.get(`${API_ENDPOINTS.PUT_GET_DELETE_SALES_TAX}/${id}`));

export const putUpdateSalesTax = (id, params) => handleApi(Axios.put(`${API_ENDPOINTS.PUT_GET_DELETE_SALES_TAX}/${id}`, params));

export const deleteSalesTax = (id, params) => handleApi(Axios.delete(`${API_ENDPOINTS.PUT_GET_DELETE_SALES_TAX}/${id}`, { data: params }));



// PURCHASE ORDER

export const getPurchaseList = (params) => handleApi(Axios.get(API_ENDPOINTS.PUT_GET_DELETE_PURCHASE_ORDER, { params }));

export const postCreatePurchaseOrder = (params) => handleApi(Axios.post(API_ENDPOINTS.CREATE_PURCHASE_ORDER, params));

export const getPurchaseOrderById = (id) => handleApi(Axios.get(`${API_ENDPOINTS.PUT_GET_DELETE_PURCHASE_ORDER}/${id}`));

export const putUpdatePurchaseOrder = (id, params) => handleApi(Axios.put(`${API_ENDPOINTS.PUT_GET_DELETE_PURCHASE_ORDER}/${id}`, params));

export const deletePurchaseOrder = (id, params) => handleApi(Axios.delete(`${API_ENDPOINTS.PUT_GET_DELETE_PURCHASE_ORDER}/${id}`, { data: params }));

export const putUpdatePurchaseOrderStatus = (id, params) => handleApi(Axios.put(`${API_ENDPOINTS.PUT_UPDATE_PURCHASE_ORDER_STATUS}/${id}`, params));



// DISCOUNT

export const getDiscountList = (params) => handleApi(Axios.get(API_ENDPOINTS.PUT_GET_DELETE_DISCOUNT, { params }));

export const postCreateDiscount = (params) => handleApi(Axios.post(API_ENDPOINTS.CREATE_DISCOUNT, params));

export const getDiscountById = (id) => handleApi(Axios.get(`${API_ENDPOINTS.PUT_GET_DELETE_DISCOUNT}/${id}`));

export const putUpdateDiscount = (id, params) => handleApi(Axios.put(`${API_ENDPOINTS.PUT_GET_DELETE_DISCOUNT}/${id}`, params));

export const deleteDiscount = (id, params) => handleApi(Axios.delete(`${API_ENDPOINTS.PUT_GET_DELETE_DISCOUNT}/${id}`, { data: params }));


// INVENTORIES

export const getInventories = (params) => handleApi(Axios.get(API_ENDPOINTS.PUT_GET_DELETE_INVENTORY, { params }));

export const postCreateInventory = (params) => handleApi(Axios.post(API_ENDPOINTS.CREATE_INVENTORY, params));

export const getInventoryById = (id) => handleApi(Axios.get(`${API_ENDPOINTS.PUT_GET_DELETE_INVENTORY}/${id}`));

export const putUpdateInventory = (id, params) => handleApi(Axios.put(`${API_ENDPOINTS.PUT_GET_DELETE_INVENTORY}/${id}`, params));

export const deleteInventory = (id, params) => handleApi(Axios.delete(`${API_ENDPOINTS.PUT_GET_DELETE_INVENTORY}/${id}`, { data: params }));

export const postTransferInventory = (params) => handleApi(Axios.post(API_ENDPOINTS.CREATE_GET_INVENTORY_TRANSFER, params));

export const getInventoryTransferList = (id) => handleApi(Axios.get(API_ENDPOINTS.CREATE_GET_INVENTORY_TRANSFER));




// SALES

export const getSales = (params) => handleApi(Axios.get(API_ENDPOINTS.PUT_GET_DELETE_ORDER, { params }));

export const postCreateSale = (params) => handleApi(Axios.post(API_ENDPOINTS.CREATE_ORDER, params));

export const getSaleById = (id) => handleApi(Axios.get(`${API_ENDPOINTS.PUT_GET_DELETE_ORDER}/${id}`));

export const putUpdateSale = (id, params) => handleApi(Axios.put(`${API_ENDPOINTS.PUT_GET_DELETE_ORDER}/${id}`, params));

export const deleteSale = (id, params) => handleApi(Axios.delete(`${API_ENDPOINTS.PUT_GET_DELETE_ORDER}/${id}`, { data: params }));



// USER

export const getEmployees = (params) => handleApi(Axios.get(API_ENDPOINTS.PUT_GET_DELETE_EMPLOYEES, { params }));

export const postCreateEmployee = (params) => handleApi(Axios.post(API_ENDPOINTS.CREATE_EMPLOYEES, params));

export const getEmployeeById = (id) => handleApi(Axios.get(`${API_ENDPOINTS.PUT_GET_DELETE_EMPLOYEES}/${id}`));

export const putUpdateEmployee = (id, params) => handleApi(Axios.put(`${API_ENDPOINTS.PUT_GET_DELETE_EMPLOYEES}/${id}`, params));

export const deleteEmployee = (id, params) => handleApi(Axios.delete(`${API_ENDPOINTS.PUT_GET_DELETE_EMPLOYEES}/${id}`, { data: params }));


// Roles

export const getRoles = (params) => handleApi(Axios.get(API_ENDPOINTS.PUT_GET_DELETE_ROLES, { params }));

export const getPermissions = () => handleApi(Axios.get(API_ENDPOINTS.GET_PERMISSIONS));

export const postCreateRole = (params) => handleApi(Axios.post(API_ENDPOINTS.CREATE_ROLES, params));

export const putUpdateRole = (id, params) => handleApi(Axios.put(`${API_ENDPOINTS.PUT_GET_DELETE_ROLES}/${id}`, params));

export const getRoleById = (id) => handleApi(Axios.get(`${API_ENDPOINTS.PUT_GET_DELETE_ROLES}/${id}`));




// Dashboard

export const getDashboard = () => handleApi(Axios.get(API_ENDPOINTS.GET_DASHBOARD));




export const postImages = (params) => handleApi(Axios.post(API_ENDPOINTS.POST_IMAGES, params));








