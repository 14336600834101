import * as Icon from "react-feather";

const DrawerRoutes = [
    {
        showSubRoute: false,
        submenuHdr: "Main",
        submenuOpen: true,
        label: "main",
        submenuItems: [
            {
                icon: <Icon.Grid />,
                showSubRoute: false,
                label: "dashboard",
                link: "/dashboard",
                submenu: false
            }
        ],
    },
    {
        submenuHdr: "Inventory",
        showSubRoute: false,
        label: "inventory",
        submenuOpen: true,
        submenuItems: [
            {
                showSubRoute: false,
                icon: <Icon.Box />,
                label: "products",
                submenu: true,
                submenuItems: [
                    {
                        label: "product_list",
                        showSubRoute: false,
                        link: "/product",
                    },
                    {
                        label: "create_product",
                        link: "/product/create",
                        showSubRoute: false,
                    },
                ],
            },
            {
                icon: <Icon.Codepen />,
                showSubRoute: false,
                label: "category",
                submenu: true,
                submenuItems: [
                    {
                        label: "category_list",
                        showSubRoute: false,
                        link: "/category",
                    },
                    {
                        label: "create_category",
                        link: "/category/create",
                        showSubRoute: false,
                    },
                ]
            },
            {
                icon: <Icon.Speaker />,
                label: "sub_category",
                showSubRoute: false,
                submenu: true,
                submenuItems: [
                    {
                        label: "sub_category_list",
                        link: "/subcategory",
                        showSubRoute: false,
                    },
                    {
                        label: "create_sub_category",
                        link: "/subcategory/create",
                        showSubRoute: false,
                    },
                ]
            },
            {
                showSubRoute: false,
                icon: <Icon.Tag />,
                label: "brands",
                submenu: true,
                submenuItems: [
                    {
                        label: "brands_list",
                        showSubRoute: false,
                        link: "/brand",
                    },
                    {
                        label: "create_brand",
                        link: "/brand/create",
                        showSubRoute: false,
                    },
                ]
            },
            {
                icon: <Icon.Speaker />,
                showSubRoute: false,
                label: "units",
                submenu: true,
                submenuItems: [
                    {
                        showSubRoute: false,
                        label: "units_list",
                        link: "/unit",
                    },
                    {
                        label: "create_unit",
                        link: "/unit/create",
                        showSubRoute: false,
                    },
                ]
            },
            {
                label: "variant_options",
                icon: <Icon.Layers />,
                showSubRoute: false,
                submenu: true,
                submenuItems: [
                    {
                        label: "variant_options_list",
                        link: "/variant-option",
                        showSubRoute: false,
                    },
                    {
                        label: "create_variant_option",
                        link: "/variant-option/create",
                        showSubRoute: false,
                    },
                ]
            },
        ],
    },
    {
        showSubRoute: false,
        submenuOpen: true,
        submenuHdr: "Tax",
        submenu: true,
        label: "tax",
        submenuItems: [
            {
                icon: <Icon.Package />,
                label: "purchase_tax",
                link: "/purchase-tax",
                showSubRoute: false,
                submenu: false,
            },
            {
                icon: <Icon.Truck />,
                showSubRoute: false,
                link: "/sales-tax",
                label: "sale_tax",
                submenu: false,
            },
        ],
    },
    {
        submenuHdr: "Stock",
        showSubRoute: false,
        submenuOpen: true,
        label: "stock",
        submenu: true,
        submenuItems: [
            {
                icon: <Icon.Package />,
                label: "manage_stock",
                showSubRoute: false,
                link: "/stock",
                submenu: false,
            },
            {
                label: "stock_transfer",
                link: "/stock-transfer",
                icon: <Icon.Truck />,
                showSubRoute: false,
                submenu: false,
            },
        ],
    },
    {
        showSubRoute: false,
        submenuHdr: "Sales",
        submenuOpen: true,
        submenu: false,
        label: "sales",
        submenuItems: [
            {
                icon: <Icon.ShoppingCart />,
                link: "/sales-list",
                showSubRoute: false,
                submenu: false,
                label: "sales",
            },
            // {
            //     icon: <Icon.FileText />,
            //     link: "/invoice-report",
            //     showSubRoute: false,
            //     label: "invoices",
            //     submenu: false,
            // },
            // {
            //     link: "/sales-returns",
            //     label: "sales_return",
            //     icon: <Icon.Copy />,
            //     showSubRoute: false,
            //     submenu: false,
            // }
        ],
    },
    {
        submenuHdr: "Promo",
        showSubRoute: false,
        submenuOpen: true,
        label: "promo",
        submenuItems: [
            // {
            //     icon: <Icon.ShoppingCart />,
            //     showSubRoute: false,
            //     label: "coupons",
            //     link: "/coupons",
            //     submenu: false,
            // },
            {
                icon: <Icon.ShoppingCart />,
                showSubRoute: false,
                label: "discounts",
                link: "/discount",
                submenu: false,
            },
        ],
    },
    {
        submenuHdr: "Purchases",
        showSubRoute: false,
        label: "purchases",
        submenuOpen: true,
        submenuItems: [
            // {
            //     icon: <Icon.ShoppingBag />,
            //     link: "/purchase-list",
            //     showSubRoute: false,
            //     label: "purchases",
            //     submenu: false,
            // },
            {
                icon: <Icon.FileMinus />,
                label: "purchase_order",
                link: "/purchase-order",
                showSubRoute: false,
                submenu: false,
            },
            // {
            //     link: "/purchase-returns",
            //     icon: <Icon.RefreshCw />,
            //     label: "purchase_return",
            //     showSubRoute: false,
            //     submenu: false,
            // },
        ],
    },
    // {
    //     submenuHdr: "Finance & Accounts",
    //     label: "finance_accounts",
    //     showSubRoute: false,
    //     submenuOpen: true,
    //     submenuItems: [
    //         {
    //             icon: <Icon.FileText />,
    //             showSubRoute: false,
    //             label: "expenses",
    //             submenu: true,
    //             submenuItems: [
    //                 {
    //                     link: "/expense-list",
    //                     showSubRoute: false,
    //                     label: "expenses",
    //                 },
    //                 {
    //                     label: "expense_category",
    //                     link: "/expense-category",
    //                     showSubRoute: false,
    //                 },
    //             ],
    //         },
    //     ],
    // },
    {
        submenuHdr: "People",
        showSubRoute: false,
        submenuOpen: true,
        label: "people",
        submenuItems: [
            {
                icon: <Icon.User />,
                showSubRoute: false,
                label: "customers",
                link: "/customer",
                submenu: false,
            },
            {
                icon: <Icon.Users />,
                showSubRoute: false,
                label: "suppliers",
                link: "/supplier",
                submenu: false,
            },
            {
                showSubRoute: false,
                icon: <Icon.Home />,
                link: "/store-list",
                label: "companies",
                submenu: false,
            },
            {
                icon: <Icon.Archive />,
                showSubRoute: false,
                label: "locations",
                link: "/location",
                submenu: false,
            },
        ],
    },
    // {
    //     submenuHdr: "Reports",
    //     showSubRoute: false,
    //     submenuOpen: true,
    //     label: "reports",
    //     submenuItems: [
    //         {
    //             icon: <Icon.BarChart2 />,
    //             label: "sales_report",
    //             link: "/sales-report",
    //             showSubRoute: false,
    //         },
    //         {
    //             label: "purchase_report",
    //             link: "/purchase-report",
    //             icon: <Icon.PieChart />,
    //             showSubRoute: false,
    //         },
    //         {
    //             label: "inventory_report",
    //             link: "/inventory-report",
    //             icon: <Icon.Inbox />,
    //             showSubRoute: false,
    //         },
    //         {
    //             label: "invoice_report",
    //             link: "/invoice-report",
    //             icon: <Icon.File />,
    //             showSubRoute: false,
    //         },
    //         {
    //             label: "supplier_report",
    //             link: "/supplier-report",
    //             icon: <Icon.UserCheck />,
    //             showSubRoute: false,
    //         },
    //         {
    //             label: "customer_report",
    //             link: "/customer-report",
    //             icon: <Icon.User />,
    //             showSubRoute: false,
    //         },
    //         {
    //             label: "expense_report",
    //             link: "/expense-report",
    //             icon: <Icon.FileText />,
    //             showSubRoute: false,
    //         },
    //         {
    //             icon: <Icon.BarChart />,
    //             label: "income_report",
    //             link: "/income-report",
    //             showSubRoute: false,
    //         },
    //         {
    //             icon: <Icon.Database />,
    //             label: "tax_report",
    //             link: "/tax-report",
    //             showSubRoute: false,
    //         },
    //         {
    //             link: "/profit-loss-report",
    //             icon: <Icon.TrendingDown />,
    //             label: "profit_loss",
    //             showSubRoute: false,
    //         },
    //     ],
    // },
    {
        submenuHdr: "User Management",
        label: "user_management",
        showSubRoute: false,
        submenuOpen: true,
        submenuItems: [
            {
                icon: <Icon.UserCheck />,
                showSubRoute: false,
                label: "users",
                link: "/users",
            },
            {
                label: "roles_permissions",
                link: "/roles-permissions",
                icon: <Icon.UserCheck />,
                showSubRoute: false,
            },
            // {
            //     label: "delete_account_request",
            //     link: "/delete-account",
            //     icon: <Icon.Lock />,
            //     showSubRoute: false,
            // },
        ],
    },
    {
        submenuHdr: "Settings",
        showSubRoute: false,
        label: "settings",
        submenu: true,
        submenuItems: [
            // {
            //     label: "general_settings",
            //     icon: <Icon.Settings />,
            //     showSubRoute: false,
            //     link: "/signin",
            // },
            {
                icon: <Icon.LogOut />,
                showSubRoute: false,
                label: "logout",
                // link: "/signin",
            },
        ],
    },
];

export default DrawerRoutes;