import { useLanguage } from "../../../../context";
import Chart from "react-apexcharts";

const AnalyticsGraph = ({ data }) => {

    const { I18n } = useLanguage();

    return (
        <div className="row sales-board">
            <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                <div className="card flex-fill default-cover">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="card-title mb-0">{I18n.sales_analytics}</h5>
                    </div>
                    <div className="card-body">
                        <div id="sales-analysis" className="chart-set" />
                        <Chart
                            series={data.series}
                            options={data}
                            height={273}
                            type="area"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnalyticsGraph;