import { getBranches, getCategories, getCompanies, getProducts, getRoles, getSubCategories } from "../../../../api";
import { Fragment, useEffect, useState } from "react";
import { useDebounce } from "../../../../hooks";
import { Icon } from "../../../Libraries";
import { Link } from "react-router-dom";
import FilterComp from "./FilterComp";
import SearchComp from "./SearchComp";

const SearchHeader = ({ applyFilter, filter }) => {

    const { from, isFilter = false } = filter || {};

    const [filterObj, setFilterObj] = useState({
        subcategory: {},
        isActive: false,
        applied: false,
        category: {},
        company: {},
        product: {},
        branch: {},
        role: {}
    });
    const [options, setOptions] = useState({});
    const [text, setText] = useState('');

    useEffect(() => {
        if (isFilter) getFiltersData();
    }, [isFilter]);

    const getFiltersData = async () => {

        try {

            let APIS = [];

            if (from == 'manage_stock' || from == 'stock_transfer') APIS = [getProducts(), getBranches()];
            if (from == 'product') APIS = [getSubCategories(), getCategories(), getBranches()];
            if (from == 'sub_category') APIS = [getCategories()];
            if (from == 'supplier') APIS = [getCompanies()];
            if (from == 'user') APIS = [getRoles()];

            const data = await Promise.all(APIS);

            setOptions(prev => {
                let all = { ...prev };

                if (from == 'manage_stock' || from == 'stock_transfer') {
                    all['product'] = data[0].data?.products || [];

                    if (from == 'stock_transfer') {
                        all['fromBranch'] = data[1].data?.branches || [];
                        all['toBranch'] = data[1].data?.branches || [];

                    } else all['branch'] = data[1].data?.branches || [];
                } else if (from == 'product') {
                    all['subcategory'] = data[0]?.data?.subCategories || [];
                    all['category'] = data[1]?.data?.categories || [];
                    all['branch'] = data[2]?.data?.branches || [];
                } else if (from == 'supplier') all['company'] = data[0]?.data?.companies || [];
                else if (from == 'user') all['role'] = data[0]?.data?.roles || [];
                else if (from == 'sub_category') all['category'] = data[0]?.data?.categories || [];

                return all;
            });

        } catch (err) { }
    };

    const getFilterParams = () => {
        let obj = {};

        if (filterObj['applied']) {
            if (from == 'manage_stock' || from == 'stock_transfer') {
                obj = { product: filterObj['product']?.value?._id || '' }

                if (from == 'stock_transfer') {
                    obj = {
                        ...obj,
                        fromBranch: filterObj['fromBranch']?.value?._id || '',
                        toBranch: filterObj['toBranch']?.value?._id || '',
                    }
                } else obj = { ...obj, branch: filterObj['branch']?.value?._id || '' }
            } else if (from == 'product') {
                obj = {
                    subcategory: filterObj['subcategory']?.value?._id || '',
                    category: filterObj['category']?.value?._id || '',
                    branch: filterObj['branch']?.value?._id || '',
                }
            } else if (from == 'supplier') obj = { company: filterObj['company']?.value?._id || '' }
            else if (from == 'sub_category') obj = { category: filterObj['category']?.value?._id || '' }
            else if (from == 'user') obj = { role: filterObj['role']?.value?._id || '' }

        }

        return obj;
    };

    const onChange = useDebounce((query) => {
        applyFilter(1, { query, ...getFilterParams() });
    }, 500);

    const onApply = () => applyFilter(1, { query: text, ...getFilterParams() });

    const togggleFilter = () => {
        setFilterObj({ ...filterObj, isActive: !filterObj.isActive, applied: false });
        if (filterObj['applied']) applyFilter(1, { query: text });
    };

    return (
        <Fragment>
            <div className="table-top">
                <SearchComp
                    onChange={onChange}
                    setText={setText}
                    text={text}
                />

                {isFilter ?
                    <div className="search-path">
                        <Link
                            className={`btn btn-filter ${filterObj.isActive ? "setclose" : ""}`}
                            onClick={togggleFilter}
                            id="filter_search"
                        >
                            {Icon['filter']}
                            <span>
                                {Icon['close']}
                            </span>
                        </Link>
                    </div>
                    : null}
            </div>

            {filterObj.isActive ?
                <FilterComp
                    setFilterObj={setFilterObj}
                    filterObj={filterObj}
                    options={options}
                    onApply={onApply}
                /> :
                null
            }

        </Fragment>
    );
};

export default SearchHeader;