import { Link } from "react-router-dom";
import { Icon } from "../../Libraries";

const Header = ({ data }) => {
    const { target, title, icon, id } = data || {};
    return (
        <div className="accordion-header" id={id} >
            <div
                data-bs-target={`#${target}`}
                className="accordion-button"
                data-bs-toggle="collapse"
                aria-controls={target}
            >
                <div className="addproduct-icon">
                    <h5>
                        {icon && Icon[icon]}
                        <span>{title}</span>
                    </h5>
                    <Link to="#">
                        {Icon['chevron--down']}
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Header;