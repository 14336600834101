import { ArrowLeft, Calendar, ChevronDown, ChevronUp, Clock, Edit, Eye, Info, LifeBuoy, List, RotateCcw, Search, Sliders, Trash2, X } from "feather-icons-react/build/IconComponents";
import { Download, Filter, PlusCircle } from "react-feather";

const AppIcons = {
    'referesh': <RotateCcw className="feather feather-rotate-ccw feather-16" />,
    'chevron--down': <ChevronDown className="chevron-down-add" />,
    'chevron--up': <ChevronUp className="feather-chevron-up" />,
    'plus-circle': <PlusCircle className="plus-down-add" />,
    'delete': <Trash2 className="feather-trash-2" />,
    'plus': <PlusCircle className="me-2 iconsize" />,
    'life-buoy': <LifeBuoy className="add-info" />,
    'calendar': <Calendar className="info-img" />,
    'arrow-left': <ArrowLeft className="me-2" />,
    'filter': <Filter className="filter-icon" />,
    'clock': <Clock className="feather-14" />,
    'download': <Download className="me-2" />,
    'edit': <Edit className="feather-edit" />,
    'sort': <Sliders className="info-img" />,
    'eye': <Eye className="feather-view" />,
    'menu': <List className="add-info" />,
    'info': <Info className="add-info" />,
    'search': <Search />,
    'close': <X />
};

export default AppIcons;
