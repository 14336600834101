const AppCheckbox = ({ className, label, ...res }) => {
    return (
        <label className={`checkboxs ${className}`}>
            <input type="checkbox"  {...res} />
            <span className="checkmarks" />
            {label || ''}
        </label>
    )
};

export default AppCheckbox;
