
import { Search, Settings, User, XCircle } from "react-feather";
import { capitalizeFirstLetter } from "../../../constants";
import { useAuth, useLanguage } from "../../../context";
import FeatherIcon from "feather-icons-react";
import { useEffect, useState } from "react";
import { useAlert } from "../../../hooks";
import { Image } from "../../Libraries";
import { Link } from "react-router-dom";

const Languages = [
    {
        title: 'English',
        image: 'assets/img/flags/us.png',
        value: 'en'
    },
    {
        title: 'Arabic',
        image: 'assets/img/flags/sa.png',
        value: 'ar'
    }
]

const Navbar = () => {

    const { I18n, handleLanguage } = useLanguage();
    const { handleLogout, user } = useAuth();
    const [handleAlert] = useAlert(handleLogout, true);

    const [selectedLanguage, setSelectedLanguage] = useState(Languages[0]);
    const [toggle, SetToggle] = useState(false);

    useEffect(() => {
        document.addEventListener("mouseover", handleMouseover);

        return () => {
            document.removeEventListener("mouseover", handleMouseover);
        };
    }, []);

    useEffect(() => {
        let lang = Languages.filter(x => x.value == I18n.lang);

        setSelectedLanguage(lang.length ? lang[0] : Languages[0]);
    }, [I18n.lang]);

    const handleMouseover = (e) => {
        e.stopPropagation();

        const body = document.body;
        const toggleBtn = document.getElementById("toggle_btn");

        if (
            body.classList.contains("mini-sidebar") &&
            isElementVisible(toggleBtn)
        ) {
            e.preventDefault();
        }
    };

    const isElementVisible = (element) => {
        return element.offsetWidth > 0 || element.offsetHeight > 0;
    };

    const handlesidebar = () => {
        document.body.classList.toggle("mini-sidebar");
        SetToggle((current) => !current);
    };

    const expandMenu = () => {
        document.body.classList.remove("expand-menu");
    };

    const expandMenuOpen = () => {
        document.body.classList.add("expand-menu");
    };

    const sidebarOverlay = () => {
        document?.querySelector(".main-wrapper")?.classList?.toggle("slide-nav");
        document?.querySelector(".sidebar-overlay")?.classList?.toggle("opened");
        document?.querySelector("html")?.classList?.toggle("menu-opened");
    };

    return (
        <>
            <div className="header">
                {/* Logo */}
                <div
                    className={`header-left ${toggle ? "" : "active"}`}
                    onMouseLeave={expandMenu}
                    onMouseOver={expandMenuOpen}
                >
                    <Link to="/dashboard" className="logo logo-normal">
                        <Image src="assets/img/vlogo-essaly.png" alt="essaly" />
                    </Link>

                    <Link to="/dashboard" className="logo logo-white">
                        <Image src="assets/img/vlogo-essaly-white.png" alt="essaly" />
                    </Link>

                    <Link to="/dashboard" className="logo-small">
                        <Image src="assets/img/logo-essaly-small.png" alt="essaly" />
                    </Link>

                    <Link
                        onClick={handlesidebar}
                        id="toggle_btn"
                        to="#"
                    >
                        <FeatherIcon icon="chevrons-left" className="feather-16" />
                    </Link>
                </div>

                {/* /Logo */}
                <Link
                    onClick={sidebarOverlay}
                    className="mobile_btn"
                    id="mobile_btn"
                    to="#"
                >
                    <span className="bar-icon">
                        <span />
                        <span />
                        <span />
                    </span>
                </Link>

                {/* Header Menu */}
                <ul className="nav user-menu">
                    {/* Search */}
                    <li className="nav-item nav-searchinputs">
                        {/* <div className="top-nav-search">
                            <Link to="#" className="responsive-search">
                                <Search />
                            </Link>

                            <form action="#" className="dropdown">
                                <div
                                    className="searchinputs dropdown-toggle"
                                    id="dropdownMenuClickable"
                                    data-bs-toggle="dropdown"
                                    data-bs-auto-close="false"
                                >
                                    <input type="text" placeholder={I18n.search} />

                                    <div className="search-addon">
                                        <span>
                                            <XCircle className="feather-14" />
                                        </span>
                                    </div>
                                </div>

                                <div
                                    className="dropdown-menu search-dropdown"
                                    aria-labelledby="dropdownMenuClickable"
                                >
                                    <div className="search-info">
                                        <h6>
                                            <span>
                                                <i data-feather="search" className="feather-16" />
                                            </span>
                                            Recent Searches
                                        </h6>

                                        <ul className="search-tags">
                                            <li>
                                                <Link to="#">Products</Link>
                                            </li>

                                            <li>
                                                <Link to="#">Sales</Link>
                                            </li>

                                            <li>
                                                <Link to="#">Applications</Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="search-info">
                                        <h6>
                                            <span>
                                                <i data-feather="help-circle" className="feather-16" />
                                            </span>
                                            Help
                                        </h6>

                                        <p>
                                            How to Change Product Volume from 0 to 200 on Inventory
                                            management
                                        </p>

                                        <p>Change Product Name</p>
                                    </div>

                                    <div className="search-info">
                                        <h6>
                                            <span>
                                                <i data-feather="user" className="feather-16" />
                                            </span>
                                            Customers
                                        </h6>

                                        <ul className="customers">
                                            <li>
                                                <Link to="#">
                                                    Aron Varu
                                                    <Image
                                                        src="assets/img/profiles/avator1.jpg"
                                                        className="img-fluid"
                                                        alt="profiles"
                                                    />
                                                </Link>
                                            </li>

                                            <li>
                                                <Link to="#">
                                                    Jonita
                                                    <Image
                                                        src="assets/img/profiles/avatar-01.jpg"
                                                        className="img-fluid"
                                                        alt="profiles"
                                                    />
                                                </Link>
                                            </li>

                                            <li>
                                                <Link to="#">
                                                    Aaron
                                                    <Image
                                                        src="assets/img/profiles/avatar-10.jpg"
                                                        className="img-fluid"
                                                        alt="profiles"
                                                    />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        </div> */}
                    </li>
                    {/* /Search */}

                    {/* Flag */}
                    <li className="nav-item dropdown has-arrow flag-nav nav-item-box">
                        <Link
                            className="nav-link dropdown-toggle"
                            data-bs-toggle="dropdown"
                            to="#"
                            role="button"
                        >
                            {/* <i data-feather="globe" /> */}
                            {/* <FeatherIcon icon="globe" /> */}
                            <Image
                                src={selectedLanguage.image}
                                height={16}
                                alt="img"
                            />
                        </Link>

                        <div className="dropdown-menu dropdown-menu-right">
                            {Languages.map(language => {
                                return (
                                    <Link key={language.value} to="#" onClick={() => handleLanguage(language.value)} className={`dropdown-item ${I18n.lang == language.value ? 'active' : ''}`}>
                                        <Image
                                            src={language.image}
                                            height={16}
                                            alt="img"
                                        />{" "}
                                        {language.title}
                                    </Link>
                                )
                            })}
                        </div>
                    </li>
                    {/* /Flag */}

                    {/* Notifications */}
                    {/* <li className="nav-item dropdown nav-item-box">
                        <Link
                            to="#"
                            className="dropdown-toggle nav-link"
                            data-bs-toggle="dropdown"
                        >
                            
                            <FeatherIcon icon="bell" />
                            <span className="badge rounded-pill">2</span>
                        </Link>

                        <div className="dropdown-menu notifications">
                            <div className="topnav-dropdown-header">
                                <span className="notification-title">{I18n.notifications}</span>

                                <Link to="#" className="clear-noti">
                                    {" "}
                                    {I18n.clear_all}{" "}
                                </Link>
                            </div>

                            <div className="noti-content">
                                <ul className="notification-list">
                                    <li className="notification-message active">
                                        <Link to="/activities">
                                            <div className="media d-flex">
                                                <span className="avatar flex-shrink-0">
                                                    <Image
                                                        src="assets/img/profiles/avatar-02.jpg"
                                                        alt="img"
                                                    />
                                                </span>

                                                <div className="media-body flex-grow-1">
                                                    <p className="noti-details">
                                                        <span className="noti-title">John Doe</span> added
                                                        new task{" "}
                                                        <span className="noti-title">
                                                            Patient appointment booking
                                                        </span>
                                                    </p>

                                                    <p className="noti-time">
                                                        <span className="notification-time">
                                                            4 mins ago
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>

                                    <li className="notification-message">
                                        <Link to="/activities">
                                            <div className="media d-flex">
                                                <span className="avatar flex-shrink-0">
                                                    <Image
                                                        src="assets/img/profiles/avatar-03.jpg"
                                                        alt="img"
                                                    />
                                                </span>

                                                <div className="media-body flex-grow-1">
                                                    <p className="noti-details">
                                                        <span className="noti-title">Tarah Shropshire</span>{" "}
                                                        changed the task name{" "}
                                                        <span className="noti-title">
                                                            Appointment booking with payment gateway
                                                        </span>
                                                    </p>

                                                    <p className="noti-time">
                                                        <span className="notification-time">
                                                            6 mins ago
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>

                                    <li className="notification-message">
                                        <Link to="/activities">
                                            <div className="media d-flex">
                                                <span className="avatar flex-shrink-0">
                                                    <Image
                                                        src="assets/img/profiles/avatar-06.jpg"
                                                        alt="img"
                                                    />
                                                </span>

                                                <div className="media-body flex-grow-1">
                                                    <p className="noti-details">
                                                        <span className="noti-title">Misty Tison</span>{" "}
                                                        added{" "}
                                                        <span className="noti-title">Domenic Houston</span>{" "}
                                                        and <span className="noti-title">Claire Mapes</span>{" "}
                                                        to project{" "}
                                                        <span className="noti-title">
                                                            Doctor available module
                                                        </span>
                                                    </p>

                                                    <p className="noti-time">
                                                        <span className="notification-time">
                                                            8 mins ago
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>

                                    <li className="notification-message">
                                        <Link to="/activities">
                                            <div className="media d-flex">
                                                <span className="avatar flex-shrink-0">
                                                    <Image
                                                        src="assets/img/profiles/avatar-17.jpg"
                                                        alt="img"
                                                    />
                                                </span>

                                                <div className="media-body flex-grow-1">
                                                    <p className="noti-details">
                                                        <span className="noti-title">Rolland Webber</span>{" "}
                                                        completed task{" "}
                                                        <span className="noti-title">
                                                            Patient and Doctor video conferencing
                                                        </span>
                                                    </p>

                                                    <p className="noti-time">
                                                        <span className="notification-time">
                                                            12 mins ago
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>

                                    <li className="notification-message">
                                        <Link to="/activities">
                                            <div className="media d-flex">
                                                <span className="avatar flex-shrink-0">
                                                    <Image
                                                        src="assets/img/profiles/avatar-13.jpg"
                                                        alt="img"
                                                    />
                                                </span>

                                                <div className="media-body flex-grow-1">
                                                    <p className="noti-details">
                                                        <span className="noti-title">Bernardo Galaviz</span>{" "}
                                                        added new task{" "}
                                                        <span className="noti-title">
                                                            Private chat module
                                                        </span>
                                                    </p>

                                                    <p className="noti-time">
                                                        <span className="notification-time">
                                                            2 days ago
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="topnav-dropdown-footer">
                                <Link to="/activities">View all Notifications</Link>
                            </div>
                        </div>
                    </li> */}
                    {/* /Notifications */}

                    {/* <li className="nav-item nav-item-box">
                        <Link to="/general-settings">
                            <FeatherIcon icon="settings" />
                        </Link>
                    </li> */}

                    <li className="nav-item dropdown has-arrow main-drop">
                        <Link
                            to="#"
                            className="dropdown-toggle nav-link userset"
                            data-bs-toggle="dropdown"
                        >
                            <span className="user-info">
                                <span className="user-letter">
                                    <Image
                                        src="assets/img/profiles/avator1.jpg"
                                        className="img-fluid"
                                        alt="img"
                                    />
                                </span>

                                <span className="user-detail">
                                    <span className="user-name">{user?.name}</span>
                                    <span className="user-role">{capitalizeFirstLetter(user?.role?.name)}</span>
                                </span>
                            </span>
                        </Link>

                        <div className="dropdown-menu menu-drop-user">
                            <div className="profilename">
                                <div className="profileset">
                                    <span className="user-img">
                                        <Image
                                            src="assets/img/profiles/avator1.jpg"
                                            alt="img"
                                        />
                                        <span className="status online" />
                                    </span>

                                    <div className="profilesets">
                                        <h6>{user?.name}</h6>
                                        <h5>{capitalizeFirstLetter(user?.role?.name)}</h5>
                                    </div>
                                </div>

                                <hr className="m-0" />

                                <Link className="dropdown-item" to={'/profile'}>
                                    <User className="me-2" /> My Profile
                                </Link>

                                <Link className="dropdown-item" to={'/settings'}>
                                    <Settings className="me-2" />
                                    Settings
                                </Link>

                                <hr className="m-0" />

                                <Link className="dropdown-item logout pb-0" to="#" onClick={() => handleAlert()}>
                                    <Image
                                        src="assets/img/icons/log-out.svg"
                                        className="me-2"
                                        alt="img"
                                    />
                                    Logout
                                </Link>
                            </div>
                        </div>
                    </li>
                </ul>
                {/* /Header Menu */}

                {/* Mobile Menu */}
                <div className="dropdown mobile-user-menu">
                    <Link
                        to="#"
                        className="nav-link dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <i className="fa fa-ellipsis-v" />
                    </Link>

                    <div className="dropdown-menu dropdown-menu-right">
                        <Link className="dropdown-item" to="profile">
                            My Profile
                        </Link>

                        <Link className="dropdown-item" to="generalsettings">
                            Settings
                        </Link>

                        <Link className="dropdown-item" to="#" onClick={() => handleAlert()}>
                            Logout
                        </Link>
                    </div>
                </div>
                {/* /Mobile Menu */}
            </div>
        </>
    )
}

export default Navbar;
