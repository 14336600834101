import { useLanguage } from "../../../../../../context";
import { RadioButton } from "../../../../../Libraries";
import { Collapse } from "../../../../../common";
import VariableProduct from "./VariableProduct";
import SingleProduct from "./SingleProduct";

const PricingAndStock = ({ selectedVariants, handleFunctions, onChange, product, options, skuLoad, errors, from }) => {
    const { I18n } = useLanguage();

    return (
        <div
            className="accordion-card-one accordion"
            id="accordionExample2"
        >
            <div className="accordion-item">
                <Collapse
                    data={{
                        title: I18n.pricing_and_stocks,
                        target: 'collapseTwo',
                        icon: 'life-buoy',
                        id: 'headingTwo',
                    }} >
                    <div className="accordion-body">
                        {from == 'edit' ? null :
                            <div className="input-blocks add-products">
                                <label className="d-block">{I18n.product_type}</label>
                                <div className="single-pill-product">
                                    <ul
                                        className="nav nav-pills"
                                        id="pills-tab1"
                                        role="tablist"
                                    >
                                        <RadioButton
                                            checked={product['product_type'] == 'single_product'}
                                            onClick={() => onChange('product_type', 'single')}
                                            className="custom_radio me-4 mb-0 active"
                                            lable={I18n['single_product']}
                                            target="pills-home"
                                        />
                                        <RadioButton
                                            onClick={() => onChange('product_type', 'variable')}
                                            checked={product['product_type'] == 'variable'}
                                            className="custom_radio me-2 mb-0"
                                            lable={I18n['variable_product']}
                                            target="pills-profile"
                                        />
                                    </ul>
                                </div>
                            </div>
                        }


                        <div className="tab-content" id="pills-tabContent">
                            <SingleProduct handleFunctions={handleFunctions} onChange={onChange} product={product} loading={skuLoad} errors={errors} from={from} />
                            <VariableProduct selectedVariants={selectedVariants} handleFunctions={handleFunctions} options={options} />
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>
    );
};

export default PricingAndStock;
