import { deletePurchaseOrder, getPurchaseList } from "../../../../../api";
import { Datatable, SearchHeader, SubHeader } from "../../../../common";
import { useLanguage } from "../../../../../context";
import { camelCase } from "../../../../../constants";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../../Libraries";
import { useEffect, useState } from "react";

const PurchaseOrderList = () => {
    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState({
        resultsPerPage: 10,
        totalCount: 0,
        page: 1,
    });

    useEffect(() => {
        handlePurchaseList();
    }, []);

    const onDelete = async (id) => {
        try {
            const data = await deletePurchaseOrder(id, { archive: true });
            if (data.success) handlePurchaseList();
        } catch (err) { }
    };

    const handlePurchaseList = async (reqPage = 1, filters = undefined) => {
        try {
            setLoading(true);

            let params = {
                resultsPerPage: pagination.resultsPerPage,
                page: reqPage
            };

            if (filters) params = { ...params, ...filters };

            const { data } = await getPurchaseList(params);
            const { resultsPerPage, totalCount, purchaseOrders, page } = data || {};

            setPagination({ resultsPerPage, totalCount, page });
            setList(purchaseOrders.map(po => {
                return {
                    status: camelCase(po?.status || ''),
                    supplier: po?.supplier?.name || '',
                    company: po?.company?.name || '',
                    total: `SAR ${po?.total || ''}`,
                    branch: po?.branch?.name || '',
                    reference: po?.poNumber || '',
                    createdAt: po?.createdAt,
                    date: po?.poDate || '',
                    _id: po._id,
                }
            }));

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const onPageChange = (page) => {
        if (page == pagination.current || loading) return;
        handlePurchaseList(page);
    };

    const AddPurchase = () => navigate('/purchase-order/create');

    return (
        <div className="page-wrapper">
            <div className="content">
                <SubHeader
                    data={{
                        subTitle: 'manage_ur_purchase_order',
                        title: 'purchase_order_list',
                        overlays: [
                            {
                                svg: 'assets/img/icons/pdf.svg',
                                tipType: I18n.pdf
                            },
                            {
                                svg: 'assets/img/icons/excel.svg',
                                tipType: I18n.excel
                            },
                            {
                                onClick: handlePurchaseList,
                                Icon: Icon['referesh'],
                                tipType: I18n.refresh,
                                disabled: loading
                            }
                        ],
                        actions: [
                            {
                                className: 'btn btn-added',
                                title: 'add_new_purchase',
                                action: AddPurchase,
                                icon: 'plus',

                            }
                        ],
                    }}
                />

                <div className="card table-list-card">
                    <div className="card-body">
                        <SearchHeader applyFilter={handlePurchaseList} />

                        <div className="table-responsive">
                            <Datatable
                                onPageChange={onPageChange}
                                pagination={pagination}
                                type="purchase-order"
                                onDelete={onDelete}
                                loading={loading}
                                dataSource={list}
                                noOfColumns={8}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PurchaseOrderList;