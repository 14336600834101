import { Icon, PrimaryButton } from "../../../Libraries";
import OverlyBox from "./OverlyBox";
import Heading from "./Heading";

const SubHeader = ({ data }) => {
    const { subTitle, overlays, actions = [], title, flip } = data || {};

    const MainActions = () => actions.map((item, index) => (
        <div className="page-btn" key={index}>
            <PrimaryButton
                Icon={Icon[item.icon]}
                onClick={item.action}
                {...item}
            />
        </div>
    ))

    const OverlayActions = () => overlays?.length ? <ul className="table-top-head">
        {overlays.map((item, index) => <OverlyBox item={item} key={index} />)}
    </ul> : null

    return (
        <div className="page-header">
            <Heading subTitle={subTitle} title={title} />

            {flip ? <MainActions /> : <OverlayActions />}
            {flip ? <OverlayActions /> : <MainActions />}
        </div>
    );
};

export default SubHeader;
