import OverlyBox from "../../../common/Headers/SubHeader/OverlyBox";
import { AppCardBoxLoader, Icon, Image } from "../../../Libraries";
import { useAuth, useLanguage } from "../../../../context";
import RecentTransactions from "./RecentTransactions";
import React, { useEffect, useState } from "react";
import { graphData } from "../../../../constants";
import { getDashboard } from "../../../../api";
import AnalyticsGraph from "./Analytics";
import BestSeller from "./BestSeller";
import Earnigs from "./Earnings";

const Dashbaord = () => {

    const { I18n } = useLanguage();
    const { user } = useAuth();

    const [analyticsData, setAnalyticsData] = useState({});
    const [dashboardInfo, setDashBoardInfo] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        handledashboard();
    }, [])

    const handledashboard = async () => {
        try {
            setLoading(true);

            const { data } = await getDashboard();
            let SP = data?.purchaseAndSales || [];

            setDashBoardInfo(data);

            const values = Object.values(SP).flatMap(item => [item.sales, item.purchase]);
            const minAmount = Math.min(...values);
            const maxAmount = Math.max(...values);

            setAnalyticsData(prev => {
                let all = { ...graphData };
                all['series'][1]['data'] = Object.values(SP).map(item => item.purchase);
                all['series'][0]['data'] = Object.values(SP).map(item => item.sales);
                all['xaxis']['categories'] = Object.keys(SP).map(item => item);
                all['yaxis']['min'] = minAmount;
                all['yaxis']['max'] = maxAmount;

                return all;
            })

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };
    return loading ? <AppCardBoxLoader /> :
        <div className="page-wrapper">
            <div className="content">
                <div className="welcome d-lg-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center welcome-text">
                        <h3 className="d-flex align-items-center">
                            <Image src="assets/img/icons/hi.svg" alt="img" />
                            &nbsp;Hi {user?.name},
                        </h3>
                        &nbsp;
                        <h6>{I18n.here_is_what_is_happening_with_ur_store}</h6>
                    </div>
                    <div className="d-flex align-items-center overLayBox">
                        <OverlyBox
                            item={{
                                onClick: handledashboard,
                                Icon: Icon['referesh'],
                                tipType: I18n.refresh,
                                disabled: loading
                            }}
                        />
                    </div>
                </div>

                <Earnigs data={dashboardInfo} />

                <div className="row">
                    <BestSeller data={dashboardInfo} />
                    <RecentTransactions data={dashboardInfo} />
                </div>

                <AnalyticsGraph data={analyticsData} />
            </div>
        </div>
};

export default Dashbaord;
