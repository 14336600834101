import SingleProduct from "../PricingAndStock/SingleProduct";
import { Collapse } from "../../../../../common";

const Variants = ({ handleFunctions, onChangeVariants, variantsError, skuLoading, variants }) => {
    return Object.keys(variants).map((item, index) => {
        return (
            <div
                className="accordion-card-one accordion"
                id="accordionExample2"
                key={index}
            >
                <div className="accordion-item">
                    <Collapse
                        data={{
                            target: `collapse${index}`,
                            id: `heading${index}`,
                            icon: 'life-buoy',
                            title: item,
                        }} >
                        <div className="accordion-body">
                            <div className="tab-content" id="pills-tabContent">
                                <SingleProduct
                                    handleFunctions={(key, value) => handleFunctions(key, value, item)}
                                    onChange={(key, value) => onChangeVariants(key, value, item)}
                                    errors={variantsError[item]}
                                    loading={skuLoading[item]}
                                    product={variants[item]}
                                    from="variant"
                                    key={index}
                                />
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        )
    });
};

export default Variants;