import React from 'react'
import { useLanguage } from '../../../context';

const AppNotFoundBox = ({title}) => {
    const { I18n } = useLanguage();

  return (
    <div className="row">
        <div className="col-lg-12 col-sm-12">
                <div className="card">
                <div className="card-body">
                    <p>
                        {I18n[title]}
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AppNotFoundBox
