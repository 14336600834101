const API_ENDPOINTS = {
    LOGIN: '/vendor/login',
    AUTHENTICATE: '/vendor/authenticate',
    PUT_GET_DELETE_CUSTOMERS: '/customer',
    CREATE_CUSTOMER: '/customer/create',
    CREATE_PRODUCT: '/product/create',
    PUT_GET_DELETE_PRODUCT: '/product',
    GET_SKU: '/product/sku',
    CREATE_CATEGORY: '/category/create',
    GET_CATEGORY_CODE: '/category/code',
    PUT_GET_DELETE_CATEGORY: '/category',
    CREATE_SUBCATEGORY: '/subcategory/create',
    PUT_GET_DELETE_SUBCATEGORIES: '/subcategory',
    GET_SUBCATEGORY_CODE: '/subcategory/code',
    PUT_GET_DELETE_BRANDS: '/brand',
    CREATE_BRAND: '/brand/create',
    PUT_GET_DELETE_UNIT: '/unit',
    CREATE_UNIT: '/unit/create',
    PUT_GET_DELETE_BRANCHES: '/branch',
    CREATE_BRANCH: '/branch/create',
    PUT_GET_DELETE_OPTIONS: '/option',
    POST_IMAGES: '/image/upload',
    CREATE_OPTION: '/option/create',
    PUT_GET_DELETE_SUPPLIER: '/supplier',
    CREATE_SUPPLIER: '/supplier/create',

    PUT_GET_DELETE_COMPANY: '/company',
    CREATE_COMPANY: '/company/create',

    PUT_GET_DELETE_PURCHASE_TAX: '/tax/purchase',
    CREATE_PURCHASE_TAX: '/tax/purchase/create',

    PUT_GET_DELETE_SALES_TAX: '/tax/sales',
    CREATE_SALES_TAX: '/tax/sales/create',

    PUT_UPDATE_PURCHASE_ORDER_STATUS: '/po/status',
    PUT_GET_DELETE_PURCHASE_ORDER: '/po',
    CREATE_PURCHASE_ORDER: '/po/create',

    PUT_GET_DELETE_DISCOUNT: '/discount',
    CREATE_DISCOUNT: '/discount/create',

    CREATE_GET_INVENTORY_TRANSFER: '/inventory/transfer',
    PUT_GET_DELETE_INVENTORY: '/inventory',
    CREATE_INVENTORY: '/inventory/create',

    PUT_GET_DELETE_ORDER: '/order',
    CREATE_ORDER: '/order/create',

    PUT_GET_DELETE_EMPLOYEES: '/employee',
    CREATE_EMPLOYEES: '/employee/create',

    PUT_GET_DELETE_ROLES: '/role',
    CREATE_ROLES: '/role/create',

    GET_PERMISSIONS: '/role/permission',


    GET_DASHBOARD: '/vendor/dashboard',


};

export default API_ENDPOINTS;