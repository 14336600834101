import { getCategories, getSubCategoryCode, postCreateSubCategory, putUpdateSubCategory } from "../../../../../api";
import { PrimaryButton, Select, TextArea, TextField } from "../../../../Libraries";
import { useLanguage, useToast } from "../../../../../context";
import { Collapse, SubHeader } from "../../../../common";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const CreateSubCategory = ({ from, item }) => {

    const { invokeToast } = useToast();
    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const [load, setLoad] = useState({ categoryLoad: true, loading: false });
    const [categories, setCategories] = useState([]);
    const [errors, setErrors] = useState({});
    const [subCategory, setSubCategory] = useState({
        category: item?.category ? { name: item.category.name, value: item.category } : {},
        description: item?.description || '',
        nameLocal: item?.nameLocal || '',
        name: item?.name || '',
        code: item?.code || '',
    });

    useEffect(() => {
        handleCategories();
    }, []);

    const handleCategories = async () => {
        try {
            const list = await getCategories();

            setCategories(list?.data?.categories || []);
        } catch (err) {
            console.log('err', err)
        } finally {
            setLoad({ ...load, categoryLoad: false });
        }
    };

    const validateSubCategory = () => {
        let obj = {};

        if (!subCategory['category']?.name) obj = { ...obj, category: 'Category is required' };
        if (!subCategory['name']) obj = { ...obj, name: 'Category name is required' };
        if (!subCategory['code']) obj = { ...obj, code: 'Code name is required' };

        return obj;
    };

    const onSave = async (event) => {
        window.scrollTo({ top: 0 });
        event.stopPropagation();
        event.preventDefault();

        try {

            if (Object.values(validateSubCategory()).length) {
                setErrors(validateSubCategory());
                return;
            };

            setLoad({ ...load, loading: true });

            const { description, nameLocal, category, name, code } = subCategory;

            let params = {
                category_id: category.value._id,
                description,
                nameLocal,
                name,
                code
            };

            from == 'edit' ? await putUpdateSubCategory(item._id, params) : await postCreateSubCategory(params);

            invokeToast({ message: `Sub Category ${from == 'edit' ? 'updated' : 'created'} successfully!`, type: 'success' });

            navigate('/subcategory');
        } catch (err) {
            invokeToast({ message: err, type: 'danger' });
        } finally {
            setLoad({ ...load, loading: false });
        }
    };

    const onChange = (key, value) => {
        let error = { ...errors };
        setSubCategory(prev => {
            let all = { ...prev };
            all[key] = value;
            if (error[key]) error[key] = '';
            return all;
        });
        if (errors[key]) setErrors(error);
    };

    const gennerateCode = async () => {
        try {
            let code = await getSubCategoryCode();
            setSubCategory({ ...subCategory, code: code.data.code });
            if (errors['code']) setErrors({ ...errors, code: '' });
        } catch (err) { }
    };

    const handleBack = () => navigate(-1);
    const onCancel = () => navigate(-1);

    return (
        <div className="page-wrapper">
            <div className="content">
                <SubHeader
                    data={{
                        title: from == 'edit' ? 'edit_sub_category' : 'new_sub_category',
                        subTitle: from == 'edit' ? '' : 'create_new_sub_category',
                        flip: true,
                        actions: [{
                            className: 'btn btn-added color',
                            title: 'back_to_sub_category',
                            action: handleBack,
                            icon: 'arrow-left',
                        }]
                    }}
                />

                <form type="submit" onSubmit={onSave} >
                    <div className="card">
                        <div className="card-body add-product pb-0">
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <Collapse
                                        data={{
                                            title: I18n.sub_category_information,
                                            target: 'collapseOne',
                                            id: 'headingOne',
                                            icon: 'info',
                                        }} >
                                    </Collapse>

                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <Select
                                                        options={categories.map(item => ({ value: item, label: item.name }))}
                                                        onChange={(value) => onChange('category', value)}
                                                        className="mb-3 add-product"
                                                        value={subCategory.category}
                                                        placeholder={I18n.choose}
                                                        error={errors?.category}
                                                        label={I18n.category}
                                                        actions={{
                                                            icon: 'plus-circle',
                                                            title: 'add_new',
                                                        }}
                                                    />
                                                </div>

                                                <TextField
                                                    onChange={(value) => onChange('name', value)}
                                                    containerClass='col-lg-4 col-sm-6 col-12'
                                                    placeholder="enter_sub_category_name"
                                                    innerContClass='mb-3 add-product'
                                                    label={'sub_category_name'}
                                                    value={subCategory.name}
                                                    error={errors?.name}
                                                    type={"text"}
                                                />

                                                <TextField
                                                    onChange={(value) => onChange('nameLocal', value)}
                                                    placeholder="enter_local_sub_category_name"
                                                    containerClass='col-lg-4 col-sm-6 col-12'
                                                    innerContClass='mb-3 add-product'
                                                    label={'local_sub_caegory_name'}
                                                    value={subCategory.nameLocal}
                                                    type={"text"}
                                                />

                                            </div>

                                            <div className="row">
                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <div className="input-blocks add-product list">
                                                        <TextField
                                                            onChange={(value) => onChange('code', value)}
                                                            placeholder={'enter_sub_category_code'}
                                                            label={'sub_category_code'}
                                                            value={subCategory.code}
                                                            error={errors?.code}
                                                            type={"text"}
                                                            onlyNumber
                                                        />
                                                        <PrimaryButton
                                                            className='btn btn-primaryadd'
                                                            onClick={gennerateCode}
                                                            title={'generate_code'}
                                                            type="button"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <TextArea
                                                    className="input-blocks summer-description-box transfer mb-3"
                                                    onChange={(value) => onChange('description', value)}
                                                    placeholder="enter_sub_category_description"
                                                    info={I18n['max_sixty_character']}
                                                    value={subCategory.description}
                                                    lable="description"
                                                    maxLength={60}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="btn-addproduct mb-4">
                            <PrimaryButton
                                className='btn btn-cancel me-2'
                                onClick={onCancel}
                                title={'cancel'}
                                type="button"
                            />
                            <PrimaryButton
                                title={'save_sub_category'}
                                className='btn btn-submit'
                                loading={load.loading}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateSubCategory;