import { useLanguage } from "../../../context";

const AppTextArea = ({ placeholder, onChange, lable, error, info, ...rest }) => {

    const { I18n } = useLanguage();

    const handleChange = (event) => {
        let value = event.target.value;
        onChange(value);
    };

    return (
        <div className="input-blocks summer-description-box transfer mb-3">
            <label>{I18n[lable]}</label>
            <textarea
                placeholder={I18n[placeholder] || ''}
                className="form-control h-100"
                onChange={handleChange}
                defaultValue={""}
                rows={5}
                {...rest}
            />
            {error && (
                <div className="error-message">
                    {error}
                </div>
            )}
            {info && <p className="">{info}</p>}
        </div>
    );
};

export default AppTextArea;