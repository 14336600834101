import { postCreateBrand, postImages, putUpdateBrand } from "../../../../../api";
import { AddImage, Collapse, ImageList, SubHeader } from "../../../../common";
import { PrimaryButton, TextArea, TextField } from "../../../../Libraries";
import { useLanguage, useToast } from "../../../../../context";
import { handleUploadImage } from "../../../../../constants";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const CreateBrand = ({ from, item }) => {

    const { invokeToast } = useToast();
    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const [laoding, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [brand, setBrand] = useState({
        description: item?.description || '',
        nameLocal: item?.nameLocal || '',
        image: item?.image || '',
        name: item?.name || '',
    });

    const onChange = (key, value) => {
        let error = { ...errors };
        setBrand(prev => {
            let all = { ...prev };
            all[key] = value;
            if (error[key]) error[key] = '';
            return all;
        });
        if (errors[key]) setErrors(error);
    };

    const removeImage = () => setBrand({ ...brand, image: '' });

    const addImage = (event) => {
        handleUploadImage(event).then(image => {
            setBrand({ ...brand, image });
        });
    };

    const validateCategory = () => {
        let obj = {};

        if (!brand['name']) obj = { ...obj, name: 'Brand name is required' };

        return obj;
    };

    const onSave = async (event) => {
        window.scrollTo({ top: 0 });
        event.stopPropagation();
        event.preventDefault();

        try {

            if (Object.values(validateCategory()).length) {
                setErrors(validateCategory());
                return;
            }

            setLoading(true);

            const { description, nameLocal, image, name } = brand;

            let params = {
                description,
                nameLocal,
                image,
                name,
            };

            if (typeof image == 'object') {
                const imagesFormData = new FormData();

                imagesFormData.append('brand', image);

                const imagesData = await postImages(imagesFormData);

                const uploadedImages = imagesData.data.images;

                if (uploadedImages['brand']) params.image = uploadedImages['brand'];
            };

            from == 'edit' ? await putUpdateBrand(item._id, params) : await postCreateBrand(params);

            invokeToast({ message: `Brand ${from == 'edit' ? 'updated' : 'created'} successfully!`, type: 'success' });

            navigate('/brand');
        } catch (err) {
            invokeToast({ message: err, type: 'danger' });
        } finally {
            setLoading(false);
        }
    }

    const handleBack = () => navigate(-1);
    const onCancel = () => navigate(-1);

    return (
        <div className="page-wrapper">
            <div className="content">

                <SubHeader
                    data={{
                        subTitle: from == 'edit' ? '' : 'create_new_brand',
                        title: from == 'edit' ? 'edit_brand' : 'new_brand',
                        flip: true,
                        actions: [{
                            className: 'btn btn-added color',
                            title: 'back_to_brand',
                            action: handleBack,
                            icon: 'arrow-left',
                        }]
                    }}
                />

                <form type="submit" onSubmit={onSave} >
                    <div className="card">
                        <div className="card-body add-product pb-0">
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <Collapse
                                        data={{
                                            title: I18n.brand_information,
                                            target: 'collapseOne',
                                            id: 'headingOne',
                                            icon: 'info',
                                        }} >
                                        <div
                                            className="accordion-collapse collapse show"
                                            data-bs-parent="#accordionExample"
                                            aria-labelledby="headingOne"
                                            id="collapseOne"
                                        >
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <TextField
                                                        onChange={(value) => onChange('name', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        innerContClass='mb-3 add-product'
                                                        placeholder="enter_brand_name"
                                                        label={'brand_name'}
                                                        error={errors?.name}
                                                        value={brand.name}
                                                        type={"text"}
                                                    />

                                                    <TextField
                                                        onChange={(value) => onChange('nameLocal', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        placeholder="enter_local_brand_name"
                                                        innerContClass='mb-3 add-product'
                                                        label={'local_brand_name'}
                                                        value={brand.nameLocal}
                                                        type={"text"}
                                                    />
                                                </div>

                                                <div className="col-lg-12">
                                                    <TextArea
                                                        className="input-blocks summer-description-box transfer mb-3"
                                                        onChange={(value) => onChange('description', value)}
                                                        placeholder="enter_brand_description"
                                                        info={I18n['max_sixty_character']}
                                                        value={brand.description}
                                                        lable="description"
                                                        maxLength={60}
                                                    />


                                                </div>

                                                <div className="text-editor add-list add">
                                                    <div className="col-lg-12">
                                                        <div className="add-choosen">
                                                            {brand.image ?
                                                                <ImageList
                                                                    removeImage={removeImage}
                                                                    item={brand.image}
                                                                />
                                                                :
                                                                <AddImage addImage={(event) => addImage(event)} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-12">
                        <div className="btn-addproduct mb-4">
                            <PrimaryButton
                                className='btn btn-cancel me-2'
                                onClick={onCancel}
                                title={'cancel'}
                                type="button"
                            />
                            <PrimaryButton
                                className='btn btn-submit'
                                title={'save_brand'}
                                loading={laoding}
                                type="submit"
                            />
                        </div>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default CreateBrand;