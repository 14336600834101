import { useLanguage } from "../../../context";
import { Image } from "..";

const AppTextfield = ({
    containerClass,
    innerContClass,
    placeholder,
    suffixClass,
    inputClass,
    onlyNumber,
    onSuffix,
    onChange,
    onlyDot,
    suffix,
    error,
    label,
    ...res
}) => {

    const { I18n } = useLanguage();

    const handleChange = (event) => {
        let value = event.target.value;
        onChange(onlyNumber ?
            value.replace(/[^0-9]/g, "") :
            onlyDot ? value[0] != '.' ? value.replace(/[^0-9.]|(?<=\..*)\./g, '') : '' :
                value.replace(/^\s+/, ''));
    };

    return (
        <div className={containerClass}>
            {label && <label className="form-label">{I18n[label]}</label>}

            <div className={innerContClass}>
                <input
                    className={`form-control ${inputClass}`}
                    placeholder={I18n[placeholder] || ''}
                    onChange={handleChange}
                    {...res}
                />
                {suffix ?
                    <Image src={suffix} alt="img" />
                    :
                    <span className={suffixClass} onClick={onSuffix && onSuffix} />
                }
                {error && (
                    <div className="error-message">
                        {error}
                    </div>
                )}
            </div>

        </div>
    );
};

export default AppTextfield;
