import { postCreateCompany, postImages, putUpdateCompany } from "../../../../../api";
import { AddImage, Collapse, ImageList, SubHeader } from "../../../../common";
import { PrimaryButton, TextArea, TextField } from "../../../../Libraries";
import { handleUploadImage, SARNO_REGEX } from "../../../../../constants";
import { useLanguage, useToast } from "../../../../../context";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const CreateCompany = ({ from, item }) => {

    const { invokeToast } = useToast();
    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const [laoding, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [company, setCompany] = useState({
        nameLocal: item?.nameLocal || '',
        address: item?.address || '',
        country: item?.country || '',
        phone: item?.phone || '',
        image: item?.image || '',
        name: item?.name || '',
        city: item?.city || '',
    });

    const onChange = (key, value) => {
        let error = { ...errors };
        setCompany(prev => {
            let all = { ...prev };
            all[key] = value;
            if (error[key]) error[key] = '';
            return all;
        });
        if (errors[key]) setErrors(error);
    };

    const removeImage = () => setCompany({ ...company, image: '' });

    const addImage = (event) => {
        handleUploadImage(event).then(image => {
            setCompany({ ...company, image });
        });
    };

    const validateCompany = () => {
        let obj = {};

        if (company['phone'] && !SARNO_REGEX.test(company['phone'])) obj = { ...obj, phone: 'Phone is invalid' };
        else if (!company['phone']) obj = { ...obj, phone: 'Company phone is required' };
        if (!company['address']) obj = { ...obj, address: 'Company address is required' };
        if (!company['name']) obj = { ...obj, name: 'Company name is required' };

        return obj;
    };

    const onSave = async (event) => {
        window.scrollTo({ top: 0 });
        event.stopPropagation();
        event.preventDefault();

        try {

            if (Object.values(validateCompany()).length) {
                setErrors(validateCompany());
                return;
            }

            setLoading(true);

            const { nameLocal, address, country, phone, image, name, city } = company;

            let params = {
                nameLocal,
                address,
                country,
                phone,
                name,
                city,
            };

            if (typeof image == 'object') {
                const imagesFormData = new FormData();

                imagesFormData.append('company', image);

                const imagesData = await postImages(imagesFormData);

                const uploadedImages = imagesData.data.images;

                if (uploadedImages['company']) params.image = uploadedImages['company'];
            };

            from == 'edit' ? await putUpdateCompany(item._id, params) : await postCreateCompany(params);

            invokeToast({ message: `Company ${from == 'edit' ? 'updated' : 'created'} successfully!`, type: 'success' });

            navigate('/store-list');
        } catch (err) {
            invokeToast({ message: err, type: 'danger' });
        } finally {
            setLoading(false);
        }
    }

    const handleBack = () => navigate(-1);
    const onCancel = () => navigate(-1);

    return (
        <div className="page-wrapper">
            <div className="content">

                <SubHeader
                    data={{
                        title: from == 'edit' ? 'edit_company' : 'new_company',
                        subTitle: from == 'edit' ? '' : 'create_new_company',
                        flip: true,
                        actions: [{
                            className: 'btn btn-added color',
                            title: 'back_to_company',
                            action: handleBack,
                            icon: 'arrow-left',
                        }]
                    }}
                />

                <form type="submit" onSubmit={onSave} >
                    <div className="card">
                        <div className="card-body add-product pb-0">
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <Collapse
                                        data={{
                                            title: I18n.company_information,
                                            target: 'collapseOne',
                                            id: 'headingOne',
                                            icon: 'info',
                                        }} >
                                        <div
                                            className="accordion-collapse collapse show"
                                            data-bs-parent="#accordionExample"
                                            aria-labelledby="headingOne"
                                            id="collapseOne"
                                        >
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <TextField
                                                        onChange={(value) => onChange('name', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        innerContClass='mb-3 add-product'
                                                        placeholder="enter_company_name"
                                                        label={'company_name'}
                                                        value={company.name}
                                                        error={errors?.name}
                                                        type={"text"}
                                                    />

                                                    <TextField
                                                        onChange={(value) => onChange('nameLocal', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        placeholder="enter_local_company_name"
                                                        innerContClass='mb-3 add-product'
                                                        label={'local_company_name'}
                                                        value={company.nameLocal}
                                                        type={"text"}
                                                    />

                                                    <TextField
                                                        onChange={(value) => onChange('phone', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        placeholder="enter_company_phone"
                                                        innerContClass='mb-3 add-product'
                                                        label={'company_phone'}
                                                        value={company.phone}
                                                        error={errors?.phone}
                                                        type={"text"}
                                                    />
                                                </div>

                                                <div className="row">


                                                    <TextField
                                                        onChange={(value) => onChange('city', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        innerContClass='mb-3 add-product'
                                                        placeholder="enter_company_city"
                                                        value={company.city}
                                                        label={'company_city'}
                                                        type={"text"}
                                                    />

                                                    <TextField
                                                        onChange={(value) => onChange('country', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        placeholder="enter_company_country"
                                                        innerContClass='mb-3 add-product'
                                                        label={'company_country'}
                                                        value={company.country}
                                                        type={"text"}
                                                    />

                                                </div>

                                                <div className="col-lg-12">
                                                    <TextArea
                                                        className="input-blocks summer-description-box transfer mb-3"
                                                        onChange={(value) => onChange('address', value)}
                                                        placeholder="enter_company_address"
                                                        value={company.address}
                                                        lable="address"
                                                        maxLength={60}
                                                    />
                                                </div>

                                                <div className="text-editor add-list add">
                                                    <div className="col-lg-12">
                                                        <div className="add-choosen">
                                                            {company.image ?
                                                                <ImageList
                                                                    removeImage={removeImage}
                                                                    item={company.image}
                                                                />
                                                                :
                                                                <AddImage addImage={(event) => addImage(event)} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="btn-addproduct mb-4">
                            <PrimaryButton
                                className='btn btn-cancel me-2'
                                onClick={onCancel}
                                title={'cancel'}
                                type="button"
                            />
                            <PrimaryButton
                                className='btn btn-submit'
                                title={'save_company'}
                                loading={laoding}
                                type="submit"
                            />
                        </div>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default CreateCompany;