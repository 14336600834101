import { Image } from "../../../Libraries";
import CountUp from "react-countup";

const AnalyticsBox = ({ className, title, amount, image }) => {
    return (
        <div className="col-xl-3 col-sm-6 col-12">
            <div className={`card color-info bg-${className} mb-4`}>
                <Image
                    src={image}
                    alt="img"
                />
                <h3>
                    {" "}
                    <CountUp end={amount} duration={4}>
                        +
                    </CountUp>
                </h3>
                <p>{title}</p>
            </div>
        </div>
    );
};

export default AnalyticsBox;

