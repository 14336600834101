import { getAuthenticatedUser, postLoginUser } from "../../api";
import { createContext, useContext } from "react"
import { useLocalStorage } from "../../hooks";
import Cookies from 'js-cookie';

export const AuthContext = createContext({
    handleLogout: () => { },
    handleLogin: () => { },
    handleAuth: () => { },
    vendor: null,
    user: null,
});

export const AuthProvider = ({ children }) => {

    const [vendor, setVendor] = useLocalStorage("vendor", null);
    const [user, setUser] = useLocalStorage("user", null);

    const handleLogin = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await postLoginUser(params);

                Cookies.set('access_token', data.token);

                setUser(data.employee);
                setVendor(data.vendor);

                resolve({ user: data.employee, vendor: data.vendor });
            } catch (err) {
                reject(err);
            }
        })
    }

    const handleAuth = () => {
        return new Promise(async (resolve, reject) => {
            try {
                let token = Cookies.get("access_token");

                if (!token) reject('Token not found');

                const { data } = await getAuthenticatedUser();

                setUser(data.employee);
                setVendor(data.vendor);

                resolve({ user: data.employee, vendor: data.vendor });
            } catch (err) {
                reject(err);
            }
        });
    };

    const handleLogout = (navigate) => {
        setUser(null);
        localStorage.clear();
        Cookies.remove("access_token");
        navigate("/login", { replace: true });
    };

    return (
        <AuthContext.Provider value={{ user, vendor, handleLogin, handleAuth, handleLogout }}>
            {children}
        </AuthContext.Provider>
    )
}


export const useAuth = () => useContext(AuthContext);