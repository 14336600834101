import { Fragment } from "react"
import Header from "./Header"
import Body from "./Body"

const Collapse = ({ data, children }) => {
    return (
        <Fragment>
            <Header data={data} />
            <Body id={data.target} children={children} />
        </Fragment>
    );
};

export default Collapse;