import { getBranches, getBrands, getCategories, getOptions, getSalesTaxes, getSku, getSubCategories, getUnits, postImages, postProduct, putUpdateProduct } from "../../../../../api";
import { Loader } from "feather-icons-react/build/IconComponents";
import { handleUploadImage } from "../../../../../constants";
import { PrimaryButton } from "../../../../Libraries";
import ProductInformation from "./ProductInformation";
import React, { useEffect, useState } from "react";
import { useToast } from "../../../../../context";
import PricingAndStock from "./PricingAndStock";
import { SubHeader } from "../../../../common";
import { useNavigate } from "react-router-dom";
import Variants from "./Variants";
import dayjs from "dayjs";

const AddProduct = ({ from, item }) => {

    const { invokeToast } = useToast();
    let navigate = useNavigate();

    const [loader, setLoader] = useState({ loading: false, skuLoad: false, dataLoad: true });
    const [selectedVariants, setSelectedVariants] = useState([]);
    const [variantsError, setVariantsError] = useState({});
    const [skuLoading, setSkuLoading] = useState({});
    const [variants, setVariants] = useState({});
    const [errors, setErrors] = useState({});
    const [product, setProduct] = useState({
        sub_category: item?.subCategory ? { name: item.subCategory.name, value: item.subCategory } : {},
        category: item?.category ? { name: item.category.name, value: item.category } : {},
        sale_tax: item?.salesTax ? { name: item.salesTax.name, value: item.salesTax } : {},
        brand: item?.brand ? { name: item.brand.name, value: item.brand } : {},
        unit: item?.unit ? { name: item.unit.name, value: item.unit } : {},
        expiry_date: item?.expiryDate ? dayjs(item.expiryDate) : '',
        selling_price: item?.sellingPrice || '',
        quantity: item?.minimumQuantity || '',
        description: item?.description || '',
        cost_price: item?.costPrice || '',
        localName: item?.nameLocal || '',
        product_name: item?.name || '',
        barcode: item?.barcode || '',
        images: item?.image || '',
        product_type: 'single',
        sku: item?.sku || '',
        location: {},
        variants: {},
    });
    const [options, setOptions] = useState({
        availableVariants: [],
        subcategories: [],
        categories: [],
        sale_taxes: [],
        location: [],
        brands: [],
        units: [],
    });

    useEffect(() => {
        handleGetDetails();
    }, []);

    useEffect(() => {
        if (product.category?.value?._id) handleSubCategories(product.category.value);
    }, [product.category]);

    const handleGetDetails = async () => {
        try {

            const [categoriesData, salesTaxData, branchesData, optionsData, brandsData, unitsData] = await Promise.all([
                getCategories(),
                getSalesTaxes(),
                getBranches(),
                getOptions(),
                getBrands(),
                getUnits(),
            ]);

            setOptions(prev => {
                let all = { ...prev };
                all['availableVariants'] = optionsData?.data?.options || [];
                all['categories'] = categoriesData?.data?.categories || [];
                all['sale_taxes'] = salesTaxData?.data?.salesTax || [];
                all['location'] = branchesData?.data?.branches || [];
                all['brands'] = brandsData?.data?.brands || [];
                all['units'] = unitsData?.data?.units || [];
                return all;
            });

        } catch (err) {
            invokeToast({ message: err, type: 'danger' });
        } finally {
            setLoader({ ...Loader, dataLoad: false });
        }
    };

    const handleSubCategories = async (category) => {
        try {
            const { data } = await getSubCategories({ category: category._id });
            setOptions({ ...options, subcategories: data?.subCategories || [] });
        } catch (err) {
            console.log(err);
        }
    };

    const onChange = (key, value) => {
        let error = { ...errors };
        setProduct(prev => {
            let all = { ...prev };
            all[key] = value;
            if (error[key]) error[key] = '';
            return all;
        });
        if (errors[key]) setErrors(error);
    };

    const onChangeVariants = (key, value, item) => {
        let error = { ...variantsError };
        setVariants(prev => {
            let all = { ...prev };
            all[item][key] = value?.value || value;
            if (error[item] && error[item][key]) error[item][key] = '';
            return all;
        });
        if (variantsError[item] && variantsError[item][key]) setVariantsError(error);
    };

    const handleGenerateSku = async (key) => {
        if (key) {
            setSkuLoading(prev => {
                let all = { ...prev };
                all[key] = true;
                return all;
            });
        } else setLoader({ ...loader, skuLoad: true })

        let { data } = await getSku();

        if (key) {
            setVariants(prev => {
                let all = { ...prev };
                all[key].sku = data.sku;
                return all;
            });

            setSkuLoading(prev => {
                let all = { ...prev };
                all[key] = false;
                return all;
            });

            if (variantsError[key] && variantsError[key]['sku']) {
                setVariantsError(prev => {
                    let all = { ...prev };
                    all[key].sku = '';
                    return all;
                });
            }
        } else {
            setProduct({ ...product, sku: data.sku });
            setLoader({ ...loader, skuLoad: false });

            if (errors.sku) setErrors({ ...errors, sku: '' });
        }
    };

    const selectVariant = (variant) => {
        setSelectedVariants(prev => {
            let all = [...prev];
            all.push(variant.value);
            return all;
        })
        setOptions(prev => {
            let all = { ...prev };
            all['availableVariants'] = all['availableVariants'].filter(x => x._id != variant.value._id);
            return all;
        });
    };

    const removeSelectedVariant = (variant) => {
        setSelectedVariants(prev => {
            let all = [...prev];
            all = all.filter(x => x._id != variant._id);
            return all;
        });

        setOptions(prev => {
            let all = { ...prev };
            all['availableVariants'] = [...all['availableVariants'], variant]
            return all;
        });
    };

    const generateVariants = () => {
        let combinations = getCombinations(selectedVariants.map(x => x.values));

        setVariants(x => {
            let obj = {};

            combinations.map(option => {
                obj[option] = {
                    product_name: option,
                    expiry_date: null,
                    selling_price: 0,
                    cost_price: 0,
                    quantity: '',
                    barcode: '',
                    images: '',
                    sku: '',
                }
            });

            return obj;
        });
    };

    const removeVariant = (key) => {
        setVariants(prev => {
            let all = { ...prev };
            delete all[key];
            return all;
        });
    };

    const getCombinations = (arr) => {
        if (!arr.length) return []

        if (arr.length == 1) {
            return arr[0];
        } else {
            let ans = [];

            let otherCases = getCombinations(arr.slice(1));

            for (var i = 0; i < otherCases.length; i++) {
                for (var j = 0; j < arr[0].length; j++) {
                    ans.push(`${arr[0][j]}-${otherCases[i]}`);
                }
            }

            return ans;
        }
    };

    const removeImage = (key) => {
        if (key) {
            setVariants(prev => {
                let all = { ...prev };
                all[key]['images'] = '';
                return all;
            });
        } else {
            setProduct(prev => {
                let all = { ...prev };
                all['images'] = '';
                return all;
            });
        }
    };

    const handleFunctions = (key, value, item) => {
        switch (key) {
            case 'generate_sku':
                handleGenerateSku(item);
                break;
            case 'select_variant':
                selectVariant(value);
                break;
            case 'remove_selected_variant':
                removeSelectedVariant(value);
                break;
            case 'generate_variants':
                generateVariants();
                break;
            case 'remove_variant':
                removeVariant(item);
                break;
            case 'remove_image':
                removeImage(item);
                break;
            default:
                uploadImage(value, item);
                break;
        }
    };
    const handleBack = () => navigate(-1);
    const onCancel = () => navigate(-1);

    const validateProduct = () => {
        let obj = {};

        if (product['product_type'] == 'single' && from != 'edit' && !Number(product['quantity'])) obj = { ...obj, quantity: 'Quantity is required' };
        if (product['product_type'] == 'single' && !product['sku']) obj = { ...obj, sku: 'SKU is required' };
        if (from != 'edit' && !product['location'].name) obj = { ...obj, location: 'Location is required' }
        if (!product['product_name']) obj = { ...obj, product_name: 'Product name is required' };
        if (!product['category'].name) obj = { ...obj, category: 'Category is required' }
        if (!product['unit'].name) obj = { ...obj, unit: 'Unit is required' };

        return obj;
    };

    const validateVariant = () => {
        let obj = {};

        Object.keys(variants).map(item => {
            if (!Number(variants[item].quantity)) obj[item] = { ...obj[item], quantity: 'Quantity is required' };
            if (!variants[item].sku) obj[item] = { ...obj[item], sku: 'SKU is required' };
        })

        return obj;
    };

    const onSave = async (event) => {
        window.scrollTo({ top: 0 });
        event.stopPropagation();
        event.preventDefault();

        try {
            let err = false;

            if (product['product_type'] == 'variable' && Object.values(validateVariant()).length) {
                setVariantsError(validateVariant());
                err = true;
            }

            if (Object.values(validateProduct()).length) {
                setErrors(validateProduct());
                err = true;
            }

            if (err == true) return;

            setLoader({ ...Loader, laoding: true });

            let params = {
                subCategory_id: product['sub_category']?.value?._id || undefined,
                category_id: product['category']?.value?._id || undefined,
                salesTax_id: product['sale_tax']?.value?._id || undefined,
                brand_id: product['brand']?.value?._id || undefined,
                unit_id: product['unit']?.value?._id || undefined,
                nameLocal: product['localName'],
                name: product['product_name'],
            };

            if (from != 'edit') params.variants = [];

            const imagesFormData = new FormData();

            if (from == 'edit') {

                params = {
                    ...params,
                    minimumQuantity: product['quantity'] || 0,
                    sellingPrice: product['selling_price'],
                    description: product['description'],
                    expiryDate: product['expiry_date'],
                    costPrice: product['cost_price'],
                    barcode: product['barcode'],
                    sku: product['sku'],
                };

                if (typeof params['image'] == 'object') {

                    imagesFormData.append(params['sku'], params['image']);
                    let { data: { images } } = await postImages(imagesFormData);
                    if (images[params.sku]) params['image'] = images[params.sku];
                }
            } else {
                if (product['product_type'] == 'single') {
                    if (product['images']) imagesFormData.append(product['sku'], product['images']);

                    params.variants.push({
                        sellingPrice: Number(product['selling_price'] || 0),
                        costPrice: Number(product['cost_price'] || 0),
                        description: product['description'],
                        expiryDate: product['expiry_date'],
                        nameLocal: params.nameLocal,
                        barcode: product['barcode'],
                        sku: product['sku'],
                        minimumQuantity: 0,
                        name: params.name,
                        inventory: [{
                            branch_id: product['location']?.value?._id || undefined,
                            quantity: Number(product['quantity'] || 0)
                        }]
                    });
                } else {

                    Object.keys(variants).map((val, index) => {
                        if (variants[val].images) imagesFormData.append(variants[val].sku, variants[val].images);

                        params.variants.push({
                            nameLocal: product['localName'] ? `${product['localName']}-${val}` : '',
                            sellingPrice: Number(variants[val].sellingPrice || 0),
                            costPrice: Number(variants[val].costPrice || 0),
                            name: `${product['product_name']}-${val}`,
                            description: product['description'],
                            expiryDate: variants[val].expiryDate,
                            barcode: variants[val].barcode,
                            sku: variants[val].sku,
                            minimumQuantity: 0,
                            inventory: [{
                                branch_id: product['location']?.value?._id || undefined,
                                quantity: Number(val.quantity || 0)
                            }]
                        });
                    })
                }

                if (!imagesFormData.entries().next().done) {
                    let { data: { images } } = await postImages(imagesFormData);
                    params.variants.map(variant => {
                        if (images[variant.sku]) variant['image'] = images[variant.sku]
                    });
                }
            }

            from == 'edit' ? await putUpdateProduct(item._id, params) : await postProduct(params);

            invokeToast({ message: `Product ${from == 'edit' ? 'edited' : 'created'} successfully!`, type: 'success' });

            navigate('/product');

        } catch (err) {
            invokeToast({ message: err, type: 'danger' });
        } finally {
            setLoader({ ...Loader, laoding: false });
        }

    };

    const uploadImage = (event, key) => {
        handleUploadImage(event).then(image => {
            if (key) {
                setVariants(prev => {
                    let all = { ...prev };
                    all[key]['images'] = image;
                    return all;
                })
            } else {
                setProduct(prev => {
                    let all = { ...prev };
                    all['images'] = image;
                    return all;
                })
            }
        });
    };

    return (
        <div className="page-wrapper">
            <div className="content">
                <SubHeader
                    data={{
                        title: from == 'edit' ? 'edit_product' : 'new_product',
                        subTitle: from == 'edit' ? '' : 'create_new_product',
                        flip: true,
                        actions: [{
                            className: 'btn btn-added color',
                            title: 'back_to_product',
                            action: handleBack,
                            icon: 'arrow-left',
                        }]
                    }}
                />

                <form type="submit" onSubmit={onSave}>
                    <div className="card">
                        <div className="card-body add-product pb-0">
                            <ProductInformation onChange={onChange} product={product} options={options} loading={loader.dataLoad} errors={errors} from={from} />
                            <PricingAndStock selectedVariants={selectedVariants} handleFunctions={handleFunctions} onChange={onChange} product={product} options={options} skuLoad={loader.skuLoad} errors={errors} from={from} />
                            <Variants handleFunctions={handleFunctions} onChangeVariants={onChangeVariants} variantsError={variantsError} skuLoading={skuLoading} variants={variants} />
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="btn-addproduct mb-4">
                            <PrimaryButton
                                className='btn btn-cancel me-2'
                                onClick={onCancel}
                                title={'cancel'}
                                type="button"
                            />
                            <PrimaryButton
                                className='btn btn-submit'
                                laoding={loader.laoding}
                                title={'save_product'}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddProduct;
