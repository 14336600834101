import { useLanguage } from "../../../../context";

const Heading = ({ subTitle, title }) => {
    const { I18n } = useLanguage();

    return (
        <div className="add-item d-flex">
            <div className="page-title">
                <h4>{I18n[title]}</h4>
                <h6>{I18n[subTitle]}</h6>
            </div>
        </div>
    );
};

export default Heading;