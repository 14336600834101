import { deletePurchaseTax, getPurchaseTaxes } from "../../../../../api";
import { Datatable, SearchHeader, SubHeader } from "../../../../common";
import { useLanguage } from "../../../../../context";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../../Libraries";
import { useEffect, useState } from "react";

const PurchaseTaxList = () => {
    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState({
        resultsPerPage: 10,
        totalCount: 0,
        page: 1,
    });

    useEffect(() => {
        handlePurchaseTaxes();
    }, []);

    const onDelete = async (id) => {
        try {
            const data = await deletePurchaseTax(id, { archive: true });
            if (data.success) handlePurchaseTaxes();
        } catch (err) { }
    };

    const handlePurchaseTaxes = async (reqPage = 1, filters = undefined) => {
        try {
            setLoading(true);

            let params = {
                resultsPerPage: pagination.resultsPerPage,
                page: reqPage
            };

            if (filters) params = { ...params, ...filters };

            const { data } = await getPurchaseTaxes(params);
            const { resultsPerPage, totalCount, purchaseTaxes, page } = data || {};

            setPagination({ resultsPerPage, totalCount, page });
            setList(purchaseTaxes.map(purchaseTax => {
                return {
                    createdAt: purchaseTax.createdAt,
                    rate: purchaseTax?.rate || '',
                    name: purchaseTax.name || '',
                    _id: purchaseTax._id,
                }
            }));
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const onPageChange = (page) => {
        if (page == pagination.current || loading) return;
        handlePurchaseTaxes(page);
    };

    const AddPurchaseTax = () => navigate('/purchase-tax/create');

    return (
        <div className="page-wrapper">
            <div className="content">
                <SubHeader
                    data={{
                        subTitle: 'manage_ur_purchase_taxes',
                        title: 'purchase_tax_list',
                        overlays: [
                            {
                                svg: 'assets/img/icons/pdf.svg',
                                tipType: I18n.pdf
                            },
                            {
                                svg: 'assets/img/icons/excel.svg',
                                tipType: I18n.excel
                            },
                            {
                                onClick: handlePurchaseTaxes,
                                Icon: Icon['referesh'],
                                tipType: I18n.refresh,
                                disabled: loading
                            }
                        ],
                        actions: [
                            {
                                title: 'add_new_purchase_tax',
                                className: 'btn btn-added',
                                action: AddPurchaseTax,
                                icon: 'plus',

                            }
                        ],
                    }}
                />

                <div className="card table-list-card">
                    <div className="card-body">
                        <SearchHeader applyFilter={handlePurchaseTaxes} />

                        <div className="table-responsive">
                            <Datatable
                                onPageChange={onPageChange}
                                pagination={pagination}
                                onDelete={onDelete}
                                type="purchase-tax"
                                loading={loading}
                                dataSource={list}
                                noOfColumns={3}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PurchaseTaxList;