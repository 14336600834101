import { getCategoryCode, postCreateCategory, postImages, putUpdateCategory } from "../../../../../api";
import { AddImage, Collapse, ImageList, SubHeader } from "../../../../common";
import { PrimaryButton, TextArea, TextField } from "../../../../Libraries";
import { useLanguage, useToast } from "../../../../../context";
import { handleUploadImage } from "../../../../../constants";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const CreateCategory = ({ from, item }) => {

    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const { invokeToast } = useToast();

    const [laoding, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [category, setCategory] = useState({
        description: item?.description || '',
        nameLocal: item?.nameLocal || '',
        image: item?.image || '',
        name: item?.name || '',
        code: item?.code || '',
    });

    const onChange = (key, value) => {
        let error = { ...errors };
        setCategory(prev => {
            let all = { ...prev };
            all[key] = value;
            if (error[key]) error[key] = '';
            return all;
        });
        if (errors[key]) setErrors(error);
    };

    const gennerateCode = async () => {
        try {
            let code = await getCategoryCode();
            setCategory({ ...category, code: code.data.code });
            if (errors['code']) setErrors({ ...errors, code: '' });
        } catch (err) { }
    };

    const removeImage = () => setCategory({ ...category, image: '' });

    const addImage = (event) => {
        handleUploadImage(event).then(image => {
            setCategory({ ...category, image });
        });
    };

    const validateCategory = () => {
        let obj = {};

        if (!category['name']) obj = { ...obj, name: 'Category name is required' };
        if (!category['code']) obj = { ...obj, code: 'Code name is required' };

        return obj;
    };

    const onSave = async (event) => {
        window.scrollTo({ top: 0 });
        event.stopPropagation();
        event.preventDefault();

        try {

            if (Object.values(validateCategory()).length) {
                setErrors(validateCategory());
                return;
            }

            setLoading(true);

            const { description, nameLocal, image, name, code } = category;

            let paramsForCategory = {
                description,
                nameLocal,
                image,
                code,
                name,
            };

            if (typeof image == 'object') {
                const imagesFormData = new FormData();

                imagesFormData.append(code, image);

                const imagesData = await postImages(imagesFormData);
                const uploadedImages = imagesData.data.images;

                if (uploadedImages[code]) paramsForCategory.image = uploadedImages[code];
            };

            from == 'edit' ? await putUpdateCategory(item._id, paramsForCategory) : await postCreateCategory(paramsForCategory);

            invokeToast({ message: `Category ${from == 'edit' ? 'updated' : 'created'} successfully!`, type: 'success' });

            navigate('/category');
        } catch (err) {
            invokeToast({ message: err, type: 'danger' });
        } finally {
            setLoading(false);
        }
    };

    const handleBack = () => navigate(-1);
    const onCancel = () => navigate(-1);

    return (
        <div className="page-wrapper">
            <div className="content">

                <SubHeader
                    data={{
                        title: from == 'edit' ? 'edit_category' : 'new_category',
                        subTitle: from == 'edit' ? '' : 'create_new_category',
                        flip: true,
                        actions: [{
                            className: 'btn btn-added color',
                            title: 'back_to_category',
                            action: handleBack,
                            icon: 'arrow-left',
                        }]
                    }}
                />

                <form type="submit" onSubmit={onSave} >
                    <div className="card">
                        <div className="card-body add-product pb-0">
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <Collapse
                                        data={{
                                            title: I18n.category_information,
                                            target: 'collapseOne',
                                            id: 'headingOne',
                                            icon: 'info',
                                        }} >
                                        <div
                                            className="accordion-collapse collapse show"
                                            data-bs-parent="#accordionExample"
                                            aria-labelledby="headingOne"
                                            id="collapseOne"
                                        >
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <TextField
                                                        onChange={(value) => onChange('name', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        innerContClass='mb-3 add-product'
                                                        placeholder="enter_category_name"
                                                        label={'category_name'}
                                                        value={category.name}
                                                        error={errors?.name}
                                                        type={"text"}
                                                    />

                                                    <TextField
                                                        onChange={(value) => onChange('nameLocal', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        placeholder="enter_local_category_name"
                                                        innerContClass='mb-3 add-product'
                                                        label={'local_category_name'}
                                                        value={category.nameLocal}
                                                        type={"text"}
                                                    />

                                                    <div className="col-lg-4 col-sm-6 col-12">
                                                        <div className="input-blocks add-product list">
                                                            <TextField
                                                                onChange={(value) => onChange('code', value)}
                                                                placeholder={'enter_category_code'}
                                                                label={'category_code'}
                                                                value={category.code}
                                                                error={errors?.code}
                                                                type={"text"}
                                                                onlyNumber
                                                            />
                                                            <PrimaryButton
                                                                className='btn btn-primaryadd'
                                                                onClick={gennerateCode}
                                                                title={'generate_code'}
                                                                type="button"
                                                            />

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <TextArea
                                                        className="input-blocks summer-description-box transfer mb-3"
                                                        onChange={(value) => onChange('description', value)}
                                                        placeholder="enter_category_description"
                                                        info={I18n['max_sixty_character']}
                                                        value={category.description}
                                                        lable="description"
                                                        maxLength={60}
                                                    />


                                                </div>

                                                <div className="text-editor add-list add">
                                                    <div className="col-lg-12">
                                                        <div className="add-choosen">
                                                            {category.image ?
                                                                <ImageList
                                                                    removeImage={removeImage}
                                                                    item={category.image}
                                                                />
                                                                :
                                                                <AddImage addImage={(event) => addImage(event)} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="col-lg-12">
                        <div className="btn-addproduct mb-4">
                            <PrimaryButton
                                className='btn btn-cancel me-2'
                                onClick={onCancel}
                                title={'cancel'}
                                type="button"
                            />
                            <PrimaryButton
                                className='btn btn-submit'
                                title={'save_category'}
                                loading={laoding}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreateCategory;