import { formatNum } from "../../../../../constants";
import { getProductById } from "../../../../../api";
import { AppCardBoxLoader, AppNotFoundBox, Image } from "../../../../Libraries";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Barcode from "react-barcode";
import { SubHeader } from "../../../../common";
import { useLanguage } from "../../../../../context";

const ProductDetail = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [product, setPorduct] = useState({});

    useEffect(() => {
        if (id) getProduct();
        else setLoading(false);
    }, [id]);

    const getProduct = async () => {
        try {
            const { data } = await getProductById(id);
            setPorduct(data.product);
        } catch (err) { }
        finally {
            setLoading(false);
        }
    };

    return loading ? <AppCardBoxLoader />         
        :
            <div className="page-wrapper">
                <div className="content">
                    <SubHeader
                        data={{
                            subTitle: 'full_details_of_a_product',
                            title: 'product_details',                           
                        }}
                    />
                    {product?._id ?
                        <div className="row">
                            <div className="col-lg-8 col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        {/* <div className="bar-code-view">
                                            <Barcode value={product?.sku} />
                                        </div> */}
                                        <div className="productdetails">
                                            <ul className="product-bar">
                                                <li>
                                                    <h4>Product</h4>
                                                    <h6>{product.name}</h6>
                                                </li>
                                                <li>
                                                    <h4>Category</h4>
                                                    <h6>{product?.category?.name}</h6>
                                                </li>
                                                <li>
                                                    <h4>Sub Category</h4>
                                                    <h6>{product?.sub_category?.name || 'None'}</h6>
                                                </li>
                                                <li>
                                                    <h4>Brand</h4>
                                                    <h6>{product?.brand?.name || 'None'}</h6>
                                                </li>
                                                <li>
                                                    <h4>Unit</h4>
                                                    <h6>{product?.unit?.name || 'None'}</h6>
                                                </li>
                                                <li>
                                                    <h4>SKU</h4>
                                                    <h6>{product?.sku || 'None'}</h6>
                                                </li>
                                                <li>
                                                    <h4>Quantity</h4>
                                                    <h6>{product?.minimumQuantity || '0'}</h6>
                                                </li>
                                                <li>
                                                    <h4>Price</h4>
                                                    <h6>{formatNum(product?.sellingPrice || 0)}</h6>
                                                </li>
                                                <li>
                                                    <h4>Status</h4>
                                                    <h6>{product.isActive ? 'Active' : 'Un-Active'}</h6>
                                                </li>
                                                <li>
                                                    <h4>Description</h4>
                                                    <h6>{product?.description || 'None'}</h6>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {product?.image ?
                                <div className="col-lg-4 col-sm-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="slider-product-details">
                                                <div className="owl-carousel owl-theme product-slide">
                                                    <div className="slider-product">
                                                        <Image src={product.image} alt="img" />
                                                        <h4>
                                                            {product.name}
                                                        </h4>
                                                        {/* <h4>{product.name}.{product.image.split('.').pop()}</h4>
                                                        <h6>581kb</h6> */}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                        :
                        <AppNotFoundBox title='failed_to_load_product' />
                    }
                </div>
            </div>            
            
};

export default ProductDetail;