const AppRadioButton = ({ className, onClick, target, lable, ...rest }) => {
    return (
        <li className="nav-item" role="presentation" onClick={onClick}>
            <span
                data-bs-target={`#${target}`}
                className={className}
                data-bs-toggle="pill"
            >
                <input
                    className="form-control"
                    type="radio"
                    {...rest}
                />
                <span className="checkmark" /> {lable}
            </span>
        </li>
    );
};

export default AppRadioButton;
