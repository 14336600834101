import { createContext, useContext, useState } from "react";
import Toast from 'react-bootstrap/Toast';

export const ToastContext = createContext({
    invokeToast: () => { }
});

export const ToastProvider = ({ children }) => {

    const [isShow, setIsShow] = useState(false);
    const [message, setMessage] = useState('');
    const [type, setType] = useState('error');

    const invokeToast = ({ message, type = 'error' }) => {
        setType(type);
        setMessage(message);
        setIsShow(true);
    };

    return (
        <ToastContext.Provider value={{ invokeToast }}>
            {children}

            <div className="toast-container position-fixed bottom-0 end-0 p-3">
                <Toast
                    bg={type == 'success' ? 'success' : type == 'info' ? 'info' : 'danger'}
                    onClose={() => setIsShow(false)}
                    show={isShow}
                    delay={3000}
                    autohide
                >
                    <Toast.Body>{message}</Toast.Body>
                </Toast>
            </div>
        </ToastContext.Provider>
    )
}

export const useToast = () => useContext(ToastContext);