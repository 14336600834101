import { AppCardBoxLoader, AppNotFoundBox } from "../../../../Libraries";
import { getPurchaseOrderById } from "../../../../../api";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CreatePurchaseOrder } from "..";

const EditPurchaseOrder = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState({});

    useEffect(() => {
        if (id) getCustomer();
        else setLoading(false);
    }, [id]);

    const getCustomer = async () => {
        try {
            const { data } = await getPurchaseOrderById(id);
            const { purchaseOrderLines, purchaseOrder } = data || {};
            setItem({ POLine: purchaseOrderLines, PO: purchaseOrder });
        } catch (err) { }
        finally {
            setLoading(false);
        }
    };


    return loading ?
        <AppCardBoxLoader />
        : item?.PO?._id ?
            <CreatePurchaseOrder from="edit" item={item} />
            :
            <AppNotFoundBox title='failed_to_load_customer' />
};

export default EditPurchaseOrder;