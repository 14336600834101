import { useLanguage } from "../../../context";
import AppSpinner from "../AppSpinner";
import { Fragment } from "react";

const Primary = ({ className, disabled, loading, title, Icon, auth, ...rest }) => {
    const { I18n } = useLanguage();
    return (
        <Fragment>
            <button className={className} disabled={loading || disabled} {...rest}>
                {Icon ? Icon : null}
                {loading ? (
                    <AppSpinner auth={auth} />
                ) : I18n[title]
                }
            </button>
        </Fragment>
    )
};

export default Primary;