import { PrimaryButton, TextArea, TextField } from "../../../../Libraries";
import { postCreateCustomer, putUpdateCustomer } from "../../../../../api";
import { EMAIL_REGEX, SARNO_REGEX } from "../../../../../constants";
import { useLanguage, useToast } from "../../../../../context";
import { Collapse, SubHeader } from "../../../../common";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const CreateCustomer = ({ from, item }) => {

    const { invokeToast } = useToast();
    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [customer, setCustomer] = useState({
        essalyCode: item?.essalyCode || '',
        nameLocal: item?.nameLocal || '',
        address: item?.address || '',
        country: item?.country || '',
        phone: item?.phone || '',
        email: item?.email || '',
        name: item?.name || '',
        city: item?.city || '',
    });

    const validateCustomer = () => {
        let obj = {};

        if (customer['email'] && !EMAIL_REGEX.test(customer['email'])) obj = { ...obj, email: 'Email is invalid' };
        if (customer['phone'] && !SARNO_REGEX.test(customer['phone'])) obj = { ...obj, phone: 'Phone is invalid' };
        else if (!customer['phone']) obj = { ...obj, phone: 'Customer phone is required' };
        if (!customer['name']) obj = { ...obj, name: 'Customer name is required' };

        return obj;
    };

    const onSave = async (event) => {
        window.scrollTo({ top: 0 });
        event.stopPropagation();
        event.preventDefault();

        try {

            if (Object.values(validateCustomer()).length) {
                setErrors(validateCustomer());
                return;
            };

            setLoading(true);

            const { nameLocal, address, country, phone, email, name, city } = customer;

            let params = {
                nameLocal,
                address,
                country,
                email,
                phone,
                city,
                name,
            };

            from == 'edit' ? await putUpdateCustomer(item._id, params) : await postCreateCustomer(params);

            invokeToast({ message: `Customer ${from == 'edit' ? 'updated' : 'created'} successfully!`, type: 'success' });

            navigate('/customer');
        } catch (err) {
            invokeToast({ message: err, type: 'danger' });
        } finally {
            setLoading(false);
        }
    };

    const onChange = (key, value) => {
        let error = { ...errors };
        setCustomer(prev => {
            let all = { ...prev };
            all[key] = value;
            if (error[key]) error[key] = '';
            return all;
        });
        if (errors[key]) setErrors(error);
    };

    const handleBack = () => navigate(-1);
    const onCancel = () => navigate(-1);

    return (
        <div className="page-wrapper">
            <div className="content">
                <SubHeader
                    data={{
                        title: from == 'edit' ? 'edit_customer' : 'new_customer',
                        subTitle: from == 'edit' ? '' : 'create_new_customer',
                        flip: true,
                        actions: [{
                            className: 'btn btn-added color',
                            title: 'back_to_customers',
                            action: handleBack,
                            icon: 'arrow-left',
                        }]
                    }}
                />

                <form type="submit" onSubmit={onSave} >
                    <div className="card">
                        <div className="card-body add-product pb-0">
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <Collapse
                                        data={{
                                            title: I18n.customer_information,
                                            target: 'collapseOne',
                                            id: 'headingOne',
                                            icon: 'info',
                                        }} >
                                    </Collapse>

                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div className="row">
                                                <TextField
                                                    onChange={(value) => onChange('name', value)}
                                                    containerClass='col-lg-4 col-sm-6 col-12'
                                                    placeholder="enter_customer_name"
                                                    innerContClass='mb-3 add-product'
                                                    label={'customer_name'}
                                                    value={customer.name}
                                                    error={errors?.name}
                                                    type={"text"}
                                                />

                                                <TextField
                                                    onChange={(value) => onChange('nameLocal', value)}
                                                    containerClass='col-lg-4 col-sm-6 col-12'
                                                    placeholder="enter_local_customer_name"
                                                    innerContClass='mb-3 add-product'
                                                    label={'local_customer_name'}
                                                    value={customer.nameLocal}
                                                    type={"text"}
                                                />

                                                <TextField
                                                    onChange={(value) => onChange('email', value)}
                                                    containerClass='col-lg-4 col-sm-6 col-12'
                                                    placeholder="enter_customer_email"
                                                    innerContClass='mb-3 add-product'
                                                    value={customer.email}
                                                    label={'email'}
                                                    type={"text"}
                                                />

                                            </div>

                                            <div className="row">
                                                <TextField
                                                    onChange={(value) => onChange('phone', value)}
                                                    containerClass='col-lg-4 col-sm-6 col-12'
                                                    placeholder="enter_customer_phone"
                                                    innerContClass='mb-3 add-product'
                                                    value={customer.phone}
                                                    error={errors?.phone}
                                                    label={'phone'}
                                                    type={"text"}
                                                />

                                                <TextField
                                                    onChange={(value) => onChange('city', value)}
                                                    containerClass='col-lg-4 col-sm-6 col-12'
                                                    placeholder="enter_customer_city"
                                                    innerContClass='mb-3 add-product'
                                                    value={customer.city}
                                                    label={'city'}
                                                    type={"text"}
                                                />

                                                <TextField
                                                    onChange={(value) => onChange('country', value)}
                                                    containerClass='col-lg-4 col-sm-6 col-12'
                                                    placeholder="enter_customer_country"
                                                    innerContClass='mb-3 add-product'
                                                    value={customer.country}
                                                    label={'country'}
                                                    type={"text"}
                                                />

                                            </div>

                                            <div className="row">
                                                <TextField
                                                    onChange={(value) => onChange('essalyCode', value)}
                                                    containerClass='col-lg-4 col-sm-6 col-12'
                                                    placeholder="enter_customer_code"
                                                    innerContClass='mb-3 add-product'
                                                    value={customer.essalyCode}
                                                    label="customer_code"
                                                    type={"text"}
                                                    onlyNumber
                                                />
                                            </div>

                                            <div className="col-lg-12">
                                                <TextArea
                                                    className="input-blocks summer-description-box transfer mb-3"
                                                    onChange={(value) => onChange('address', value)}
                                                    placeholder="enter_customer_address"
                                                    value={customer.address}
                                                    lable="address"
                                                    maxLength={60}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="btn-addproduct mb-4">
                            <PrimaryButton
                                className='btn btn-cancel me-2'
                                onClick={onCancel}
                                title={'cancel'}
                                type="button"
                            />
                            <PrimaryButton
                                className='btn btn-submit'
                                title={'save_customer'}
                                loading={loading}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateCustomer;