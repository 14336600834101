import AppCheckbox from "../../../../Libraries/AppCheckbox";
import { camelCase } from "../../../../../constants";
import { getRoleById } from "../../../../../api";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { AppCardBoxLoader, AppNotFoundBox } from "../../../../Libraries";
import { SubHeader } from "../../../../common";

const RolePermisisonDetails = () => {

    const { id } = useParams();

    const [permisisonsList, setPermisisonsList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        handleGetRolePermissions();
    }, []);

    const handleGetRolePermissions = async () => {
        try {

            const { data } = await getRoleById(id);

            setPermisisonsList(data?.role?.permissions || []);

        } catch (err) {
            console.log('err', err)
        } finally {
            setLoading(false);
        }
    };

    return loading ? <AppCardBoxLoader /> : permisisonsList?.length ?
        <div className="page-wrapper">
            <div className="content">
                <SubHeader
                        data={{
                            subTitle: 'full_detail_roles_permissions',
                            title: 'roles_permissions_details',                           
                        }}
                    />
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table datanew">
                                            <thead>
                                                <tr>
                                                    <th>Modules</th>
                                                    <th>Create</th>
                                                    <th>Edit</th>
                                                    <th>Delete</th>
                                                    <th>View</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {permisisonsList.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{camelCase(item.name)}</td>
                                                            <td>
                                                                <AppCheckbox checked={item['canCreate']} disabled />
                                                            </td>
                                                            <td>
                                                                <AppCheckbox checked={item['canUpdate']} disabled />
                                                            </td>
                                                            <td>
                                                                <AppCheckbox checked={item['canDelete']} disabled />
                                                            </td>
                                                            <td>
                                                                <AppCheckbox checked={item['canRead']} disabled />
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        :
        <AppNotFoundBox title='failed_to_load_roles_permission' />
};

export default RolePermisisonDetails;