import { PrimaryButton, TextField } from "../../../../Libraries";
import { postCreateUnit, putUpdateUnit } from "../../../../../api";
import { useLanguage, useToast } from "../../../../../context";
import { Collapse, SubHeader } from "../../../../common";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const CreateUnit = ({ from, item }) => {

    const { invokeToast } = useToast();
    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const [laoding, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [unit, setUnit] = useState({
        nameLocal: item?.nameLocal || '',
        short: item?.short || '',
        name: item?.name || '',
    });

    const onChange = (key, value) => {
        let error = { ...errors };
        setUnit(prev => {
            let all = { ...prev };
            all[key] = value;
            if (error[key]) error[key] = '';
            return all;
        });
        if (errors[key]) setErrors(error);
    };

    const validateUnit = () => {
        let obj = {};

        if (!unit['short']) obj = { ...obj, short: 'Short name is required' };
        if (!unit['name']) obj = { ...obj, name: 'Brand name is required' };

        return obj;
    };

    const onSave = async (event) => {
        window.scrollTo({ top: 0 });
        event.stopPropagation();
        event.preventDefault();

        try {

            if (Object.values(validateUnit()).length) {
                setErrors(validateUnit());
                return;
            }

            setLoading(true);

            const { nameLocal, short, name } = unit;

            let params = {
                nameLocal,
                short,
                name,
            };

            from == 'edit' ? await putUpdateUnit(item._id, params) : await postCreateUnit(params);

            invokeToast({ message: `Unit ${from == 'edit' ? 'updated' : 'created'} successfully!`, type: 'success' });

            navigate('/unit');
        } catch (err) {
            invokeToast({ message: err, type: 'danger' });
        } finally {
            setLoading(false);
        }
    };

    const handleBack = () => navigate(-1);
    const onCancel = () => navigate(-1);

    return (
        <div className="page-wrapper">
            <div className="content">

                <SubHeader
                    data={{
                        subTitle: from == 'edit' ? '' : 'create_new_unit',
                        title: from == 'edit' ? 'edit_unit' : 'new_unit',
                        flip: true,
                        actions: [{
                            className: 'btn btn-added color',
                            title: 'back_to_unit',
                            action: handleBack,
                            icon: 'arrow-left',
                        }]
                    }}
                />

                <form type="submit" onSubmit={onSave} >
                    <div className="card">
                        <div className="card-body add-product pb-0">
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <Collapse
                                        data={{
                                            title: I18n.unit_information,
                                            target: 'collapseOne',
                                            id: 'headingOne',
                                            icon: 'info',
                                        }} >
                                        <div
                                            className="accordion-collapse collapse show"
                                            data-bs-parent="#accordionExample"
                                            aria-labelledby="headingOne"
                                            id="collapseOne"
                                        >
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <TextField
                                                        onChange={(value) => onChange('name', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        innerContClass='mb-3 add-product'
                                                        placeholder="enter_unit_name"
                                                        error={errors?.name}
                                                        label={'unit_name'}
                                                        value={unit.name}
                                                        type={"text"}
                                                    />

                                                    <TextField
                                                        onChange={(value) => onChange('nameLocal', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        placeholder="enter_local_unit_name"
                                                        innerContClass='mb-3 add-product'
                                                        label={'local_unit_name'}
                                                        value={unit.nameLocal}
                                                        type={"text"}
                                                    />

                                                    <TextField
                                                        onChange={(value) => onChange('short', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        innerContClass='mb-3 add-product'
                                                        placeholder="enter_short_name"
                                                        error={errors?.short}
                                                        label={'short_name'}
                                                        value={unit.short}
                                                        type={"text"}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-12">
                        <div className="btn-addproduct mb-4">
                            <PrimaryButton
                                className='btn btn-cancel me-2'
                                onClick={onCancel}
                                title={'cancel'}
                                type="button"
                            />
                            <PrimaryButton
                                className='btn btn-submit'
                                title={'save_unit'}
                                loading={laoding}
                                type="submit"
                            />
                        </div>
                    </div>

                </form>
            </div>
        </div>
    )
}

export default CreateUnit;