import { useLanguage } from "../../../../context";
import { Image } from "../../../Libraries";
import { Link } from "react-router-dom";

const BestSeller = ({ data }) => {

    const { bestSellers } = data || {};

    const { I18n } = useLanguage();

    return (
        <div className="col-sm-12 col-md-12 col-xl-4 d-flex">
            <div className="card flex-fill default-cover w-100 mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h4 className="card-title mb-0">{I18n.best_seller}</h4>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-borderless best-seller">
                            <tbody>
                                {bestSellers.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <div className="product-info">
                                                    <Link to={"#"} className="product-img" >
                                                        <Image
                                                            src={item?.productImage || "assets/img/products/stock-img-01.png"}
                                                            alt="product"
                                                        />
                                                    </Link>
                                                    <div className="info">
                                                        <Link to={"#"}>
                                                            {item.productName}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <p className="head-text">{I18n.sales}</p>
                                                {item.count}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default BestSeller;