import dayjs from "dayjs";

export let EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export let SARNO_REGEX = /^(?:\+966|0)?(5\d{8}|[1-3]\d{7})$/;
export let DECIMAL_REGEX = /^\d+(\.\d)?$/;

export const capitalizeFirstLetter = (string) => {
    if (string && string.length) {
        return string.slice(0, 1).toUpperCase() + string.slice(1);
    } else {
        return ''
    }
};

export const camelCase = string => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
};

export const formatNum = (number) =>
    Number(number)
        .toFixed(2)
        .replace(/[.,]00$/, "")
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");


export const handleUploadImage = (event) => {
    return new Promise((resolve, reject) => {
        if (event.target.files.length) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = () => resolve(file)
            reader.readAsDataURL(file);
        } else reject();
    });
};

export const getRelativeTime = (date) => {
    const now = dayjs();
    const targetDate = dayjs(date);

    const secondsDiff = now.diff(targetDate, 'second');
    const minutesDiff = now.diff(targetDate, 'minute');
    const hoursDiff = now.diff(targetDate, 'hour');
    const daysDiff = now.diff(targetDate, 'day');
    const weeksDiff = now.diff(targetDate, 'week');
    const monthsDiff = now.diff(targetDate, 'month');

    if (secondsDiff < 60) {
        return `${secondsDiff} ${secondsDiff > 1 ? "Secs" : 'Sec'}`;
    } else if (minutesDiff < 60) {
        return `${minutesDiff} ${minutesDiff > 1 ? "Mins" : 'Min'}`;
    } else if (hoursDiff < 24) {
        return `${hoursDiff} ${hoursDiff > 1 ? "Hours" : 'Hour'}`;
    } else if (daysDiff < 7) {
        return `${daysDiff} ${daysDiff > 1 ? "Days" : 'Day'}`;
    } else if (weeksDiff < 4) {
        return `${weeksDiff} ${weeksDiff > 1 ? "Weeks" : 'Week'}`;
    } else if (monthsDiff < 12) {
        return `${monthsDiff} ${monthsDiff > 1 ? "Months" : 'Month'}`;
    } else {
        return `Year`;
    }
};

export const graphData = {
    series: [
        {
            name: "Sales Analysis",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
            name: "Purchase Analysis",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
    ],
    chart: {
        zoom: { enabled: false },
        height: 273,
        type: "area",
    },
    colors: ["#3c5eab", "#af4f91"],
    dataLabels: { enabled: false },
    stroke: { curve: "straight" },
    title: { align: "left", text: "" },
    xaxis: {
        categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ],
    },
    yaxis: {
        min: 10,
        max: 60,
        tickAmount: 5,
        labels: {
            formatter: (val) => {
                if (val >= 1000) {
                    return (val / 1000) + 'K';
                } else {
                    return val;
                }
            },
        },
    },
    legend: {
        horizontalAlign: "left",
        position: "top",
    },
};