export default {
    lang: 'en',
    languageDir: "ltr",
    hello_world: "Hello world",
    sign_in: "Sign In",
    sign_in_descp: "Access the Essaly vendor portal to learn and control your business.",
    vendor_code: "Vendor Code",
    vendor_code_is_required: "Vendor code is required.",
    email_address: "Email Address",
    email_is_required: "Email is required",
    password: "Password",
    password_is_required: "Password is required.",
    remember_me: "Remember me",
    add_new_product: 'Add New Product',
    import_product: 'Import Product',
    manage_ur_product: 'Manage your products',
    product_list: 'Product List',
    copy_right_descp: "Copyright © 2024 Essaly. All rights reserved",
    back_to_product: 'Back to product',
    generate_code: 'Generate Code',
    create_new_product: 'Create new product',
    new_product: 'New Product',
    product_information: 'Product Information',
    choose: 'Choose',
    store: 'Store',
    warehouse: 'Warehouse',
    sub_category: 'Sub Category',
    sub_sub_category: 'Sub Sub Category',
    selling_type: 'Selling Type',
    barcode_symbology: 'Barcode Symbology',
    tax_type: 'Tax Type',
    select_options: 'Select Option',
    discount_type: 'Discount Type',
    category: 'Category',
    add_new: 'Add New',
    brand: 'Brand',
    unit: 'Unit',
    product_name: 'Product Name',
    slug: 'Slug',
    sku: 'SKU',
    enter_sku: 'Enter SKU',
    item_code: 'Item Code',
    enter_item_code: 'Please Enter Item Code',
    pricing_and_stocks: 'Pricing & Stocks',
    single_product: 'Single Product',
    variable_product: 'Variable Product',
    quantity: 'Quantity',
    price: 'Price',
    discount_value: 'Discount Value',
    quantity_alert: 'Quantity Alert',
    product_type: 'Product Type',
    add_images: 'Add Images',
    images: 'Images',
    custom_fields: 'Custom Fields',
    manufacture_date: 'Manufactured Date',
    expiry_on: 'Expiry On',
    choose_date: 'Choose Date',
    warranties: 'Warranties',
    manufacturer: 'Manufacturer',
    expiry: 'Expiry',
    cancel: 'Cancel',
    save_product: 'Save Product',
    main: "Main",
    dashboard: "Dashboard",
    inventory: "Inventory",
    products: "Products",
    product: "Product",
    create_product: "Create Product",
    category_list: "Category List",
    create_category: "Create Category",
    sub_category_list: "Sub Category List",
    create_sub_category: "Create Sub Category",
    brands: "Brands",
    brands_list: "Brands List",
    create_brand: "Create Brand",
    units: "Units",
    units_list: "Units List",
    create_unit: "Create Unit",
    variant_options: "Variant Options",
    variant_options_list: "Variant Options List",
    create_variant_option: "Create Variant Option",
    stock: "Stock",
    manage_stock: "Manage Stock",
    stock_transfer: "Stock Transfer",
    sales: "Sales",
    invoices: "Invoices",
    sales_return: "Sales Return",
    promo: "Promo",
    coupons: "Coupons",
    purchases: "Purchases",
    purchase_order: "Purchase Order",
    purchase_return: "Purchase Return",
    finance_accounts: "Finance & Accounts",
    expenses: "Expenses",
    expense_category: "Expense Category",
    people: "People",
    customers: "Customers",
    suppliers: "Suppliers",
    companies: "Companies",
    locations: "Locations",
    reports: "Reports",
    sales_report: "Sales Report",
    purchase_report: "Purchase Report",
    inventory_report: "Inventory Report",
    invoice_report: "Invoice Report",
    supplier_report: "Supplier Report",
    customer_report: "Customer Report",
    expense_report: "Expense Report",
    income_report: "Income Report",
    tax_report: "Tax Report",
    profit_loss: "Profit & Loss",
    user_management: "User Management",
    users: "Users",
    roles_permissions: "Roles & Permissions",
    delete_account_request: "Delete Account Request",
    settings: "Settings",
    general_settings: "General Settings",
    logout: "Logout",
    are_you_sure: "Are you sure?",
    you_won_be_able_to_revert_this: "You won't be able to revert this!",
    yes_delete_it: "Yes, delete it!",
    deleted: "Deleted!",
    your_file_has_been_deleted: "Your file has been deleted.",
    ok: "OK",
    local_name: "Local Name",
    action: "Action",
    search: "Search",
    pdf: "Pdf",
    excel: "Excel",
    refresh: "Refresh",
    notifications: "Notifications",
    clear_all: "Clear All",
    enter_product_name: 'Enter product name',
    enter_local_product_name: 'Enter local product name',
    local_product_name: 'Local Product Name',
    sale_tax: 'Sale Tax',
    location: 'Location',
    description: 'Description',
    enter_product_description: 'Enter product description',
    barcode: 'Barcode',
    enter_barocde: 'Enter barcode',
    enter_quantity: 'Enter quantity',
    cost_price: 'Cost Price',
    enter_cost_price: 'Enter cost price',
    selling_price: 'Selling Price',
    enter_selling_price: 'Enter selling price',
    expiry_date: 'Expiry Date',
    variant_attribute: 'Variant Attribute',
    generate_variants: 'Generate Variants',
    remove_variant: 'Remove Varinat',
    new_category: 'New Category',
    create_new_category: 'Create new category',
    category_information: 'Category Information',
    category_name: 'Category Name',
    enter_category_name: 'Enter category name',
    local_category_name: 'Local Category Name',
    enter_local_category_name: 'Enter local category name',
    category_code: 'Category Code',
    enter_category_code: 'Enter category code',
    enter_category_description: 'Enter category description',
    save_category: 'Save Category',
    manage_ur_categories: 'Manage your categories',
    add_new_category: 'Add New Category',
    import_category: 'Import Category',
    you_wont_be_revert_this: "You won't be able to revert this!",
    new_sub_category: 'New Sub Category',
    create_new_sub_category: 'Create new sub category',
    back_to_sub_category: 'Back to Sub Category',
    sub_category_information: 'Sub Category Information',
    sub_category_name: 'Sub Category Name',
    enter_sub_category_name: 'Enter sub category name',
    local_sub_caegory_name: 'Local Sub Category Name',
    enter_local_sub_category_name: 'Enter local sub category name',
    sub_category_code: 'Sub Category Code',
    enter_sub_category_code: 'Enter sub category code',
    enter_sub_category_description: 'Enter sub category description',
    save_sub_category: 'Save Sub Category',
    manage_ur_sub_categories: 'Manage your sub categories',
    sub_category_list: "Sub Category List",
    add_new_sub_category: "Add New Sub Category",
    sub_category: 'Sub Category',
    parent_category: 'Parent Category',
    code: 'Code',
    status: 'Status',
    new_brand: 'New Brand',
    create_new_brand: 'Create new brand',
    back_to_brand: 'Back To Brand',
    brand_information: 'Brand Information',
    brand_name: 'Brand Name',
    enter_brand_name: 'Enter brand name',
    local_brand_name: 'Local Brand Name',
    enter_local_brand_name: 'Enter local brand name',
    enter_brand_description: 'Enter brand description',
    brand_list: 'Brand List',
    manage_ur_brands: 'Manage your brands',
    add_new_brand: 'Add New Brand',
    import_brand: 'Import Brand',
    save_brand: 'Save Brand',
    new_unit: 'New unit',
    create_new_unit: 'Create new unit',
    back_to_unit: 'Back To Unit',
    unit_information: 'Unit Information',
    unit_name: 'Unit Name',
    enter_unit_name: 'Enter unit name',
    local_unit_name: 'Lcocal Unit Name',
    enter_local_unit_name: 'Enter local unit name',
    short_name: 'Short Name',
    enter_short_name: 'Enter short name',
    save_unit: 'Save Unit',
    enter_short_name: 'Enter short name',
    save_unit: 'Save Unit',
    unit_list: 'Unit List',
    manage_ur_units: 'Manage your units',
    import_unit: 'Import Unit',
    add_new_unit: 'Add New Unit',
    new_variant_option: 'New Variant Option',
    create_new_variant_option: 'Create new variant option',
    back_to_option: 'Back To Option',
    variant_option_information: 'Variant Option Information',
    option_name: 'Option Name',
    enter_option_name: 'Enter option name',
    local_option_name: 'Local Option Name',
    enter_local_option_name: 'Enter local option name',
    option_type: 'Option Type',
    values: 'Values',
    enter_value: 'Enter value',
    add: 'Add',
    save_option: 'Save Option',
    add_new_option: 'Add New Option',
    manage_ur_variant_options: 'Manage your variant options',
    variant_option: "Variant Option",
    type: 'Type',
    stock_list: 'Stock List',
    manage_ur_stock: 'Manage your stocks',
    last_updated_at: 'Last Updated At',
    customer_list: 'Customer List',
    manage_ur_expense_category: 'Manage Your Expense Category',
    add_new_customer: 'Add New Customer',
    customer: 'Customer',
    email: 'Email',
    phone: 'Phone',
    country: 'Country',
    create_new_customer: 'Create new customer',
    new_customer: 'New customer',
    back_to_customers: 'Back To Customers',
    customer_information: 'Customer Information',
    save_customer: 'Save Customer',
    local_customer_name: 'Local Customer Name',
    enter_local_customer_name: 'Enter local customer name',
    customer_name: 'Customer Name',
    enter_customer_name: 'Enter customer name',
    enter_customer_email: 'Enter customer email',
    enter_customer_phone: 'Enter customer phone',
    city: 'City',
    enter_customer_city: 'Enter customer city',
    enter_customer_country: 'Enter customer country',
    enter_customer_address: 'Enter customer address',
    address: 'Address',
    enter_customer_code: 'Enter customer code',
    location_list: 'Location List',
    manage_ur_locations: 'Manage your location',
    add_new_location: 'Add New Location',
    new_location: 'New Location',
    create_new_location: 'Create new location',
    back_to_location: 'Back To Location',
    location_information: 'Location Information',
    save_location: 'Save Location',
    location_name: 'Lcoation Name',
    enter_location_name: 'Enter location name',
    enter_location_phone: 'Enter location phone',
    location_code: 'Location Code',
    enter_location_address: 'Enter location address',
    enter_location_code: 'Enter location code',
    latitude: 'Latitude',
    enter_location_latitude: 'Enter location latitude',
    longitude: 'Longitude',
    enter_location_longitude: 'Enter location longitude',
    supplier_list: 'Supplier List',
    manage_ur_supplier: 'Manage your supplier',
    add_new_supplier: 'Add New Supplier',
    new_supplier: 'New Supplier',
    create_new_supplier: 'Create new supplier',
    back_to_supplier: 'Back To Supplier',
    supplier_information: 'Supplier Information',
    enter_supplier_name: 'Enter supplier name',
    supplier_name: 'Supplier Name',
    local_supplier_name: 'Local Supplier Name',
    enter_local_supplier_name: 'Enter lcoal supplier name',
    supplier_phone: 'Supplier Phone',
    enter_supplier_phone: 'Enter supplier phone',
    supplier_email: 'Supplier Email',
    enter_supplier_email: 'Enter supplier email',
    save_supplier: 'Save Supplier',
    company_list: 'Company List',
    manage_ur_company: 'Manage your company',
    add_new_company: 'Add New Company',
    company: 'Company',
    create_new_company: 'Create New Company',
    new_company: 'New Company',
    back_to_company: 'Back To Company',
    company_information: 'Company Information',
    enter_company_name: 'Enter company name',
    company_name: 'Company Name',
    enter_local_company_name: 'Enter local company name',
    local_company_name: 'Local Company Name',
    company_phone: 'Company Phone',
    enter_company_phone: 'Enter company phone',
    enter_company_city: 'Enter company city',
    company_city: 'Company City',
    enter_company_country: 'Enter company country',
    company_country: 'Company Country',
    save_company: 'Save Company',
    enter_company_address: 'Enter Company Address',
    supplier: 'Supplier',
    tax: 'Tax',
    purchase_tax: 'Purchase Tax',
    purchase_tax_list: 'Purchase Tax List',
    manage_ur_purchase_taxes: 'Manage your purchase taxes',
    add_new_purchase_tax: 'Add New Purchase Tax',
    rate: 'Rate',
    new_purchase_tax: 'New Purchase Tax',
    create_new_purchase_tax: 'Create New Purchase Tax',
    back_to_purchase_tax: 'Back To Purchase Tax',
    purchase_tax_information: 'Purchase Tax Information',
    enter_purchase_tax_name: 'Enter purchase tax name',
    purchase_tax_name: 'Purchase Tax Name',
    purchase_tax_rate: 'Purchase Tax Rate',
    enter_purchase_tax_rate_in_percentage: 'Enter purchase tax rate in %',
    save_purchase_tax: 'Save Purchase Tax',
    sale_tax_list: 'Sale Tax List',
    manage_ur_sale_taxes: 'Manage your sale taxes',
    add_new_sale_tax: 'Add New Sale Tax',
    sales_tax: 'Sales Tax',
    create_new_sales_tax: 'Create New Sales Tax',
    new_sales_tax: 'New Sales Tax',
    back_to_sales_tax: 'Back To Sales tax',
    sales_tax_information: 'Sales Tax Information',
    sales_tax_name: 'Sales Tax Name',
    enter_sales_tax_name: 'Enter sales tax name',
    sales_tax_rate: 'Sales Tax Rate',
    enter_sales_tax_rate_in_percentage: 'Enter sales tax rate in %',
    save_sales_tax: 'Save Sales Tax',
    inclusive: 'Inclusive',
    manage_ur_stock: 'Manage your stock',
    add_new_purchase: 'Add New Purchase',
    purchase_order_list: 'Purchase Order List',
    manage_ur_purchase_order: 'Manage your purchase order',
    branch: 'Branch',
    reference: 'Reference',
    total: 'Total',
    date: 'Date',
    new_purchase_order: 'New Purchase Order',
    create_new_purchase_order: 'Create new purchase order',
    back_to_purchase_list: 'Back To Purchase List',
    purchase_order_information: 'Purchase Order Information',
    save_pruchase_order: 'Save Purchase Order',
    reference_no: 'Reference No',
    enter_reference_no: 'Enter reference no',
    search_by_name: 'Search by name',
    subcategory: 'Subcategory',
    enter_purchase_note: 'Enter purchase note',
    note: 'Note',
    enter_purchase_discount_in_percentage: 'Enter purchase discount in percentage',
    discount: 'Discount',
    enter_purchase_subtotal: 'Enter purchase subtotal',
    subtotal: 'SubTotal',
    enter_purchase_total: 'Enter purchase total',
    discounts: 'Discounts',
    manage_ur_categories: 'Manage your discounts',
    discount_list: 'Discount List',
    add_new_discount: 'Add New Discount',
    purchase_date: 'Purchase Date',
    expected_date: 'Expected Date',
    create_new_stock: 'Create new stock',
    new_stock: 'New Stock',
    back_to_stock_list: 'Back To Stock List',
    stock_information: 'Stock Information',
    save_stock: 'Save Stock',
    reject: 'Reject',
    accept: 'Accept',
    edit: 'Edit',
    return: 'Return',
    deliver: 'Deliver',
    choose_company_first: 'Choose company first',
    fetching_companies: 'Fetching companies...',
    fetching_suppliers: 'Fetching suppliers...',
    fetching_branches: 'Fetching branches...',
    fetching_products: 'Fetching products...',
    max_discount: 'Max Discount',
    transacton_limit: 'Transaction Limit',
    value: 'Value',
    create_new_discount: 'Create new discount',
    new_discount: 'New Discount',
    back_to_discount: 'Back To Discount',
    discount_information: 'Discount Information',
    save_discount: 'Save Discount',
    discount_name: 'Discount Name',
    enter_discount_name: 'Enter discount name',
    local_discount_name: 'Local Discount Name',
    enter_local_discount_name: 'Enter local discount name',
    enter_discount_value_in_percentage: 'Enter discount value in %',
    discount_value: 'Discount Value',
    max_cap: 'Max Cap',
    enter_max_discount_cap: 'Enter Max discount cap',
    enter_discount_transaction_limit: 'Enter discount transaction limit',
    limit_of_transaction: 'Limit Of Transaction',
    manage_ur_stock_transfer: 'Manage your stock transfer',
    stock_transfer: 'Stock Transfer',
    transfer_your_stock: 'Transfer your stock',
    new_stock_transfer: 'New Stock Transfer',
    back_to_tansfer_list: 'Back To Transfer List',
    transfer_information: 'Transfer Information',
    save_transfer: 'Save Transfer',
    from_branch: 'From Branch',
    enter_product_quantity: 'Enter product quantity',
    toBranch: 'To Branch',
    order_number: 'Order Number',
    grand_total: 'Grand Total',
    paid: 'Paid',
    due: 'Due',
    payment_status: 'Payment Status',
    biller: 'Biller',
    choose_from_branch_first: 'Choose from branch first',
    to_branch: 'To Branch',
    employee: 'Employee',
    role: 'Role',
    manage_ur_employees: 'Manage your employees',
    employee_list: 'Employee List',
    add_new_employee: 'Add New Employee',
    cretae_new_role: 'Create new role',
    new_role: 'New Role',
    back_to_roles: 'Back To Roles',
    role_information: 'Role Information',
    save_role: 'Save Role',
    enter_role_name: 'Enter role name',
    role_name: 'Role Name',
    local_role_name: 'Local Role Name',
    enter_local_role_name: 'Enter local role name',
    create_new_user: 'Create new user',
    new_user: 'New User',
    back_to_users: 'Back to user list',
    user_information: 'User Information',
    enter_user_name: 'Enter user name',
    user_name: 'User Name',
    enter_user_email: 'Enter user email',
    enter_user_password: 'Enter user password',
    save_user: 'Save User',
    fetching_roles: 'Fetching roles',
    coming_soon: 'Coming soon',
    fetching_units: 'Fetching units...',
    fetching_categories: 'Fetching categories...',
    fetching_sub_categories: 'Fetching sub categories...',
    fetching_brands: 'Fetching brands...',
    fetching_sale_taxes: 'Fetching sale taxes...',
    fetching_locations: 'Fetching locations...',
    fetching_sku: 'Fetching sku...',
    product_details: 'Product Details',
    full_details_of_a_product: 'Full details of a product',
    failed_to_load_product: 'Failed to load product',
    failed_to_load_product: 'Failed to load product',
    failed_to_load_brand: "Failed to load Brand",
    failed_to_load_category: 'Failed to load Category',
    failed_to_load_company: "Failed to load Company",
    failed_to_load_customer: "Failed to load Customer",
    failed_to_load_discount: "Failed to load Discount",
    failed_to_load_location: "Failed to load Location",
    failed_to_load_editinventory: "Failed to load EditInventory",
    failed_to_load_purchase_tax: "Failed to load Purchase Tax",
    failed_to_load_role: "Failed to load Role",
    failed_to_load_roles_permission: "Failed to load Roles and permissions",
    failed_to_load_sales_tax: "Failed to load Sales Tax",
    failed_to_load_sub_category: "Failed to load Sub Category",
    failed_to_load_supplier: "Failed to load Supplier",
    failed_to_load_user: "Failed to load User",
    failed_to_load_variant: "Failed to load Variant",
    manage_ur_users: 'Manage your users',
    add_new_user: 'Add New User',
    user_list: "User List",
    roles_permission_list: "Roles & permissions List",
    manage_ur_roles_permission: 'Manage your roles & permissions',
    add_new_roles: 'Add New Roles',
    back_to_category: 'Back to category',
    you_will_return_to_login: 'You will be returned to the login screen',
    logout: 'Logout',
    weekly_earning: 'Weekly Earning',
    no_of_total_sales: 'No of Total Sales',
    no_of_total_customers: 'No of Total Customers',
    best_seller: 'Best Seller',
    recent_transactions: 'Recent Transactions',
    manage_ur_sales: 'Manage your sales',
    sale_list: 'Sale List',
    sales_analytics: 'Sales Analytics',
    here_is_what_is_happening_with_ur_store: "here's what's happening with your store today.",
    order_details: 'Order Details',
    order_number: 'Order number',
    amount: 'Amount',
    delete: "Delete",
    customer_code: 'Customer code',
    confirm_password: "Confirm Password",
    confirm_user_password: 'Confirm user password',
    qty: 'Qty',
    purchase_price: 'Purchase Price',
    total_cost: 'Total Cost',
    discount_in_percent: 'Discount (%)',
    edit_product: 'Edit Product',
    edit_category: 'Edit Category',
    edit_sub_category: 'Edit Sub Category',
    edit_brand: 'Edit Brand',
    edit_unit: 'Edit Unit',
    edit_variant_option: 'Edit Variant Option',
    edit_purchase_tax: 'Edit Purchase Tax',
    edit_sale_tax: 'Edit Sale Tax',
    edit_stock: 'Edit Stock',
    edit_discount: 'Edit Discount',
    edit_purchase_order: 'Edit Purchase Order',
    edit_customer: 'Edit customer',
    edit_supplier: 'Edit Supplier',
    edit_location: 'Edit Location',
    edit_company: 'Edit Company',
    edit_user: 'Edit User',
    edit_role: 'Edit Role',
    fromBranch: 'From Branch',
    roles_permissions_details: 'Roles & permissions Detail',
    full_detail_roles_permissions: 'Full detatils of Roles & permissions',
    sales_details:"Sale Detail",
    full_detail_sales: "Full details of sale",
    branch_info: "Branch Info",
    cashier_info: "Cashier Info",
    invoice_info : "Invoice Info",
    order_summary: "Order Summary",
    unit_cost: "Unit Cost",
    tax_amount: "Tax Amount",
    order_tax: "Order Tax",    
};
