import { useLanguage, useToast } from "../../../../context";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Image } from "../../../Libraries";
import { Link } from "react-router-dom";

const tooltip = {
    'Collapse': 'refresh-tooltip',
    'Refresh': 'refresh-tooltip',
    'Excel': 'excel-tooltip',
    'Pdf': 'pdf-tooltip',
};

const OverlyBox = ({ item }) => {

    const { invokeToast } = useToast();
    const { I18n } = useLanguage();


    const { disabled, onClick, tipType, Icon, svg } = item || {};

    const AppToolTip = (props) => {
        return (
            <Tooltip id={tooltip[tipType]} {...props}>
                {tipType}
            </Tooltip>
        );
    };

    const onOverlay = () => {
        if (tipType == I18n.pdf || tipType == I18n.excel) invokeToast({ message: I18n.coming_soon, type: 'info' });
        else if (onClick && !disabled) onClick();
    };

    return (
        <li>
            <OverlayTrigger placement="top" overlay={AppToolTip} >
                <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={onOverlay}>
                    {Icon || <Image src={svg} alt="img" />}
                </Link>
            </OverlayTrigger>
        </li>
    );
};

export default OverlyBox;