import { formatNum, getRelativeTime } from "../../../../constants";
import { useLanguage } from "../../../../context";
import { Icon, Image } from "../../../Libraries";
import { Link } from "react-router-dom";

const RecentTransactions = ({ data }) => {

    const { recentSales } = data || {};

    const { I18n } = useLanguage();

    return (
        <div className="col-sm-12 col-md-12 col-xl-8 d-flex">
            <div className="card flex-fill default-cover w-100 mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h4 className="card-title mb-0">{I18n.recent_transactions}</h4>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-borderless recent-transactions">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{I18n.order_details}</th>
                                    <th>{I18n.order_number}</th>
                                    <th>{I18n.quantity}</th>
                                    <th>{I18n.amount}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {recentSales.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <div className="product-info">
                                                    <Link to={"#"} className="product-img">
                                                        <Image
                                                            src={item?.product?.image || 'assets/img/image-placeholder.png'}
                                                            alt="product"
                                                        />
                                                    </Link>
                                                    <div className="info">
                                                        <Link to={"#"}>{item.productName}</Link>
                                                        <span className="dull-text d-flex align-items-center">
                                                            {Icon['clock']}
                                                            {getRelativeTime(item.updatedAt)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <Link to={"/sales-list"}>#{item?.order?.orderNumber}</Link>
                                            </td>
                                            <td>
                                                <span>
                                                    {item?.quantity || 0}
                                                </span>
                                            </td>
                                            <td>SAR {(formatNum(item?.totalPrice || 0))}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecentTransactions;