import { PrimaryButton, TextField } from "../../../../Libraries";
import { postCreatePurchaseTax, putUpdatePurchaseTax } from "../../../../../api";
import { useLanguage, useToast } from "../../../../../context";
import { Collapse, SubHeader } from "../../../../common";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const CreatePurchaseTax = ({ from, item }) => {

    const { invokeToast } = useToast();
    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [purchaseTax, setPurchaseTax] = useState({
        rate: item?.rate || '',
        name: item?.name || '',
    });

    const validatePurchaseTax = () => {
        let obj = {};

        if (!purchaseTax['name']) obj = { ...obj, name: 'Name is invalid' };
        if (!Number(purchaseTax['rate'])) obj = { ...obj, rate: 'Rate is required' };

        return obj;
    };

    const onSave = async (event) => {
        window.scrollTo({ top: 0 });
        event.stopPropagation();
        event.preventDefault();

        try {

            if (Object.values(validatePurchaseTax()).length) {
                setErrors(validatePurchaseTax());
                return;
            };

            setLoading(true);

            const { name, rate } = purchaseTax;

            let params = { name, rate };

            from == 'edit' ? await putUpdatePurchaseTax(item._id, params) : await postCreatePurchaseTax(params);

            invokeToast({ message: `Purchase Tax ${from == 'edit' ? 'updated' : 'created'} successfully!`, type: 'success' });

            navigate('/purchase-tax');
        } catch (err) {
            invokeToast({ message: err, type: 'danger' });
        } finally {
            setLoading(false);
        }
    };

    const onChange = (key, value) => {
        let error = { ...errors };
        setPurchaseTax(prev => {
            let all = { ...prev };
            all[key] = value;
            if (error[key]) error[key] = '';
            return all;
        });
        if (errors[key]) setErrors(error);
    };

    const handleBack = () => navigate(-1);
    const onCancel = () => navigate(-1);

    return (
        <div className="page-wrapper">
            <div className="content">
                <SubHeader
                    data={{
                        title: from == 'edit' ? 'edit_purchase_tax' : 'new_purchase_tax',
                        subTitle: from == 'edit' ? '' : 'create_new_purchase_tax',
                        flip: true,
                        actions: [{
                            className: 'btn btn-added color',
                            title: 'back_to_purchase_tax',
                            action: handleBack,
                            icon: 'arrow-left',
                        }]
                    }}
                />

                <form type="submit" onSubmit={onSave} >
                    <div className="card">
                        <div className="card-body add-product pb-0">
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <Collapse
                                        data={{
                                            title: I18n.purchase_tax_information,
                                            target: 'collapseOne',
                                            id: 'headingOne',
                                            icon: 'info',
                                        }} >
                                    </Collapse>

                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div className="row">
                                                <TextField
                                                    onChange={(value) => onChange('name', value)}
                                                    containerClass='col-lg-4 col-sm-6 col-12'
                                                    placeholder="enter_purchase_tax_name"
                                                    innerContClass='mb-3 add-product'
                                                    label={'purchase_tax_name'}
                                                    value={purchaseTax.name}
                                                    error={errors?.name}
                                                    type={"text"}
                                                />

                                                <TextField
                                                    placeholder="enter_purchase_tax_rate_in_percentage"
                                                    onChange={(value) => onChange('rate', value)}
                                                    containerClass='col-lg-4 col-sm-6 col-12'
                                                    innerContClass='mb-3 add-product'
                                                    label={'purchase_tax_rate'}
                                                    value={purchaseTax.rate}
                                                    error={errors?.rate}
                                                    type={"text"}
                                                    onlyNumber
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="btn-addproduct mb-4">
                            <PrimaryButton
                                className='btn btn-cancel me-2'
                                onClick={onCancel}
                                title={'cancel'}
                                type="button"
                            />
                            <PrimaryButton
                                title={'save_purchase_tax'}
                                className='btn btn-submit'
                                loading={loading}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreatePurchaseTax;