import { AuthProvider, LanguageProvider } from "./context";
import { ToastProvider } from "./context/ToastContext";
import AppRoutes from "./routes";

const App = () => {
  return (
    <LanguageProvider>
      <AuthProvider>
        <ToastProvider>
          <AppRoutes />
        </ToastProvider>
      </AuthProvider>
    </LanguageProvider>
  )
}

export default App;