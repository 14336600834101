import { useLanguage } from "../../../context";
import { Icon, Image } from "../../Libraries";
import { useAlert } from "../../../hooks";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { list } from "./list";
import { Table } from "antd";
import dayjs from "dayjs";

const Datatable = ({ onPageChange, noOfColumns, dataSource, pagination, onDelete, loading, props, type }) => {

    const [handleAlert] = useAlert(onDelete);
    const { I18n } = useLanguage();

    const getColumns = () => {
        return [...Array(noOfColumns)].map((_, index) => {

            let dataIndex = list[type][index].dataIndex;
            let title = I18n[list[type][index].title];
            let sort = list[type][index].sort;

            const obj = {
                sorter: (a, b) => a[sort].length - b[sort].length,
                dataIndex,
                title
            };

            if (dataIndex == 'name') {
                return {
                    ...obj,
                    render: (_, record) => (
                        <span className="productimgname">
                            {(type == 'subcategory' || type == 'unit' || type == 'variant-option' || type == 'customer' || type == 'location' || type == 'purchase-tax' || type == 'sales-tax' || type == 'purchase-order' || type == 'discount' || type == 'sales-list' || type == 'stock-transfer' || type == 'users' || type == 'roles-permissions') ? null :
                                <Link to="#" className="product-img stock-img">
                                    <Image alt="img" src={record?.image || 'assets/img/image-placeholder.png'} />
                                </Link>
                            }

                            <Link to="#">{record.name || record.supplier || record.orderNumber || record.fromBranch}</Link>
                        </span>
                    ),
                };
            } else if (dataIndex == 'updatedAt') {
                return {
                    ...obj,
                    render: (_, record) => (
                        <span>
                            {dayjs(record.updatedAt).format('DD MMM YYYY')}
                        </span>
                    ),
                };
            } else if (dataIndex == 'isActive') {
                return {
                    ...obj,
                    render: (text) => (
                        <span className={`badge badge-line${text === true ? 'success' : 'danger'}`}>
                            <Link to="#">{text === true ? 'Active' : 'Inactive'}</Link>
                        </span>
                    ),
                };
            } else if (dataIndex == 'action') {
                return {
                    ...obj,
                    render: (_, record) => (
                        <div className="action-table-data">
                            <div className="edit-delete-action">
                                <div className="input-block add-lists"></div>

                                {(type == 'product' || type == 'sales-list' || type == 'roles-permissions') ?
                                    <Link className="me-2 p-2" to={`/${type}/detail/${record._id}`} state={record.item}>
                                        {Icon['eye']}
                                    </Link>
                                    : null
                                }

                                {((type == 'purchase-order' && (record.status == 'rejected' || record.status == 'cancelled')) || type == 'sales-list') ? null :
                                    <Link className="me-2 p-2" to={`/${type}/edit/${record._id}`} state={record} >
                                        {Icon['edit']}
                                    </Link>
                                }

                                {((type == 'purchase-order' && record.status != 'draft') || type == 'stock' || type == 'roles-permissions') ? null :
                                    <Link
                                        onClick={() => handleAlert(record._id)}
                                        className="confirm-text p-2"
                                        to="#"
                                    >
                                        {Icon['delete']}
                                    </Link>
                                }
                            </div>
                        </div>
                    ),
                };
            } else {
                return obj;
            }
        });
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <Table
            className="table datanew dataTable no-footer"
            rowKey={(record) => record.id}
            rowSelection={rowSelection}
            dataSource={dataSource}
            columns={getColumns()}
            loading={loading}
            pagination={{
                pageSize: pagination.resultsPerPage,
                total: pagination.totalCount,
                current: pagination.page,
                onChange: onPageChange
            }}
            key={props}
        />
    );
};

export default Datatable;