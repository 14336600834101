import React from 'react';

const AppSpinner = ({ auth }) => {
  return auth ?
    <span
      className="spinner-border spinner-border-sm align-middle me-1"
      aria-hidden="true"
      role="status"
    />
    :
    <div className="spinner-border text-light me-1" role="status">
      <span className="sr-only">Loading...</span>
    </div>
};

export default AppSpinner;
