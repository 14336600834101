import { Datatable, SearchHeader, SubHeader } from "../../../../common";
import { deleteUnit, getInventories } from "../../../../../api";
import { useLanguage } from "../../../../../context";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../../Libraries";
import { useEffect, useState } from "react";

const InventoryList = () => {
    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState({
        resultsPerPage: 10,
        totalCount: 0,
        page: 1,
    });

    useEffect(() => {
        handleInventories();
    }, []);

    const onDelete = async (id) => {
        try {
            const data = await deleteUnit(id, { archive: true });
            if (data.success) handleInventories();
        } catch (err) { }
    };

    const handleInventories = async (reqPage = 1, filters = undefined) => {
        try {
            setLoading(true);

            let params = {
                resultsPerPage: pagination.resultsPerPage,
                page: reqPage
            };

            if (filters) params = { ...params, ...filters };

            const { data } = await getInventories(params);
            const { resultsPerPage, totalCount, inventory, page } = data || {};

            setPagination({ resultsPerPage, totalCount, page });
            setList(inventory.map(inv => {
                return {
                    location: inv.branch?.name || '',
                    image: inv.product?.image || '',
                    name: inv.product?.name || '',
                    quantity: inv.quantity || '',
                    updatedAt: inv.updatedAt,
                    createdAt: inv.createdAt,
                    _id: inv._id,
                }
            }));

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const onPageChange = (page) => {
        if (page == pagination.current || loading) return;
        handleInventories(page);
    };

    const AddStock = () => navigate('/stock/create');

    return (
        <div className="page-wrapper">
            <div className="content">
                <SubHeader
                    data={{
                        subTitle: 'manage_ur_stock',
                        title: 'stock_list',
                        overlays: [
                            {
                                svg: 'assets/img/icons/pdf.svg',
                                tipType: I18n.pdf
                            },
                            {
                                svg: 'assets/img/icons/excel.svg',
                                tipType: I18n.excel
                            },
                            {
                                onClick: handleInventories,
                                Icon: Icon['referesh'],
                                tipType: I18n.refresh,
                                disabled: loading
                            }
                        ],
                        actions: [
                            {
                                className: 'btn btn-added',
                                title: 'add_new',
                                action: AddStock,
                                icon: 'plus',

                            }
                        ],
                    }}
                />

                <div className="card table-list-card">
                    <div className="card-body">
                        <SearchHeader
                            filter={{ isFilter: true, from: 'manage_stock' }}
                            applyFilter={handleInventories}
                        />

                        <div className="table-responsive">
                            <Datatable
                                onPageChange={onPageChange}
                                pagination={pagination}
                                onDelete={onDelete}
                                loading={loading}
                                dataSource={list}
                                noOfColumns={5}
                                type="stock"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InventoryList;