import { Datatable, SearchHeader, SubHeader } from "../../../../common";
import { deleteDiscount, getDiscountList } from "../../../../../api";
import { useLanguage } from "../../../../../context";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../../Libraries";
import { useEffect, useState } from "react";

const DiscountList = () => {

    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState({
        resultsPerPage: 10,
        totalCount: 0,
        page: 1,
    });

    useEffect(() => {
        handleDiscounts();
    }, []);

    const onDelete = async (id) => {
        try {
            const data = await deleteDiscount(id, { archive: true });
            if (data.success) handleDiscounts();
        } catch (err) { }
    };

    const handleDiscounts = async (reqPage = 1, filters = undefined) => {
        try {
            setLoading(true);

            let params = {
                resultsPerPage: pagination.resultsPerPage,
                page: reqPage
            };

            if (filters) params = { ...params, ...filters };

            const { data } = await getDiscountList(params);
            const { resultsPerPage, totalCount, discounts, page } = data || {};

            setPagination({ resultsPerPage, totalCount, page });
            setList(discounts.map(discount => {
                return {
                    capPerTransaction: discount?.capPerTransaction || 0,
                    nameLocal: discount?.nameLocal || '-',
                    createdAt: discount.createdAt,
                    maxCap: discount.maxCap || 0,
                    value: discount.value || 0,
                    name: discount.name || '',
                    type: discount.type || '',
                    _id: discount._id,
                }
            }));

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const onPageChange = (page) => {
        if (page == pagination.current || loading) return;
        handleDiscounts(page);
    };

    const AddDiscount = () => navigate('/discount/create');

    return (
        <div className="page-wrapper">
            <div className="content">
                <SubHeader
                    data={{
                        subTitle: 'manage_ur_categories',
                        title: 'discount_list',
                        overlays: [
                            {
                                svg: 'assets/img/icons/pdf.svg',
                                tipType: I18n.pdf
                            },
                            {
                                svg: 'assets/img/icons/excel.svg',
                                tipType: I18n.excel
                            },
                            {
                                onClick: handleDiscounts,
                                Icon: Icon['referesh'],
                                tipType: I18n.refresh,
                                disabled: loading
                            }
                        ],
                        actions: [
                            {
                                className: 'btn btn-added',
                                title: 'add_new_discount',
                                action: AddDiscount,
                                icon: 'plus',

                            }
                        ],
                    }}
                />

                <div className="card table-list-card">
                    <div className="card-body">
                        <SearchHeader applyFilter={handleDiscounts} />

                        <div className="table-responsive">
                            <Datatable
                                onPageChange={onPageChange}
                                pagination={pagination}
                                onDelete={onDelete}
                                loading={loading}
                                dataSource={list}
                                noOfColumns={6}
                                type="discount"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DiscountList;