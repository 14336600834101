import { AppCardBoxLoader, AppNotFoundBox } from "../../../../Libraries";
import { getBranchById } from "../../../../../api";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CreateLocation } from "..";

const EditLocation = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState({});

    useEffect(() => {
        if (id) getLocation();
        else setLoading(false);
    }, [id]);

    const getLocation = async () => {
        try {
            const { data } = await getBranchById(id);
            setItem(data.branch);
        } catch (err) { }
        finally {
            setLoading(false);
        }
    };


    return loading ?
        <AppCardBoxLoader />
        : item?._id ?
            <CreateLocation from="edit" item={item} />
            :
            <AppNotFoundBox title='failed_to_load_location' />
};

export default EditLocation;