import { useLanguage } from "../../../context";
import { Icon } from "../../Libraries";

const AddImages = ({ addImage }) => {
    const { I18n } = useLanguage();

    return (
        <div className="input-blocks">
            <div className="image-upload">
                <input type="file" onChange={addImage} />
                <div className="image-uploads">
                    {Icon['plus-circle']}
                    <h4>{I18n.add_images}</h4>
                </div>
            </div>
        </div>
    );
};

export default AddImages;