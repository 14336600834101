import { getRoles, postCreateEmployee, putUpdateEmployee } from "../../../../../api";
import { PrimaryButton, Select, TextField } from "../../../../Libraries";
import { useLanguage, useToast } from "../../../../../context";
import { Collapse, SubHeader } from "../../../../common";
import { EMAIL_REGEX } from "../../../../../constants";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const CreateUser = ({ from, item }) => {

    const { invokeToast } = useToast();
    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const [load, setLoad] = useState({ rolesLoad: true, loading: false });
    const [roles, setRoles] = useState([]);
    const [errors, setErrors] = useState({});
    const [user, setUser] = useState({
        role: item?.role ? { name: item.role.name, value: item.role } : {},
        confirmPassword: '',
        email: item?.email || '',
        name: item?.name || '',
        password: '',
    });

    useEffect(() => {
        handleGetRoles();
    }, []);

    const handleGetRoles = async () => {
        try {
            const { data } = await getRoles();

            setRoles(data?.roles || []);
        } catch (err) {
            console.log('err', err)
        } finally {
            setLoad({ ...load, rolesLoad: false });
        }
    };

    const validateUser = () => {
        let obj = {};
        if (from != 'edit') {
            if (user['password'] && user['confirmPassword'] && user['password'] != user['confirmPassword']) obj = { ...obj, confirmPassword: 'Password not match' };
            if (!user['confirmPassword']) obj = { ...obj, confirmPassword: 'Password confirmaion is required' };
            if (!user['password']) obj = { ...obj, password: 'Password is required' };
        }

        if (user['email'] && !EMAIL_REGEX.test(user['email'])) obj = { ...obj, email: 'Email is invalid' };
        if (!user['role']?.name) obj = { ...obj, role: 'Role is required' };
        if (!user['name']) obj = { ...obj, name: 'Role name is required' };
        if (!user['email']) obj = { ...obj, email: 'Email is required' };

        return obj;
    };

    const onSave = async (event) => {
        window.scrollTo({ top: 0 });
        event.stopPropagation();
        event.preventDefault();

        try {

            if (Object.values(validateUser()).length) {
                setErrors(validateUser());
                return;
            };

            setLoad({ ...load, loading: true });

            const { password, email, name, role } = user;

            let params = {
                role_id: role.value._id,
                email,
                name,
            };

            if (from != 'edit') params = { ...params, password }

            from == 'edit' ? await putUpdateEmployee(item._id, params) : await postCreateEmployee(params);

            invokeToast({ message: `User ${from == 'edit' ? 'updated' : 'created'} successfully!`, type: 'success' });

            navigate('/users');
        } catch (err) {
            invokeToast({ message: err, type: 'danger' });
        } finally {
            setLoad({ ...load, loading: false });
        }
    };

    const onChange = (key, value) => {
        let error = { ...errors };
        setUser(prev => {
            let all = { ...prev };
            all[key] = value;
            if (error[key]) error[key] = '';
            return all;
        });
        if (errors[key]) setErrors(error);
    };

    const handleBack = () => navigate(-1);
    const onCancel = () => navigate(-1);

    return (
        <div className="page-wrapper">
            <div className="content">
                <SubHeader
                    data={{
                        subTitle: from == 'edit' ? '' : 'create_new_user',
                        title: from == 'edit' ? 'edit_user' : 'new_user',
                        flip: true,
                        actions: [{
                            className: 'btn btn-added color',
                            title: 'back_to_users',
                            action: handleBack,
                            icon: 'arrow-left',
                        }]
                    }}
                />

                <form type="submit" onSubmit={onSave} >
                    <div className="card">
                        <div className="card-body add-product pb-0">
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <Collapse
                                        data={{
                                            title: I18n.user_information,
                                            target: 'collapseOne',
                                            id: 'headingOne',
                                            icon: 'info',
                                        }} >
                                    </Collapse>

                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <Select
                                                        options={roles.map(item => ({ value: item, label: item.name }))}
                                                        placeholder={load.rolesLoad ? I18n.fetching_roles : I18n.choose}
                                                        onChange={(value) => onChange('role', value)}
                                                        className="mb-3 add-product"
                                                        isDisabled={load.rolesLoad}
                                                        error={errors?.role}
                                                        value={user.role}
                                                        label={I18n.role}
                                                        actions={{
                                                            icon: 'plus-circle',
                                                            title: 'add_new',
                                                        }}
                                                    />
                                                </div>

                                                <TextField
                                                    onChange={(value) => onChange('name', value)}
                                                    containerClass='col-lg-4 col-sm-6 col-12'
                                                    innerContClass='mb-3 add-product'
                                                    placeholder="enter_user_name"
                                                    error={errors?.name}
                                                    label={'user_name'}
                                                    value={user.name}
                                                    type={"text"}
                                                />
                                                <TextField
                                                    onChange={(value) => onChange('email', value)}
                                                    containerClass='col-lg-4 col-sm-6 col-12'
                                                    innerContClass='mb-3 add-product'
                                                    placeholder="enter_user_email"
                                                    error={errors?.email}
                                                    value={user.email}
                                                    label={'email'}
                                                    type={"text"}
                                                />
                                            </div>

                                            {from == 'edit' ? null :
                                                <div className="row">
                                                    <TextField
                                                        onChange={(value) => onChange('password', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        innerContClass='mb-3 add-product'
                                                        placeholder={'enter_user_password'}
                                                        error={errors?.password}
                                                        value={user.password}
                                                        label={'password'}
                                                        type={"text"}
                                                    />
                                                    <TextField
                                                        onChange={(value) => onChange('confirmPassword', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        placeholder={'confirm_user_password'}
                                                        innerContClass='mb-3 add-product'
                                                        error={errors?.confirmPassword}
                                                        value={user.confirmPassword}
                                                        label={'confirm_password'}
                                                        type={"text"}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="btn-addproduct mb-4">
                            <PrimaryButton
                                className='btn btn-cancel me-2'
                                onClick={onCancel}
                                title={'cancel'}
                                type="button"
                            />
                            <PrimaryButton
                                className='btn btn-submit'
                                loading={load.loading}
                                title={'save_user'}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateUser;