import { getPermissions, postCreateRole, putUpdateRole } from "../../../../../api";
import { PrimaryButton, TextField } from "../../../../Libraries";
import { useLanguage, useToast } from "../../../../../context";
import { Collapse, SubHeader } from "../../../../common";
import { camelCase } from "../../../../../constants";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const CreateRole = ({ from, item }) => {

    const { invokeToast } = useToast();
    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const [load, setLoad] = useState({ dataLoad: true, laoding: false });
    const [permisisonsList, setPermisisonsList] = useState([]);
    const [permissions, setPermissions] = useState({});
    const [errors, setErrors] = useState({});
    const [role, setRole] = useState({
        nameLocal: item?.nameLocal || '',
        name: item?.name || '',
    });

    useEffect(() => {
        handleGetModules()
    }, []);

    const handleGetModules = async () => {
        try {
            const { data } = await getPermissions();

            setPermisisonsList(data.permissions || []);

            if (from == 'edit' && item?.permissions?.length) {
                setPermissions(item?.permissions.reduce((obj, pm) => {
                    let all = pm.canCreate && pm.canDelete && pm.canUpdate && pm.canRead;
                    const { _id, ...rest } = pm;
                    obj[pm.name] = { ...rest, all };
                    return obj;
                }, {}));
            }

        } catch (err) {
            console.log('err', err)
        } finally {
            setLoad({ ...load, dataLoad: false });
        }
    };

    const onChange = (key, value) => {
        let error = { ...errors };
        setRole(prev => {
            let all = { ...prev };
            all[key] = value;

            if (error[key]) error[key] = '';
            return all;
        });
        if (errors[key]) setErrors(error);
    };

    const validateRole = () => {
        let obj = {};

        if (!role['nameLocal']) obj = { ...obj, nameLocal: 'Local name is required' };
        if (!role['name']) obj = { ...obj, name: 'Role name is required' };

        return obj;
    };

    const onSave = async (event) => {
        window.scrollTo({ top: 0 });
        event.stopPropagation();
        event.preventDefault();

        try {

            if (Object.values(validateRole()).length) {
                setErrors(validateRole());
                return;
            }

            let list = [];
            if (Object.keys(permissions)?.length) {
                list = Object.keys(permissions).map(module => {
                    const { all, ...rest } = permissions[module];
                    return {
                        name: module,
                        ...rest
                    };
                });
            } else {
                invokeToast({ message: "permissions must contain at least 1 items", type: "danger" });
                return;
            }

            setLoad({ ...load, loading: true });

            const { nameLocal, name } = role;


            let params = {
                permissions: list,
                nameLocal,
                name,
            };

            from == 'edit' ? await putUpdateRole(item._id, params) : await postCreateRole(params);

            invokeToast({ message: `Role ${from == 'edit' ? 'updated' : 'created'} successfully!`, type: 'success' });

            navigate('/roles-permissions');
        } catch (err) {
            invokeToast({ message: err, type: 'danger' });
        } finally {
            setLoad({ ...load, loading: false });
        }
    };

    const onPermission = (permisson, checked) => {
        setPermissions(prev => {
            let all = { ...prev };

            if (all[permisson]) {
                if (checked == 'all' && all[permisson][checked]) delete all[permisson]
                else {
                    all[permisson] = { ...all[permisson], [checked]: !all[permisson][checked] }
                    const { canCreate, canDelete, canUpdate, canRead } = all[permisson];

                    if (!canCreate && !canDelete && !canUpdate && !canRead) delete all[permisson]
                    else all[permisson].all = canCreate && canDelete && canUpdate && canRead;
                }
            } else {
                all[permisson] = {
                    canDelete: Boolean(checked == 'canDelete' || checked == 'all'),
                    canCreate: Boolean(checked == 'canCreate' || checked == 'all'),
                    canUpdate: Boolean(checked == 'canUpdate' || checked == 'all'),
                    canRead: Boolean(checked == 'canRead' || checked == 'all'),
                    all: Boolean(checked == 'all')
                }
            }

            return all;
        })
    }

    const handleBack = () => navigate(-1);
    const onCancel = () => navigate(-1);

    return (
        <div className="page-wrapper">
            <div className="content">

                <SubHeader
                    data={{
                        subTitle: from == 'edit' ? '' : 'cretae_new_role',
                        title: from == 'edit' ? 'edit_role' : 'new_role',
                        flip: true,
                        actions: [{
                            className: 'btn btn-added color',
                            title: 'back_to_roles',
                            action: handleBack,
                            icon: 'arrow-left',
                        }]
                    }}
                />

                <form type="submit" onSubmit={onSave} >
                    <div className="card">
                        <div className="card-body add-product pb-0">
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <Collapse
                                        data={{
                                            title: I18n.role_information,
                                            target: 'collapseOne',
                                            id: 'headingOne',
                                            icon: 'info',
                                        }} >
                                        <div
                                            className="accordion-collapse collapse show"
                                            data-bs-parent="#accordionExample"
                                            aria-labelledby="headingOne"
                                            id="collapseOne"
                                        >
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <TextField
                                                        onChange={(value) => onChange('name', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        innerContClass='mb-3 add-product'
                                                        placeholder="enter_role_name"
                                                        error={errors?.name}
                                                        label={'role_name'}
                                                        value={role.name}
                                                        type={"text"}
                                                    />

                                                    <TextField
                                                        onChange={(value) => onChange('nameLocal', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        placeholder="enter_local_role_name"
                                                        innerContClass='mb-3 add-product'
                                                        label={'local_role_name'}
                                                        error={errors?.nameLocal}
                                                        value={role.nameLocal}
                                                        type={"text"}
                                                    />
                                                </div>
                                                {/* </div> */}
                                                {/* </div> */}

                                                <div className="table-responsive">
                                                    <table className="table  datanew">
                                                        <thead>
                                                            <tr>
                                                                <th>Modules</th>
                                                                <th>Create</th>
                                                                <th>Edit</th>
                                                                <th>Delete</th>
                                                                <th>View</th>
                                                                <th className="no-sort">Allow all</th>
                                                            </tr>
                                                        </thead>



                                                        <tbody>
                                                            {permisisonsList.map((item, index) => {
                                                                let selcted = permissions[item];

                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{camelCase(item)}</td>
                                                                        <td>
                                                                            <label className="checkboxs">
                                                                                <input type="checkbox" checked={selcted && selcted['canCreate']} onChange={() => onPermission(item, 'canCreate')} />
                                                                                <span className="checkmarks" />
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            <label className="checkboxs">
                                                                                <input type="checkbox" checked={selcted && selcted['canUpdate']} onChange={() => onPermission(item, 'canUpdate')} />
                                                                                <span className="checkmarks" />
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            <label className="checkboxs">
                                                                                <input type="checkbox" checked={selcted && selcted['canDelete']} onChange={() => onPermission(item, 'canDelete')} />
                                                                                <span className="checkmarks" />
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            <label className="checkboxs">
                                                                                <input type="checkbox" checked={selcted && selcted['canRead']} onChange={() => onPermission(item, 'canRead')} />
                                                                                <span className="checkmarks" />
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            <label className="checkboxs">
                                                                                <input type="checkbox" checked={selcted && selcted['all']} onChange={() => onPermission(item, 'all')} />
                                                                                <span className="checkmarks" />
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-12">
                        <div className="btn-addproduct mb-4">
                            <PrimaryButton
                                className='btn btn-cancel me-2'
                                onClick={onCancel}
                                title={'cancel'}
                                type="button"
                            />
                            <PrimaryButton
                                className='btn btn-submit'
                                loading={load.loading}
                                title={'save_role'}
                                type="submit"
                            />
                        </div>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default CreateRole;