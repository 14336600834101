import { getBranches, getCompanies, getProducts, getPurchaseTaxes, getSuppliers, postCreatePurchaseOrder, putUpdatePurchaseOrder, putUpdatePurchaseOrderStatus } from "../../../../../api";
import { DatePicker, Icon, PrimaryButton, Select, TextArea, TextField } from "../../../../Libraries";
import { useLanguage, useToast } from "../../../../../context";
import { Collapse, SubHeader } from "../../../../common";
import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import dayjs from "dayjs";

const CreatePurchaseOrder = ({ from, item }) => {

    const { POLine, PO } = item || {}

    const { invokeToast } = useToast();
    const { I18n } = useLanguage();
    let navigate = useNavigate();

    let isDisabled = from == 'edit' ? PO?.status == "draft" ? false : true : false;
    let POStatus = PO?.status;

    const [load, setLoad] = useState({ supplierLoad: false, acceptLaod: false, returnLoad: false, dataLoad: true, loading: false });
    const [errors, setErrors] = useState({ poError: {}, poLinesError: {} });
    const [poLines, setPoLines] = useState([]);
    const [options, setOptions] = useState({
        purchaseTaxes: [],
        companies: [],
        suppliers: [],
        branches: [],
        products: [],
    });
    const [purchaseOrder, setPurchaseOrder] = useState({
        supplier: PO?.supplier ? { name: PO.supplier.name, value: PO.supplier } : {},
        company: PO?.company ? { name: PO.company.name, value: PO.company } : {},
        branch: PO?.branch ? { name: PO.branch.name, value: PO.branch } : {},
        expectedDate: PO?.expectedDate ? dayjs(PO.expectedDate) : '',
        poDate: PO?.poDate ? dayjs(PO.poDate) : '',
        reference: PO?.reference || '',
        discount: PO?.discount || '',
        note: PO?.note || '',
    });

    useEffect(() => {
        handleGetDetails();
    }, []);

    useEffect(() => {
        if (from == 'edit') updatePOLinesSate();
    }, [from]);

    const updatePOLinesSate = () => {
        let obj = [];

        POLine.map(item => {
            obj[item._id] = {
                purchaseTax: item?.purchaseTax?._id ? { name: item.purchaseTax.name, value: item.purchaseTax } : {},
                costPrice: item?.unitPrice || 0,
                product_id: item?.product || '',
                name: item?.productName || '',
                quantity: item?.quantity || 0,
                unit: item?.unit || {},
                _id: item?._id || ''
            }
        });

        setPoLines(obj);
    };

    const handleGetDetails = async () => {
        try {
            const [productsData, purchaseTaxData, companiessData, branchesData] = await Promise.all([
                getProducts({ page: 1, resultsPerPage: 500 }),
                getPurchaseTaxes(),
                getCompanies(),
                getBranches(),
            ]);

            setOptions(prev => {
                let all = { ...prev };
                all['purchaseTaxes'] = purchaseTaxData?.data?.purchaseTaxes || [];
                all['companies'] = companiessData?.data?.companies || [];
                all['branches'] = branchesData?.data?.branches || [];
                all['products'] = productsData?.data?.products || [];

                return all;
            });
        } catch (err) {
        } finally {
            setLoad({ ...load, dataLoad: false });
        }
    };

    const getSupplier = async (company) => {
        try {

            setLoad({ ...load, supplierLoad: true });

            let { data } = await getSuppliers({ company });

            setOptions(prev => {
                let all = { ...prev };
                all['suppliers'] = data?.suppliers || [];
                return all;
            })

        } catch (err) { }
        finally {
            setLoad({ ...load, supplierLoad: false });
        }
    }

    const validatePurchaseOrder = () => {
        let obj = {};

        if (!Object.values(poLines).length) obj = { ...obj, product: 'Product attachment is required' };
        if (!purchaseOrder['expectedDate']) obj = { ...obj, expectedDate: 'Expected Date is required' };
        if (Number(purchaseOrder['discount']) < 0) obj = { ...obj, discount: 'Invalid discount' };
        if (!purchaseOrder['supplier']?.name) obj = { ...obj, supplier: 'Supplier is required' };
        if (!purchaseOrder['company']?.name) obj = { ...obj, company: 'Company is required' };
        if (!purchaseOrder['reference']) obj = { ...obj, reference: 'Reference is required' };
        if (!purchaseOrder['poDate']) obj = { ...obj, poDate: 'Purchase date is required' };
        if (!purchaseOrder['branch']?.name) obj = { ...obj, branch: 'Branch is required' };

        return obj;
    };

    const validatePurchaseOrderLines = () => {
        let obj = {};

        Object.values(poLines).map(item => {
            if (!Number(item.costPrice)) obj[item.product_id] = { ...obj[item.product_id], costPrice: 'Purchase price is required' };
            if (!item.purchaseTax?.name) obj[item.product_id] = { ...obj[item.product_id], purchaseTax: 'PurchaseTax is required' };
            if (!Number(item.quantity)) obj[item.product_id] = { ...obj[item.product_id], quantity: 'Quantity is required' };
            if (!purchaseOrder['branch']) obj[item.product_id] = { ...obj[item.product_id], branch: 'Branch is required' };
        });

        return obj;
    };

    const handleLoading = (status, isLoad) => {
        let obj = { ...load };

        if (status == 'returned' || status == 'rejected') obj = { returnLoad: isLoad };
        else if (status == 'delivered' || status == 'approved') obj = { acceptLaod: isLoad };
        else obj = { loading: isLoad };
        setLoad(obj);
    };

    const onSave = async (event, status) => {
        window.scrollTo({ top: 0 });
        event.stopPropagation();
        event.preventDefault();

        try {

            if (Object.values(validatePurchaseOrder()).length || Object.values(validatePurchaseOrderLines()).length) {
                setErrors({ poLinesError: validatePurchaseOrderLines(), poError: validatePurchaseOrder() });
                return;
            };

            handleLoading(status, true);

            if (from == 'edit' && PO?.status != "draft") {
                await putUpdatePurchaseOrderStatus(PO._id, { status });

                invokeToast({ message: `Purchase Order ${from == 'edit' ? 'updated' : 'created'} successfully!`, type: 'success' });

                navigate('/purchase-order');

                return;
            };

            const {
                expectedDate,
                reference,
                supplier,
                discount,
                company,
                branch,
                poDate,
                note
            } = purchaseOrder;

            let params = {
                expectedDate: expectedDate ? dayjs(expectedDate) : '',
                subTotal: Number(getAmount().subTotal || 0),
                total: Number(getAmount().total || 0),
                poDate: poDate ? dayjs(poDate) : '',
                discount: Number(discount || 0),
                supplier_id: supplier.value._id,
                company_id: company.value._id,
                branch_id: branch.value._id,
                reference: reference || '',
                lines: [],
                note,
            };

            let poLinesError = {};

            Object.values(poLines).map(item => {

                let PTRate = item.purchaseTax?.value?.rate || 0;
                let subTotal = item.costPrice * item.quantity;
                let PTAmount = subTotal * (PTRate / 100);
                let total = subTotal + PTAmount;

                let obj = {
                    purchaseTax_id: item.purchaseTax?.value?._id || '',
                    unitPrice: Number(item.costPrice || 0),
                    quantity: Number(item.quantity || 0),
                    product_id: item?.product_id || '',
                    taxAmount: Number(PTAmount || 0),
                    subTotal: Number(subTotal || 0),
                    unit_id: item.unit?._id || '',
                    total: Number(total || 0)
                };

                if (item?._id) obj['_id'] = item?._id;

                if (!obj['taxAmount']) poLinesError[item.product_id] = { ...poLinesError[item.product_id], purchaseTax: 'Tax amount can not be 0' };

                params.lines.push(obj);
            });

            if (Object.keys(poLinesError).length) {
                setErrors({ ...errors, poLinesError });
                return
            }

            if (from == 'edit') {
                await putUpdatePurchaseOrder(PO._id, params)
                if (status) putUpdatePurchaseOrderStatus(PO._id, { status })
            } else await postCreatePurchaseOrder(params);

            invokeToast({ message: `Purchase Order ${from == 'edit' ? 'updated' : 'created'} successfully!`, type: 'success' });

            navigate('/purchase-order');
        } catch (err) {
            invokeToast({ message: err, type: 'danger' });
        } finally {
            // handleLoading(status, false);
        }
    };

    const onChange = (key, value) => {
        let poError = { ...errors.poError };
        setPurchaseOrder(prev => {
            let all = { ...prev };
            all[key] = value;

            if (key == 'company' && value?.value?._id) getSupplier(value.value._id);

            if (poError[key]) poError[key] = '';
            return all;
        });
        if (Object.values(poError).length) setErrors({ ...errors, poError });
    };

    const onChangeLines = (key, value, id) => {
        let poLinesError = { ...errors.poLinesError };
        setPoLines(prev => {
            let all = { ...prev };
            all[id][key] = value;

            if (poLinesError[id] && poLinesError[id][key]) poLinesError[id][key] = '';
            return all;
        });
        if (Object.values(poLinesError).length) setErrors({ ...errors, poLinesError });
    };

    const onProductSelect = ({ value }) => {
        let poError = { ...errors.poError };

        setPoLines(prev => {
            let all = { ...prev };
            all[value._id] = {
                costPrice: value?.costPrice || '',
                quantity: value?.quantity || 1,
                unit: value?.unit || {},
                name: value?.name || '',
                product_id: value?._id,
                purchaseTax: {},
            };

            if (poError['product']) poError['product'] = '';

            return all;
        });

        if (Object.values(poError).length) setErrors({ ...errors, poError });
    };

    const removeProduct = (product_id) => {
        setPoLines(prev => {
            let all = { ...prev };
            if (all[product_id]) delete all[product_id];
            return all;
        });
    };

    const getAmount = () => {
        let subTotal = 0, total = 0;

        Object.values(poLines).map(item => {
            let itemPTRate = item.purchaseTax?.value?.rate || 0;
            let itemSBT = item.costPrice * item.quantity;

            let itemPTAmont = itemSBT * (itemPTRate / 100);

            total += itemSBT + itemPTAmont;
            subTotal += itemSBT;
        });

        let discAmount = total * ((purchaseOrder.discount || 0) / 100);
        total = total - discAmount;

        return { subTotal, total }
    };

    const handleBack = () => navigate(-1);
    const onCancel = () => navigate(-1);

    const disabledDates = (current) => {
        const today = dayjs().startOf('day');
        return current && current.isBefore(purchaseOrder.poDate || today, 'day');
    };

    const getAvailProducts = () => options.products.filter(x => x._id != poLines[x._id]?.product_id).map(item => ({ value: item, label: item.name }));

    return (
        <div className="page-wrapper">
            <div className="content">
                <SubHeader
                    data={{
                        title: from == 'edit' ? 'edit_purchase_order' : 'new_purchase_order',
                        subTitle: from == 'edit' ? '' : 'create_new_purchase_order',
                        flip: true,
                        actions: [{
                            className: 'btn btn-added color',
                            title: 'back_to_purchase_list',
                            action: handleBack,
                            icon: 'arrow-left',
                        }]
                    }}
                />

                <form type="submit" onSubmit={onSave} >
                    <div className="card">
                        <div className="card-body add-product pb-0">
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <Collapse
                                        data={{
                                            title: I18n.purchase_order_information,
                                            target: 'collapseOne',
                                            id: 'headingOne',
                                            icon: 'info',
                                        }} >
                                    </Collapse>

                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <Select
                                                        options={options.companies.map(item => ({ value: item, label: item.name }))}
                                                        placeholder={load.dataLoad ? I18n.fetching_companies : I18n.choose}
                                                        onChange={(value) => onChange('company', value)}
                                                        isDisabled={isDisabled || load.dataLoad}
                                                        error={errors.poError?.company}
                                                        value={purchaseOrder.company}
                                                        className="mb-3 add-product"
                                                        label={I18n.company}
                                                        actions={{
                                                            icon: 'plus-circle',
                                                            title: 'add_new',
                                                        }}
                                                    />
                                                </div>

                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <Select
                                                        placeholder={load.supplierLoad ? I18n.fetching_suppliers : purchaseOrder.company?.name ? I18n.choose : I18n.choose_company_first}
                                                        options={options.suppliers.map(item => ({ value: item, label: item.name }))}
                                                        onChange={(value) => onChange('supplier', value)}
                                                        isDisabled={isDisabled || load.supplierLoad}
                                                        error={errors.poError?.supplier}
                                                        value={purchaseOrder.supplier}
                                                        className="mb-3 add-product"
                                                        label={I18n.supplier}
                                                        actions={{
                                                            icon: 'plus-circle',
                                                            title: 'add_new',
                                                        }}
                                                    />
                                                </div>

                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <Select
                                                        options={options.branches.map(item => ({ value: item, label: item.name }))}
                                                        placeholder={load.dataLoad ? I18n.fetching_branches : I18n.choose}
                                                        onChange={(value) => onChange('branch', value)}
                                                        isDisabled={isDisabled || load.dataLoad}
                                                        error={errors.poError?.branch}
                                                        value={purchaseOrder.branch}
                                                        className="mb-3 add-product"
                                                        label={I18n.branch}
                                                        actions={{
                                                            icon: 'plus-circle',
                                                            title: 'add_new',
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <TextField
                                                    onChange={(value) => onChange('reference', value)}
                                                    containerClass='col-lg-4 col-sm-6 col-12'
                                                    error={errors.poError?.reference}
                                                    innerContClass='mb-3 add-product'
                                                    placeholder="enter_reference_no"
                                                    value={purchaseOrder.reference}
                                                    disabled={isDisabled}
                                                    label={'reference_no'}
                                                    type={"text"}
                                                    onlyNumber
                                                />

                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <DatePicker
                                                        onChange={(date) => onChange('poDate', date)}
                                                        error={errors.poError?.poDate}
                                                        placeholder={I18n.choose_date}
                                                        value={purchaseOrder.poDate}
                                                        className="datetimepicker"
                                                        lable={I18n.purchase_date}
                                                        disabled={isDisabled}
                                                        format="YYYY-MM-DD"
                                                        icon="calendar"
                                                    />
                                                </div>

                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <DatePicker
                                                        onChange={(date) => onChange('expectedDate', date)}
                                                        error={errors.poError?.expectedDate}
                                                        value={purchaseOrder.expectedDate}
                                                        placeholder={I18n.choose_date}
                                                        disabledDate={disabledDates}
                                                        lable={I18n.expected_date}
                                                        className="datetimepicker"
                                                        disabled={isDisabled}
                                                        format="YYYY-MM-DD"
                                                        icon="calendar"
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12 col-sm-6 col-12">
                                                    <Select
                                                        placeholder={load.dataLoad ? I18n.fetching_products : I18n.choose}
                                                        isDisabled={isDisabled || load.dataLoad}
                                                        error={errors.poError?.product}
                                                        className="mb-3 add-product"
                                                        options={getAvailProducts()}
                                                        onChange={onProductSelect}
                                                        label={I18n.product_name}
                                                        value={''}
                                                        actions={{
                                                            icon: 'plus-circle',
                                                            title: 'add_new',
                                                        }}
                                                    />
                                                </div>

                                                <div className="col-lg-12">
                                                    <div className="modal-body-table">
                                                        <div className="table-responsive">
                                                            <table className="table  datanew">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{I18n.product}</th>
                                                                        <th>{I18n.qty}</th>
                                                                        <th>{I18n.purchase_price}</th>
                                                                        <th>{I18n.tax}(%)</th>
                                                                        <th>{I18n.total_cost}</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {Object.keys(poLines).map((item, index) => {
                                                                        let purchaseTaxRate = poLines[item].purchaseTax?.value?.rate || 0;
                                                                        let subTotal = poLines[item].costPrice * poLines[item].quantity;
                                                                        let purchaseTaxAmount = subTotal * (purchaseTaxRate / 100);
                                                                        let total = subTotal + purchaseTaxAmount;

                                                                        return (
                                                                            <tr key={index}>
                                                                                <td className="p-2">
                                                                                    <TextField
                                                                                        error={errors.poLinesError[item]?.name}
                                                                                        innerContClass='mt-2 mb-2 add-product'
                                                                                        placeholder="enter_product_name"
                                                                                        value={poLines[item].name}
                                                                                        containerClass='col-12'
                                                                                        onChange={() => { }}
                                                                                        type={"text"}
                                                                                        disabled
                                                                                    />
                                                                                </td>

                                                                                <td className="p-2">
                                                                                    <TextField
                                                                                        onChange={(value) => onChangeLines('quantity', value, item)}
                                                                                        error={errors.poLinesError[item]?.quantity}
                                                                                        innerContClass='mt-2 mb-2 add-product'
                                                                                        placeholder="enter_product_quantity"
                                                                                        value={poLines[item].quantity}
                                                                                        containerClass='col-12'
                                                                                        disabled={isDisabled}
                                                                                        type={"text"}
                                                                                    />
                                                                                </td>

                                                                                <td className="p-2">
                                                                                    <TextField
                                                                                        onChange={(value) => onChangeLines('costPrice', value, item)}
                                                                                        error={errors.poLinesError[item]?.costPrice}
                                                                                        innerContClass='mt-2 mb-2 add-product'
                                                                                        value={poLines[item].costPrice}
                                                                                        placeholder="enter_costPrice"
                                                                                        containerClass='col-12'
                                                                                        disabled={isDisabled}
                                                                                        type={"text"}
                                                                                    />
                                                                                </td>

                                                                                <td className="p-2">
                                                                                    <Select
                                                                                        options={options.purchaseTaxes.map(item => ({ value: item, label: item.name }))}
                                                                                        onChange={(value) => onChangeLines('purchaseTax', value, item)}
                                                                                        error={errors.poLinesError[item]?.purchaseTax}
                                                                                        innerContClass='mt-2 mb-2 add-product'
                                                                                        value={poLines[item].purchaseTax}
                                                                                        placeholder={I18n.choose}
                                                                                        isDisabled={isDisabled}
                                                                                        actions={{
                                                                                            icon: 'plus-circle',
                                                                                            title: 'add_new',
                                                                                        }}
                                                                                    />
                                                                                </td>

                                                                                <td className="p-2">
                                                                                    <TextField
                                                                                        innerContClass='mt-2 mb-2 add-product'
                                                                                        placeholder="enter_total_amount"
                                                                                        containerClass='col-12'
                                                                                        onChange={() => { }}
                                                                                        value={total}
                                                                                        type={"text"}
                                                                                        disabled
                                                                                    />
                                                                                </td>
                                                                                <td className="p-2">
                                                                                    <div className="col-lg-2 deletBoxCol">
                                                                                        <div className="input-blocks variantDeletBtn">
                                                                                            <PrimaryButton
                                                                                                onClick={() => removeProduct(poLines[item].product_id)}
                                                                                                className='remove-color'
                                                                                                Icon={Icon['delete']}
                                                                                                disabled={isDisabled}
                                                                                                type="button"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <TextField
                                                    placeholder="enter_purchase_discount_in_percentage"
                                                    onChange={(value) => onChange('discount', value)}
                                                    containerClass='col-lg-4 col-sm-6 col-12'
                                                    innerContClass='mb-3 add-product'
                                                    error={errors.poError?.discount}
                                                    value={purchaseOrder.discount}
                                                    label={'discount_in_percent'}
                                                    disabled={isDisabled}
                                                    type={"text"}
                                                    onlyNumber
                                                />

                                                <TextField
                                                    containerClass='col-lg-4 col-sm-6 col-12'
                                                    placeholder="enter_purchase_subtotal"
                                                    innerContClass='mb-3 add-product'
                                                    value={getAmount().subTotal}
                                                    onChange={() => { }}
                                                    label={'subtotal'}
                                                    type={"text"}
                                                    disabled
                                                    onlyDot
                                                />

                                                <TextField
                                                    onChange={(value) => onChange('total', value)}
                                                    containerClass='col-lg-4 col-sm-6 col-12'
                                                    placeholder="enter_purchase_total"
                                                    innerContClass='mb-3 add-product'
                                                    value={getAmount().total}
                                                    label={'total'}
                                                    type={"text"}
                                                    disabled
                                                    onlyDot
                                                />
                                            </div>

                                            <div className="col-lg-12">
                                                <TextArea
                                                    className="input-blocks summer-description-box transfer mb-3"
                                                    onChange={(value) => onChange('note', value)}
                                                    info={I18n['max_sixty_character']}
                                                    placeholder="enter_purchase_note"
                                                    error={errors.poError?.note}
                                                    value={purchaseOrder.note}
                                                    disabled={isDisabled}
                                                    maxLength={60}
                                                    lable="note"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="btn-addproduct mb-4">
                            <PrimaryButton
                                className='btn btn-cancel me-2'
                                onClick={onCancel}
                                title={'cancel'}
                                type="button"
                            />
                            {(POStatus == 'draft' || from != 'edit') ?
                                <PrimaryButton
                                    disabled={load.acceptLaod || load.returnLoad || load.loading}
                                    title={from == 'edit' ? 'edit' : 'save_pruchase_order'}
                                    className='btn btn-submit me-2'
                                    loading={load.loading}
                                    type="submit"
                                />
                                : null}

                            {(POStatus == "draft" || POStatus == "approved") ?
                                <Fragment>
                                    <PrimaryButton
                                        onClick={event => onSave(event, POStatus == "approved" ? 'returned' : 'rejected')}
                                        disabled={load.acceptLaod || load.returnLoad || load.loading}
                                        title={POStatus == "approved" ? 'return' : 'reject'}
                                        className='btn btn-submit me-2'
                                        loading={load.returnLoad}
                                        type="submit"
                                    />
                                    <PrimaryButton
                                        onClick={event => onSave(event, POStatus == "approved" ? 'delivered' : 'approved')}
                                        disabled={load.acceptLaod || load.returnLoad || load.loading}
                                        title={POStatus == "approved" ? 'deliver' : 'accept'}
                                        className='btn btn-submit me-2'
                                        loading={load.acceptLaod}
                                        type="submit"
                                    />
                                </Fragment>
                                : null
                            }
                        </div>
                    </div>
                </form>
            </div >
        </div >
    );
};

export default CreatePurchaseOrder;