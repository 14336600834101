import { Icon, PrimaryButton, Select, TextField } from "../../../../Libraries";
import { postCreateOptions, putUpdateOption } from "../../../../../api";
import { useLanguage, useToast } from "../../../../../context";
import { Collapse, SubHeader } from "../../../../common";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

const allTypes = [
    { name: "Text", value: "text" },
    { name: "Color", value: "color" }
];

const CreateVariantOption = ({ from, item }) => {

    const { invokeToast } = useToast();
    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const [values, setValues] = useState(item?.values || []);
    const [laoding, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [variantOption, setVariantOption] = useState({
        type: item?.type ? { name: item.type, value: item.type } : {},
        nameLocal: item?.nameLocal || '',
        name: item?.name || '',
        value: '',
    });

    const onChange = (key, value) => {
        let error = { ...errors };
        setVariantOption(prev => {
            let all = { ...prev };
            all[key] = value;
            if (error[key]) error[key] = '';
            return all;
        });
        if (errors[key]) setErrors(error);
    };

    const addValue = () => {
        if (!variantOption.value) {
            setErrors({ ...errors, value: 'Value is required' });
            return;
        };

        setValues(prev => {
            let all = [...prev];
            all.push(variantOption.value);
            return all;
        });
        setVariantOption({ ...variantOption, value: '' });
    };

    const removeValue = (val) => {
        setValues(x => {
            let arr = [...x];
            return arr.filter(x => x != val);
        })
    };

    const validateOptions = () => {
        let obj = {};

        if (!variantOption['name']) obj = { ...obj, name: 'Option name is required' };
        if (!variantOption['type'].name) obj = { ...obj, type: 'Type is required' };
        if (!values.length) obj = { ...obj, value: 'Atleast 1 value is required' };

        return obj;
    };

    const onSave = async (event) => {
        window.scrollTo({ top: 0 });
        event.stopPropagation();
        event.preventDefault();

        try {

            if (Object.values(validateOptions()).length) {
                setErrors(validateOptions());
                return;
            }

            setLoading(true);

            const { nameLocal, type, name } = variantOption;

            let params = {
                type: type?.name || "text",
                nameLocal,
                values,
                name,
            };

            from == 'edit' ? await putUpdateOption(item._id, params) : await postCreateOptions(params);

            invokeToast({ message: `Variant option ${from == 'edit' ? 'updated' : 'created'} successfully!`, type: 'success' });

            navigate('/variant-option');
        } catch (err) {
            invokeToast({ message: err, type: 'danger' });
        } finally {
            setLoading(false);
        }
    };

    const handleBack = () => navigate(-1);
    const onCancel = () => navigate(-1);

    return (
        <div className="page-wrapper">
            <div className="content">

                <SubHeader
                    data={{
                        title: from == 'edit' ? 'edit_variant_option' : 'new_variant_option',
                        subTitle: from == 'edit' ? '' : 'create_new_variant_option',
                        flip: true,
                        actions: [{
                            className: 'btn btn-added color',
                            title: 'back_to_option',
                            action: handleBack,
                            icon: 'arrow-left',
                        }]
                    }}
                />

                <form type="submit" onSubmit={onSave} >
                    <div className="card">
                        <div className="card-body add-product pb-0">
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <Collapse
                                        data={{
                                            title: I18n.variant_option_information,
                                            target: 'collapseOne',
                                            id: 'headingOne',
                                            icon: 'info',
                                        }} >
                                        <div
                                            className="accordion-collapse collapse show"
                                            data-bs-parent="#accordionExample"
                                            aria-labelledby="headingOne"
                                            id="collapseOne"
                                        >
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <TextField
                                                        onChange={(value) => onChange('name', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        innerContClass='mb-3 add-product'
                                                        placeholder="enter_option_name"
                                                        value={variantOption.name}
                                                        error={errors?.name}
                                                        label={'option_name'}
                                                        type={"text"}
                                                    />

                                                    <TextField
                                                        onChange={(value) => onChange('nameLocal', value)}
                                                        containerClass='col-lg-4 col-sm-6 col-12'
                                                        placeholder="enter_local_option_name"
                                                        innerContClass='mb-3 add-product'
                                                        value={variantOption.nameLocal}
                                                        label={'local_option_name'}
                                                        type={"text"}
                                                    />

                                                    <div className="col-lg-4 col-sm-6 col-12">
                                                        <Select
                                                            options={allTypes.map(item => ({ value: item, label: item.name }))}
                                                            onChange={(value) => onChange('type', value)}
                                                            className="mb-3 add-product"
                                                            value={variantOption.type}
                                                            placeholder={I18n.choose}
                                                            label={I18n.option_type}
                                                            error={errors?.type}
                                                            actions={{
                                                                icon: 'plus-circle',
                                                                title: 'add_new',
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 col-sm-6 col-12">
                                                        <div className="input-blocks add-product list">
                                                            <TextField
                                                                onChange={(value) => onChange('value', value)}
                                                                placeholder={'enter_value'}
                                                                value={variantOption.value}
                                                                error={errors?.value}
                                                                label={'values'}
                                                                type={"text"}
                                                            />
                                                            <PrimaryButton
                                                                className='btn btn-primaryadd'
                                                                onClick={addValue}
                                                                title={'add'}
                                                                type="button"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {values.map((option, index) => (
                                                    <div key={`${option}-${index}`} className="row align-items-center">
                                                        <div className="col-sm-10">
                                                            <div className="input-blocks">
                                                                <TextField
                                                                    onChange={() => { }}
                                                                    value={option}
                                                                    type={"text"}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-2">
                                                            <div className="input-blocks variantDeletBtn">
                                                                <Link to="#" className="remove-color" onClick={() => removeValue(option)}>
                                                                    {Icon['delete']}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-12">
                        <div className="btn-addproduct mb-4">
                            <PrimaryButton
                                className='btn btn-cancel me-2'
                                onClick={onCancel}
                                title={'cancel'}
                                type="button"
                            />
                            <PrimaryButton
                                className='btn btn-submit'
                                title={'save_option'}
                                loading={laoding}
                                type="submit"
                            />
                        </div>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default CreateVariantOption;