import { AppCardBoxLoader, AppNotFoundBox } from "../../../../Libraries";
import { getSalesTaxById } from "../../../../../api";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CreateSalesTax } from "..";

const EditSalesTax = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState({});

    useEffect(() => {
        if (id) getPurchaseTax();
        else setLoading(false);
    }, [id]);

    const getPurchaseTax = async () => {
        try {
            const { data } = await getSalesTaxById(id);
            setItem(data.salesTax);
        } catch (err) { }
        finally {
            setLoading(false);
        }
    };


    return loading ?
        <AppCardBoxLoader />
        : item?._id ?
            <CreateSalesTax from="edit" item={item} />
            :
            <AppNotFoundBox title='failed_to_load_sales_tax' />
};

export default EditSalesTax;