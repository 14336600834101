import { Select, TextArea, TextField } from "../../../../../Libraries";
import { useLanguage } from "../../../../../../context";
import { Collapse } from "../../../../../common";

const ProductInformation = ({ onChange, product, options, loading, errors, from }) => {
    const { I18n } = useLanguage();

    return (
        <div
            className="accordion-card-one accordion"
            id="accordionExample"
        >
            <div className="accordion-item">
                <Collapse
                    data={{
                        title: I18n.product_information,
                        target: 'collapseOne',
                        id: 'headingOne',
                        icon: 'info',
                    }} >
                    <>
                        <div className="accordion-body">
                            <div className="row">
                                <TextField
                                    onChange={(value) => onChange('product_name', value)}
                                    containerClass='col-lg-4 col-sm-6 col-12'
                                    innerContClass='mb-3 add-product'
                                    placeholder="enter_product_name"
                                    value={product.product_name}
                                    error={errors?.product_name}
                                    label={'product_name'}
                                    type={"text"}
                                />
                                <TextField
                                    onChange={(value) => onChange('localName', value)}
                                    containerClass='col-lg-4 col-sm-6 col-12'
                                    placeholder='enter_local_product_name'
                                    innerContClass='mb-3 add-product'
                                    label={'local_product_name'}
                                    value={product.localName}
                                    type={"text"}
                                />
                                <div className="col-lg-4 col-sm-6 col-12">
                                    <Select
                                        options={options.units.map(item => ({ value: item, label: item.name }))}
                                        placeholder={loading ? I18n.fetching_units : I18n.choose}
                                        onChange={(value) => onChange('unit', value)}
                                        className="mb-3 add-product"
                                        value={product.unit}
                                        error={errors?.unit}
                                        isDisabled={loading}
                                        label={I18n.unit}
                                        actions={{
                                            icon: 'plus-circle',
                                            title: 'add_new',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="addservice-info">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6 col-12">
                                        <Select
                                            options={options.categories.map(item => ({ value: item, label: item.name }))}
                                            placeholder={loading ? I18n.fetching_categories : I18n.choose}
                                            onChange={(value) => onChange('category', value)}
                                            className="mb-3 add-product"
                                            value={product.category}
                                            error={errors?.category}
                                            label={I18n.category}
                                            isDisabled={loading}
                                            actions={{
                                                icon: 'plus-circle',
                                                title: 'add_new',
                                            }}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-sm-6 col-12">
                                        <Select
                                            options={options.subcategories.map(item => ({ value: item, label: item.name }))}
                                            placeholder={loading ? I18n.fetching_sub_categories : I18n.choose}
                                            onChange={(value) => onChange('sub_category', value)}
                                            className="mb-3 add-product"
                                            value={product.sub_category}
                                            label={I18n.sub_category}
                                            isDisabled={loading}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-sm-6 col-12">
                                        <Select
                                            options={options.brands.map(item => ({ value: item, label: item.name }))}
                                            placeholder={loading ? I18n.fetching_brands : I18n.choose}
                                            onChange={(value) => onChange('brand', value)}
                                            className="mb-3 add-product"
                                            value={product.brand}
                                            isDisabled={loading}
                                            label={I18n.brand}
                                            actions={{
                                                icon: 'plus-circle',
                                                title: 'add_new',
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-12">
                                    <Select
                                        options={options.sale_taxes.map(item => ({ value: item, label: item.name }))}
                                        placeholder={loading ? I18n.fetching_sale_taxes : I18n.choose}
                                        onChange={(value) => onChange('sale_tax', value)}
                                        className="mb-3 add-product"
                                        value={product.sale_tax}
                                        label={I18n.sale_tax}
                                        isDisabled={loading}
                                    />
                                </div>
                                {from == 'edit' ? null :
                                    <div className="col-lg-4 col-sm-6 col-12">
                                        <Select
                                            options={options.location.map(item => ({ value: item, label: item.name }))}
                                            placeholder={loading ? I18n.fetching_locations : I18n.choose}
                                            onChange={(value) => onChange('location', value)}
                                            className="mb-3 add-product"
                                            value={product.location}
                                            error={errors?.location}
                                            label={I18n.location}
                                            isDisabled={loading}
                                        />
                                    </div>
                                }
                            </div>

                            <div className="col-lg-12">
                                <TextArea
                                    className="input-blocks summer-description-box transfer mb-2"
                                    onChange={(value) => onChange('description', value)}
                                    placeholder="enter_product_description"
                                    info={I18n['max_sixty_character']}
                                    value={product.description}
                                    lable="description"
                                    maxLength={60}
                                />
                            </div>

                        </div>
                    </>
                </Collapse>
            </div>
        </div>
    );
};

export default ProductInformation