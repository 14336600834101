import { Datatable, SearchHeader, SubHeader } from "../../../../common";
import { getProducts, deleteProduct } from "../../../../../api";
import { useLanguage, useToast } from "../../../../../context";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../../Libraries";
import { useEffect, useState } from "react";

const ProductList = () => {

    const { invokeToast } = useToast();
    const { I18n } = useLanguage();
    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState({
        resultsPerPage: 10,
        totalCount: 0,
        page: 1,
    });

    useEffect(() => {
        handleProducts();
    }, []);

    const onDelete = async (id) => {
        try {
            const data = await deleteProduct(id, { archive: true });
            if (data.success) handleProducts();
        } catch (err) { }
    };

    const handleProducts = async (reqPage = 1, filters = undefined) => {

        try {
            setLoading(true);

            let params = {
                resultsPerPage: pagination.resultsPerPage,
                page: reqPage
            };

            if (filters) params = { ...params, ...filters };

            const { data } = await getProducts(params);
            const { resultsPerPage, totalCount, products, page } = data || {};

            setPagination({ resultsPerPage, totalCount, page });

            setList(products.map(product => {
                return {
                    category: product?.category?.name || '-',
                    nameLocal: product.nameLocal || '-',
                    brand: product?.brand?.name || '-',
                    price: product.sellingPrice || 0,
                    unit: product?.unit?.name || '-',
                    createdAt: product.createdAt,
                    name: product.name || '-',
                    sku: product.sku || '-',
                    image: product.image,
                    _id: product._id,
                }
            }));
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const onPageChange = (page) => {
        if (page == pagination.current || loading) return;
        handleProducts(page);
    };

    const ImportProduct = () => invokeToast({ message: I18n.coming_soon, type: 'info' });
    const AddProduct = () => navigate('/product/create');

    return (
        <div className="page-wrapper">
            <div className="content">
                <SubHeader
                    data={{
                        subTitle: 'manage_ur_product',
                        title: 'product_list',
                        overlays: [
                            {
                                svg: 'assets/img/icons/pdf.svg',
                                tipType: I18n.pdf,
                            },
                            {
                                svg: 'assets/img/icons/excel.svg',
                                tipType: I18n.excel,
                            },
                            {
                                onClick: handleProducts,
                                Icon: Icon['referesh'],
                                tipType: I18n.refresh,
                                disabled: loading
                            }
                        ],
                        actions: [
                            {
                                className: 'btn btn-added',
                                title: 'add_new_product',
                                action: AddProduct,
                                icon: 'plus'

                            },
                            {
                                className: 'btn btn-added color',
                                title: 'import_product',
                                action: ImportProduct,
                                icon: 'download'
                            }
                        ],
                    }}
                />

                <div className="card table-list-card">
                    <div className="card-body">
                        <SearchHeader
                            filter={{ isFilter: true, from: 'product' }}
                            applyFilter={handleProducts}
                        />

                        <div className="table-responsive">
                            <Datatable
                                onPageChange={onPageChange}
                                pagination={pagination}
                                onDelete={onDelete}
                                loading={loading}
                                dataSource={list}
                                noOfColumns={8}
                                type="product"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductList;