import { Icon, PrimaryButton, Select } from "../../../Libraries"
import { useLanguage } from "../../../../context";

const FilterComp = ({ setFilterObj, filterObj, options, onApply }) => {

    const { I18n } = useLanguage();

    const onChange = (key, value) => {
        setFilterObj(prev => {
            let all = { ...prev };
            all['applied'] = true;
            all[key] = value;
            return all;
        });
    };

    return (
        <div className="card" id="filter_inputNew">
            <div className="card-body pb-0">
                <div className="row">
                    {Object.keys(options).map((item, index) => (
                        <div className="col-lg-2 col-sm-6 col-12" key={index}>
                            <Select
                                options={options[item].map(item => ({ value: item, label: item.name }))}
                                placeholder={`${I18n.choose} ${I18n[item]?.toLowerCase()}`}
                                onChange={(value) => onChange(item, value)}
                                className="mb-3 add-product"
                                value={filterObj[item]}
                                actions={{
                                    icon: 'plus-circle',
                                    title: 'add_new',
                                }}
                            />
                        </div>
                    ))}
                    <div className="col-lg-2 col-sm-6 col-12">
                        <div className="input-blocks">
                            <PrimaryButton
                                className='btn btn-submit btn-filters ms-auto'
                                Icon={Icon['search']}
                                onClick={onApply}
                                title={'search'}
                                type="button"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterComp;