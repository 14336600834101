import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context";

const PublicLayout = () => {

    const { user } = useAuth();

    if (user) return <Navigate to="/dashboard" />;

    return (
        <>
            <Outlet />
        </>
    )
}

export default PublicLayout;