import { MinusCircle, PlusCircle } from "feather-icons-react/build/IconComponents";
import { Link, useParams } from "react-router-dom";
import { getSaleById } from "../../../../../api";
import { AppCardBoxLoader, Image } from "../../../../Libraries";
import { useEffect, useState } from "react";
import { camelCase } from "../../../../../constants";
import { SubHeader } from "../../../../common";
import { useLanguage } from "../../../../../context";

const SaleDetails = () => {

    const { I18n } = useLanguage();
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState({});


    useEffect(() => {
        if (id) handleOrder();
        else setLoading(false);
    }, [id]);


    const handleOrder = async () => {
        try {
            const { data } = await getSaleById(id);
            setOrder(data);
        } catch (err) { }
        finally {
            setLoading(false);
        }
    }

    return loading ? <AppCardBoxLoader /> :
        <div className="page-wrapper">
            <div className="content">
                <SubHeader
                    data={{
                        subTitle: 'full_detail_sales',
                        title: 'sales_details',                           
                    }}
                />
                <div className="row">
                    <div className="col-lg-12 col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <form>
                                    <div
                                        className="invoice-box table-height"

                                    >
                                        <div className="sales-details-items d-flex">
                                            <div className="details-item">
                                                <h6>{I18n.branch_info}</h6>
                                                <p className="mb-2">
                                                    {order?.order?.branch?.name}                                                                                                        
                                                </p>
                                                <p className="mb-2">
                                                    {order?.order?.branch?.address}
                                                </p>
                                                <p className="white mb-2">-</p>
                                            </div>
                                            <div className="details-item">
                                                <h6>{I18n.cashier_info}</h6>
                                                <p className="mb-2">
                                                    {order?.order?.cashier?.name}                                                                                                        
                                                </p>
                                                <p className="mb-2">
                                                    {order?.order?.cashier?.email}                                                    
                                                </p>
                                                <p className="white mb-2">-</p>
                                            </div>
                                            <div className="details-item">
                                                <h6>{I18n.invoice_info}</h6>
                                                <p className="mb-2">
                                                    {I18n.reference}                                                                                                                                                        
                                                </p>
                                                <p className="mb-2">
                                                    {I18n.payment_status}
                                                </p>
                                                <p className="mb-2">
                                                    {I18n.status}
                                                </p>
                                            </div>
                                            <div className="details-item details-itemLast">
                                                <h6 className="white">-</h6>
                                                <p className="mb-2">
                                                    SL{order?.order?.orderNumber || ''}
                                                </p>
                                                <p className="successColor mb-2">
                                                    {order?.order?.paymentStatus || '-'}
                                                </p>
                                                <p className="successColor mb-2">
                                                    {camelCase(order?.order?.status || '-')}
                                                </p>
                                            </div>
                                        </div>
                                        <h5 className="order-text">{I18n.order_summary}</h5>
                                        <div className="table-responsive no-pagination">
                                            <table className="table datanew">
                                                <thead>
                                                    <tr>
                                                        <th>{I18n.product}</th>
                                                        <th>{I18n.qty}</th>
                                                        <th>{I18n.purchase_price}</th>
                                                        <th>{I18n.discount}</th>
                                                        <th>{I18n.tax}(%)</th>
                                                        <th>{I18n.tax_amount}</th>
                                                        <th>{I18n.unit_cost}</th>
                                                        <th>{I18n.total_cost}(%)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {order.orderLines.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td key={index}>
                                                                    <span className="productimgname">
                                                                        <Link className="product-img stock-img">
                                                                            <Image alt="img" src={'assets/img/image-placeholder.png'} />
                                                                        </Link>

                                                                        <Link to="#">{item?.product?.name}</Link>
                                                                    </span>
                                                                </td>

                                                                <td>{item.quantity || 0}</td>
                                                                <td>{item?.unitPrice || 0}</td>
                                                                <td>{0}</td>
                                                                <td>{item?.taxPercentage || 0}</td>
                                                                <td>{item?.taxAmount || 0}</td>
                                                                <td>{0}</td>
                                                                <td>{item?.totalPrice || 0}</td>

                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    {console.log("========= item", order.orderLines)
                                    }
                                    <div className="row">
                                        <div className="row">
                                            <div className="col-lg-6 ms-auto">
                                                <div className="total-order w-100 max-widthauto m-auto mb-4">
                                                    <ul>
                                                        <li>
                                                            <h4>{I18n.order_tax}</h4>
                                                            <h5>SAR {order?.order?.taxAmount}</h5>
                                                        </li>
                                                        <li>
                                                            <h4>{I18n.discount}</h4>
                                                            <h5>SAR 0.00</h5>
                                                        </li>
                                                        <li>
                                                            <h4>{I18n.grand_total}</h4>
                                                            <h5>SAR {order?.order?.total || 0}</h5>
                                                        </li>
                                                        <li>
                                                            <h4>{I18n.paid}</h4>
                                                            <h5>SAR {order?.order?.paidAmount || 0}</h5>
                                                        </li>
                                                        <li>
                                                            <h4>{I18n.due}</h4>
                                                            <h5>SAR {order?.order?.dueAmount || 0}</h5>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* <div id="sales-details-new">
                    <div className="modal-dialog sales-details-modal">
                        <div className="modal-content">
                            <div className="page-wrapper details-blk">
                                <div className="content p-0">
                                    <div className="card">
                                        <div className="card-body">
                                            <form>
                                                <div
                                                    className="invoice-box table-height"

                                                >
                                                    <div className="sales-details-items d-flex">
                                                        <div className="details-item">
                                                            <h6>Branch Info</h6>
                                                            <p>
                                                                {order?.order?.branch?.name}
                                                                <br />
                                                                {order?.order?.branch?.address}
                                                            </p>
                                                        </div>
                                                        <div className="details-item">
                                                            <h6>Cashier Info</h6>
                                                            <p>
                                                                {order?.order?.cashier?.name}
                                                                <br />
                                                                {order?.order?.cashier?.email}
                                                                <br />
                                                            </p>
                                                        </div>
                                                        <div className="details-item">
                                                            <h6>Invoice Info</h6>
                                                            <p>
                                                                Reference
                                                                <br />
                                                                Payment Status
                                                                <br />
                                                                Status
                                                            </p>
                                                        </div>
                                                        <div className="details-item">
                                                            <h5>
                                                                <span>SL{order?.order?.orderNumber || ''}</span>{order?.order?.paymentStatus || '-'}
                                                                <br /> {camelCase(order?.order?.status || '-')}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <h5 className="order-text">Order Summary</h5>
                                                    <div className="table-responsive no-pagination">
                                                        <table className="table  datanew">
                                                            <thead>
                                                                <tr>
                                                                    <th>Product</th>
                                                                    <th>Qty</th>
                                                                    <th>Purchase Price</th>
                                                                    <th>Discount</th>
                                                                    <th>Tax(%)</th>
                                                                    <th>Tax Amount</th>
                                                                    <th>Unit Cost</th>
                                                                    <th>Total Cost(%)</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {order.orderLines.map((item, index) => {
                                                                    return (
                                                                        <tr>
                                                                            <td key={index}>
                                                                                <span className="productimgname">
                                                                                    <Link className="product-img stock-img">
                                                                                        <Image alt="img" src={'assets/img/image-placeholder.png'} />
                                                                                    </Link>

                                                                                    <Link to="#">{item?.product?.name}</Link>
                                                                                </span>
                                                                            </td>

                                                                            <td>{item.quantity || 0}</td>
                                                                            <td>{item?.unitPrice || 0}</td>
                                                                            <td>{0}</td>
                                                                            <td>{item?.taxPercentage || 0}</td>
                                                                            <td>{item?.taxAmount || 0}</td>
                                                                            <td>{0}</td>
                                                                            <td>{item?.totalPrice || 0}</td>

                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                {console.log("========= item", order.orderLines)
                                                }
                                                <div className="row">
                                                    <div className="row">
                                                        <div className="col-lg-6 ms-auto">
                                                            <div className="total-order w-100 max-widthauto m-auto mb-4">
                                                                <ul>
                                                                    <li>
                                                                        <h4>Order Tax</h4>
                                                                        <h5>SAR {order?.order?.taxAmount}</h5>
                                                                    </li>
                                                                    <li>
                                                                        <h4>Discount</h4>
                                                                        <h5>SAR 0.00</h5>
                                                                    </li>
                                                                    <li>
                                                                        <h4>Grand Total</h4>
                                                                        <h5>SAR {order?.order?.total || 0}</h5>
                                                                    </li>
                                                                    <li>
                                                                        <h4>Paid</h4>
                                                                        <h5>SAR {order?.order?.paidAmount || 0}</h5>
                                                                    </li>
                                                                    <li>
                                                                        <h4>Due</h4>
                                                                        <h5>SAR {order?.order?.dueAmount || 0}</h5>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
};

export default SaleDetails;
