export const list = {
    'sales-list': [{ title: "order_number", dataIndex: "name", sort: 'orderNumber' }, { title: "date", dataIndex: "updatedAt", sort: 'updatedAt' }, { title: "status", dataIndex: "status", sort: 'status' }, { title: "grand_total", dataIndex: "total", sort: 'total' }, { title: "paid", dataIndex: "paidAmount", sort: 'paidAmount' }, { title: "due", dataIndex: "dueAmount", sort: 'dueAmount' }, { title: "payment_status", dataIndex: "paymentStatus", sort: 'paymentStatus' }, { title: "biller", dataIndex: "cashier", sort: 'cashier' }, { title: "action", dataIndex: "action" }],
    'purchase-order': [{ title: "supplier", dataIndex: "name", sort: 'supplier' }, { title: "company", dataIndex: "company", sort: 'company' }, { title: "branch", dataIndex: "branch", sort: 'branch' }, { title: "reference", dataIndex: "reference", sort: 'reference' }, { title: "status", dataIndex: "status", sort: 'status' }, { title: "total", dataIndex: "total", sort: 'total' }, { title: "date", dataIndex: "date", sort: 'date' }, { title: "action", dataIndex: "action", sort: 'createdAt' }],
    'product': [{ title: "product", dataIndex: "name", sort: 'name' }, { title: "local_name", dataIndex: "nameLocal", sort: 'nameLocal' }, { title: "sku", dataIndex: "sku", sort: 'sku' }, { title: "category", dataIndex: "category", sort: 'category' }, { title: "brand", dataIndex: "brand", sort: 'brand' }, { title: "price", dataIndex: "price", sort: 'price' }, { title: "unit", dataIndex: "unit", sort: 'unit' }, { title: "action", dataIndex: "action", sort: 'createdAt' }],
    'discount': [{ title: "discount", dataIndex: "name", sort: 'name' }, { title: "local_name", dataIndex: "nameLocal", sort: 'nameLocal' }, { title: "value", dataIndex: "value", sort: 'value' }, { title: "max_discount", dataIndex: "maxCap", sort: 'maxCap' }, { title: "transacton_limit", dataIndex: "capPerTransaction", sort: 'capPerTransaction' }, { title: "action", dataIndex: "action", sort: 'createdAt' }],
    'subcategory': [{ title: "sub_category", dataIndex: "name", sort: 'name' }, { title: "local_name", dataIndex: "nameLocal", sort: 'nameLocal' }, { title: "parent_category", dataIndex: "category", sort: 'category' }, { title: "code", dataIndex: "code", sort: 'code' }, { title: "status", dataIndex: "isActive", sort: 'isActive' }, { title: "action", dataIndex: "action", sort: 'createdAt' }],
    'supplier': [{ title: "supplier", dataIndex: "name", sort: 'name' }, { title: "local_name", dataIndex: "nameLocal", sort: 'nameLocal' }, { title: "company", dataIndex: "company", sort: 'company' }, { title: "phone", dataIndex: "phone", sort: 'phone' }, { title: "email", dataIndex: "email", sort: 'email' }, { title: "action", dataIndex: "action", sort: 'createdAt' }],
    'customer': [{ title: "customer", dataIndex: "name", sort: 'name' }, { title: "local_name", dataIndex: "nameLocal", sort: 'nameLocal' }, { title: "code", dataIndex: "code", sort: 'code' }, { title: "phone", dataIndex: "phone", sort: 'phone' }, { title: "country", dataIndex: "country", sort: 'country' }, { title: "action", dataIndex: "action", sort: 'createdAt' }],
    'stock': [{ title: "product", dataIndex: "name", sort: 'name' }, { title: "location", dataIndex: "location", sort: 'location' }, { title: "quantity", dataIndex: "quantity", sort: 'quantity' }, { title: "last_updated_at", dataIndex: "updatedAt", sort: 'updatedAt' }, { title: "action", dataIndex: "action", sort: 'createdAt' }],
    'variant-option': [{ title: "variant_option", dataIndex: "name", sort: 'name' }, { title: "local_name", dataIndex: "nameLocal", sort: 'nameLocal' }, { title: "values", dataIndex: "values", sort: 'values' }, { title: "type", dataIndex: "type", sort: 'type' }, { title: "action", dataIndex: "action", sort: 'createdAt' }],
    'store-list': [{ title: "company", dataIndex: "name", sort: 'name' }, { title: "local_name", dataIndex: "nameLocal", sort: 'nameLocal' }, { title: "phone", dataIndex: "phone", sort: 'phone' }, { title: "country", dataIndex: "country", sort: 'country' }, { title: "action", dataIndex: "action", sort: 'createdAt' }],
    'category': [{ title: "category", dataIndex: "name", sort: 'name' }, { title: "local_name", dataIndex: "nameLocal", sort: 'nameLocal' }, { title: "code", dataIndex: "code", sort: 'code' }, { title: "status", dataIndex: "isActive", sort: 'isActive' }, { title: "action", dataIndex: "action", sort: 'createdAt' }],
    'unit': [{ title: "unit", dataIndex: "name", sort: 'name' }, { title: "local_name", dataIndex: "nameLocal", sort: 'nameLocal' }, { title: "short_name", dataIndex: "short", sort: 'short' }, { title: "status", dataIndex: "isActive", sort: 'isActive' }, { title: "action", dataIndex: "action", sort: 'createdAt' }],
    'location': [{ title: "location", dataIndex: "name", sort: 'name' }, { title: "phone", dataIndex: "phone", sort: 'phone' }, { title: "code", dataIndex: "code", sort: 'code' }, { title: "address", dataIndex: "address", sort: 'address' }, { title: "action", dataIndex: "action", sort: 'createdAt' }],
    'stock-transfer': [{ title: "from_branch", dataIndex: "name", sort: 'fromBranch' }, { title: "to_branch", dataIndex: "toBranch", sort: 'toBranch' }, { title: "product", dataIndex: "product", sort: 'product' }, { title: "quantity", dataIndex: "quantity", sort: 'quantity' }],
    'brand': [{ title: "brand", dataIndex: "name", sort: 'name' }, { title: "local_name", dataIndex: "nameLocal", sort: 'nameLocal' }, { title: "status", dataIndex: "isActive", sort: 'isActive' }, { title: "action", dataIndex: "action", sort: 'createdAt' }],
    'sales-tax': [{ title: "sales_tax", dataIndex: "name", sort: 'name' }, { title: "rate", dataIndex: "rate", sort: 'rate' }, { title: "type", dataIndex: "type", sort: 'type' }, { title: "action", dataIndex: "action", sort: 'createdAt' }],
    'users': [{ title: "employee", dataIndex: "name", sort: 'name' }, { title: "email", dataIndex: "email", sort: 'email' }, { title: "role", dataIndex: "role", sort: 'role' }, { title: "action", dataIndex: "action", sort: 'createdAt' }],
    'roles-permissions': [{ title: "role", dataIndex: "name", sort: 'name' }, { title: "local_name", dataIndex: "nameLocal", sort: 'nameLocal' }, { title: "action", dataIndex: "action", sort: 'createdAt' }],
    'purchase-tax': [{ title: "purchase_tax", dataIndex: "name", sort: 'name' }, { title: "rate", dataIndex: "rate", sort: 'rate' }, { title: "action", dataIndex: "action", sort: 'createdAt' }],
};
