import { X } from "feather-icons-react/build/IconComponents";
import { Link } from "react-router-dom";
import { Image } from "../../Libraries";

const ImageList = ({ removeImage, item }) => {
    return (
        <div className="phone-img">
            <Image
                src={typeof item === 'object' ? URL.createObjectURL(item) : item}
                alt="image"
            />
            <Link to="#">
                <X
                    className="x-square-add remove-product"
                    onClick={removeImage}
                />
            </Link>
        </div>
    );
};

export default ImageList;